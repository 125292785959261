import React from "react";

export const TitleDescriptionAndUpload = ({
  classes,
  imageData,
  required,
  title,
  description,
  fileUpload,
  setTitle,
  setDescription,
  t,
}) => {
  return (
    <>
      <div className={classes.UploadOptions}>
        <span style={{ width: "8rem", padding: "0 5px" }}>
          {t("blog.mainPicture")}
        </span>
        {imageData ? (
          <>
            <img alt="article" src={imageData} className={classes.Picture} />
            <h4
              style={{
                paddingLeft: "3px",
                color: "#000",
                textAlign: "center",
                paddingRight: "5px",
              }}
            >
              {t("blog.orUploadNew")}
            </h4>
          </>
        ) : (
          <h4
            style={{
              paddingLeft: "3px",
              color: "#000",
              textAlign: "center",
              paddingRight: "5px",
            }}
          >
            {t("blog.uploadNew")}
          </h4>
        )}
        <form
          onChange={(event) => fileUpload(event)}
          className={classes.ImageUpload}
        >
          <input
            required={required}
            type="file"
            name="image"
            id="file"
            accept=".jpeg, .png, .jpg"
          />
        </form>
      </div>
      <p>
        {t("blog.title")}
        <input
          required={required}
          onChange={(event) => setTitle(event.target.value)}
          className={classes.TextInput}
          type="text"
          value={title}
        ></input>
      </p>
      <p>
        {t("blog.description")}
        <input
          required={required}
          onChange={(event) => setDescription(event.target.value)}
          className={classes.Description}
          value={description}
          type="text-area"
        ></input>
      </p>
    </>
  );
};
