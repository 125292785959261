import React from "react";
import classes from "../cartProduct/CartProduct.module.css"
import {WithTranslation, withTranslation} from "react-i18next";
import ProductClass, {ChangeAmountEnum, ProductCategory} from "../../../../templateObjects/product/ProductClass";
import image from "../../../../assets/image/grey-close.png"
import {Link} from "react-router-dom";
import OmegaProducts from '../../../../data/products/OmegaProducts';
import CBDProducts from '../../../../data/products/CBDProducts';
import CollagenProducts from '../../../../data/products/KolagenProducts';
import anticelProducts from "../../../../data/products/AnticelProducts";

interface Props extends WithTranslation {
    productClass: ProductClass,
    incrementAmount: (product: ProductClass) => void,
    decrementAmount: (product: ProductClass) => void,
    removeProduct: (product: ProductClass) => void
}

class CartProduct extends React.Component<Props> {

    changeAmountOfProduct(productClass: ProductClass, type: ChangeAmountEnum) {
        switch (type) {
            case ChangeAmountEnum.INCREMENT:
                this.props.incrementAmount(productClass);
                break;
            case ChangeAmountEnum.DECREMENT:
                if (productClass.amount > 1) {
                    this.props.decrementAmount(productClass);
                }
                else {
                    this.props.removeProduct(productClass)
                }
                break;
        }
    }

    render() {
        const {t} = this.props;

        const findProductWithRecommendedProducts = (product: ProductClass) =>  {
            switch (product.category) {
                case ProductCategory.OMEGA:
                    return OmegaProducts.find(p => p.name === product.name);
                case ProductCategory.COLLAGEN:
                    return CollagenProducts.find(p => p.name === product.name);
                case ProductCategory.CBD:
                    return CBDProducts.find(p => p.name === product.name);
                case ProductCategory.ANTICEL:
                    return anticelProducts.find(p => p.name === product.name);
            }
        }

        return (
            <div>
                <div className={classes.CartProductGrid}>
                    <Link to={
                        {
                            pathname: `/detail/${findProductWithRecommendedProducts(this.props.productClass)!.id}`
                        }
                    } className={classes.CartPictureLink}>
                        <img alt="product-icon" className={classes.CartItemImg} src={this.props.productClass.cartIcon}/>
                    </Link>
                    <Link to={
                        {
                            pathname: `/detail/${findProductWithRecommendedProducts(this.props.productClass)!.id}`
                        }
                    } className={classes.CartItemLink}>
                        <span className={classes.CartItemProduct}>
                            {this.props.productClass.isNew ?
                            <p>
                                <span className={classes.CartProductDescription}>
                                    <span>
                                        <span className={classes.CardProductAlert}>{t("cart.product.new")}</span>
                                        <span className={classes.CartProductDivider}>/</span>
                                    </span>
                                </span>
                            </p>:null}
                            <p className={classes.CartProductName}>{t(this.props.productClass.name)}</p>
                        </span>
                    </Link>
                    {!this.props.productClass.discount &&
                    <span className={classes.CartItemPrice}>{this.props.productClass.price}&nbsp;{t("currency")}</span>}
                    {this.props.productClass.discount &&
                    <span className={classes.CartPriceWrapper}>
                        <p className={classes.CartItemPriceBefore}>{this.props.productClass.discount}&nbsp;{t("currency")}</p>
                        <p className={classes.CartItemNewPrice}>{this.props.productClass.price}&nbsp;{t("currency")}</p>
                    </span>}
                    <span className={classes.CardItemAmount}>
                        <span style={{float: "left"}}
                              onClick={() => this.changeAmountOfProduct(this.props.productClass, ChangeAmountEnum.DECREMENT)}>-</span>
                        <span>{this.props.productClass.amount}</span>
                        <span style={{float: "right"}}
                              onClick={() => this.changeAmountOfProduct(this.props.productClass, ChangeAmountEnum.INCREMENT)}>+</span>
                    </span>
                    <span className={classes.CartItemOverallPrice}>
                            {this.props.productClass.discount && <span className={classes.CartItemOverallPriceBefore}>
                                                                    {this.props.productClass.discount*this.props.productClass.amount}&nbsp;{t("currency")}
                                                                </span>
                            }
                            {this.props.productClass.totalPriceByAmount}&nbsp;{t("currency")}
                    </span>
                    <span className={classes.CartItemClose}
                          onClick={() => this.props.removeProduct(this.props.productClass)}><img
                        src={image} alt={image}/></span>
                </div>

            </div>
        )
    }
}

export default withTranslation()(CartProduct);