import React from "react";
import classes from './SignIn.module.css';
import Button from "../../../common/button/Button";
import {Link, RouteComponentProps} from 'react-router-dom';
import InputFieldCommon from "../../../common/inputField/InputFieldCommon";
import {RouteConstants} from "../../../routes/RouteConstants";
import {withTranslation, WithTranslation} from "react-i18next";
import {USERNAME, PASSWORD} from '../../../../utils/config/config';
import {connect} from 'react-redux';
import {AuthenticationEnum} from "../../../../templateObjects/authentication/Authentication";

var passwordHash = require('password-hash');

interface Props extends RouteComponentProps, WithTranslation {
  isPassword: boolean,
  login: (isAuth: boolean) => void
}

interface State {
  username: string,
  password: string,
  error: boolean
}

class SignIn extends React.Component<Props, State> {

  state = {
    username: '',
    password: '',
    error: false
  };

    routeChange = (redirectPath: string) => {
        window.location.href = redirectPath
    };

  login() {
    if (this.state.username === USERNAME && passwordHash.verify(this.state.password, PASSWORD)) {
      this.props.login(true);
      this.setState({error: false})

      //needs to be replace not push, so he cant go back to this page
      this.props.history.replace('/aboutUs')
    }else{
this.setState({error: true})
    }
  }

  render() {
    const {t} = this.props;

    const BG_COLOR = '#D9D9D9';

    const inlineStyle = {
      width: 290,
      height: 50
    };

    return (

      <div className={classes.SignIn}>
          <span className={classes.LinkBackMobile} style={{position: "relative", top: "-90px", left: "-80px"}}>
            <p onClick={() => window.history.back()} style={{color: "black"}}>{t('signin.back').toUpperCase()}</p>
          </span>
          <h1 className={classes.Title}>{this.props.isPassword ? t('password.title') : t('signin.title')}</h1>
          <hr className={classes.UnderLine}/>
        {
          this.props.isPassword ? <div className={classes.DescriptionContainer}><p
            className={classes.Description}>{t('password.description')}</p></div> : null
        }
        <div className={classes.InputContainer}>
          {
              this.props.isPassword ? <InputFieldCommon placeHolder={t('password.email')}
                                                        onChange={(event) => this.setState({username: event.target.value})}
                                                        bgcolor={BG_COLOR} height={inlineStyle.height}
                                                        width={window.innerWidth > 500 ? inlineStyle.width : 250}/> :
              <InputFieldCommon onChange={(event) => this.setState({username: event.target.value})} bgcolor={BG_COLOR}
                                placeHolder={t('signin.email')} height={inlineStyle.height}
                                width={window.innerWidth > 500 ? inlineStyle.width : 250}/>
          }
        </div>
        {
            !this.props.isPassword ? (
                <React.Fragment>
            <div className={classes.InputContainer}>
              <InputFieldCommon type="password" onChange={(event) => this.setState({password: event.target.value})}
                                bgcolor={BG_COLOR} height={inlineStyle.height}
                                width={window.innerWidth > 500 ? inlineStyle.width : 250}
                                placeHolder={t('signin.password')}/>
            </div>
            <div className={classes.LinkText}>
                <div onClick={() => this.routeChange(RouteConstants.password)}
                   style={{color: 'black'}}>{t('signin.forgottenPass')}</div>
            </div>
            <div className={classes.ButtonContainer}>
                <div>
                    <Button text={t('signin.submit').toUpperCase()} isEmailButton={false}
                            clickCallBack={() => this.login()}
                            height={50} width={window.innerWidth > 500 ? 290 : 250}/>
                </div>
            </div>
            <div className={classes.LinkText}>
                <div onClick={() => this.routeChange(RouteConstants.registration)}
                   style={{color: 'black'}}>{t('signin.noAccount')}</div>
            </div>
                </React.Fragment>) : (<div className={classes.ButtonContainer}>
                <div>
            <Button text={t('password.submit').toUpperCase()} isEmailButton={false} clickCallBack={() => {
            }} height={50} width={window.innerWidth > 500 ? 290 : 250}/>
                </div>
            </div>)
        }
          <p className={classes.LinkBack}>
            {this.props.isPassword ?
              <Link to={RouteConstants.signin}
                    style={{
                      textDecoration: 'none',
                      color: 'black'
                    }}>{t('password.back').toUpperCase()}</Link>
              :
              <Link to={RouteConstants.aboutUs}
                    style={{
                      textDecoration: 'none',
                      color: 'black'
                    }}>{t('signin.back').toUpperCase()}</Link>
            }
          </p>
        {
          this.state.error ? <div className={classes.Warning}>{t('signin.wrongCredencials')}</div> : null
        }

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: (arg0: { type: AuthenticationEnum; isAuthenticated: boolean; }) => any) => {
  return {
    login: (isAuth: boolean) => dispatch({type: AuthenticationEnum.AUTHORIZATION, isAuthenticated: isAuth})
  }
};

export default withTranslation()(connect(null, mapDispatchToProps)(SignIn));