import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './utils/languageComponents/i18n';
import {I18nextProvider} from 'react-i18next';
import App from './App';
//import RootStore from "./globalStore/RootStore";
import {createStore} from "redux";
import Reducer from './store/Reducer';
import {Provider} from 'react-redux';

const store = createStore(Reducer);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </I18nextProvider></Provider>,
  document.getElementById('root')
);