import React from "react";
import './ProductDetailDescriptionComponent.scss'
import {WithTranslation, withTranslation} from "react-i18next";

interface Props extends WithTranslation{
    marks: string,
    title: string,
    description: string,
    image? : string
}

const ProductDetailDescriptionComponent: React.FC<Props> = (props: Props) => {
    const {t} = props;

    const keyWords = Array.from(t(props.marks, {returnObjects: true}));

    return (
        <div className="ProductDetailDescriptionContainer">
            <div className="MoreDetailDescriptionsContainer">
              <h4 style={{marginTop: '0px'}}>{t(props.title)}</h4>
              <p >
                {t(props.description)}
              </p>
            </div>
            <div className="ProductAttributes">
                {keyWords ? keyWords.map((value, index) => {
                    return (
                        <div key={index}> {value} {props.image && <img className="CorrectMark" alt="logo" src={props.image}/> } </div>
                    );
                }) : null}
            </div>
        </div>
    );
};

export default withTranslation()(ProductDetailDescriptionComponent);