import React, {MouseEventHandler} from 'react';
import classes from './Button.module.css';
import ArrowImg from "../../../assets/image/next.svg";

interface Props {
  text: string,
  isEmailButton: boolean,
  clickCallBack?: MouseEventHandler<HTMLButtonElement>,
  height?: number,
  width?: number,
  customStyle?: Object,
  responsive?: boolean
}

const Button: React.FunctionComponent<Props> = (props: Props) => {

  let buttonStyles;
  if(props.isEmailButton && props.responsive){
    buttonStyles = `${classes.ButtonResponsive} ${classes.EmailButton}`;
  }else if(props.isEmailButton || props.responsive){
    buttonStyles = props.isEmailButton ? `${classes.Button} ${classes.EmailButton}`: `${classes.ButtonResponsive}`;
  }else{
    buttonStyles = `${classes.Button}`;
  }

  const inlineStyle = {
    height: props.height,
    width: props.width,
    fontWeight: 100
  };

  let buttonComponent = (
    <button style={props.customStyle ? props.customStyle : (props.height && props.width ? inlineStyle : undefined)} className={buttonStyles} type="submit" onClick={props.clickCallBack}>
        <img className={classes.ButtonArrowImg} src={ArrowImg} alt="btnArrow"/>
        <div className={classes.ButtonText}>{props.text}</div>
    </button>);

  return (
    <React.Fragment>
      {buttonComponent}
    </React.Fragment>
  );
};

export default Button;