import React, {Component} from 'react'
import {withTranslation, WithTranslation} from "react-i18next";
import classes from "./Services.module.css"
import Button from "../../../common/button/Button";
import {Link} from "react-router-dom";

export class Services extends Component<WithTranslation> {
    render() {
        const {t} = this.props;
        return (
            <div className={classes.wrapper}>
                <div className={classes.proposal}>
                    <h1>{t("services.servicesProposalHeader")}</h1>
                    <p>{t("services.servicesProposalBody_1")}</p>
                    <p>{t("services.servicesProposalBody_2")}</p>
                </div>
                <section className={classes.services}>
                    <div className={classes.image}>
                        <img src={'./delga_services.png'} width={"100%"} height={"auto"} alt={"cosmetic bottles"}/>
                    </div>
                    <div className={classes.servicesDesc}>
                        <ul className={classes.servicesList}>
                            <li className={classes.servicesListItem}>
                                <h4 className={classes.serviceTitle}>{t("services.contractManufacturing")}</h4>
                                <p className={classes.serviceBody}>
                                    <span
                                        className={classes.serviceSubTitle}> {t("services.contractManufacturingBody.cosmetic")}</span>
                                    {t("services.contractManufacturingBody.cosmeticDesc")}
                                </p>
                                <p className={classes.serviceBody}>
                                    <span
                                        className={classes.serviceSubTitle}> {t("services.contractManufacturingBody.supplements")}</span>
                                    {t("services.contractManufacturingBody.supplementsDesc")}
                                </p>
                                <p className={classes.serviceBody}>
                                    <span
                                        className={classes.serviceSubTitle}> {t("services.contractManufacturingBody.veterinary")}</span>
                                    {t("services.contractManufacturingBody.veterinaryDesc")}
                                </p>
                            </li>
                            <li className={classes.servicesListItem}>
                                <h4 className={classes.serviceTitle}>{t("services.develop")}</h4>
                                <p className={classes.serviceBody}>
                                    {t("services.developDesc")}
                                </p>
                            </li>
                            <li className={classes.servicesListItem}>
                                <h4 className={classes.serviceTitle}>{t("services.legislative")}</h4>
                                <p className={classes.serviceBody}>
                                    {t("services.legislativeDesc")}
                                </p>
                            </li>
                            <li className={classes.servicesListItem}>
                                <h4 className={classes.serviceTitle}>{t("services.certification")}</h4>
                                <p className={classes.serviceBody}>
                                    {t("services.certificationDesc_1")}
                                </p>
                                <p className={classes.serviceBody}>
                                    {t("services.certificationDesc_2")}
                                </p>
                            </li>
                        </ul>
                    </div>

                </section>
                <div className={classes.buttonWrapper}>
                    <Link to={"/contact"} style={{cursor: "pointer"}}>
                        <Button text={t("services.contactBtn")} isEmailButton={false}/>
                    </Link>
                    <div className={classes.certificates}>
                        <div className={classes.imageContainer}>
                            <img src={'./GDP.png'} alt={"GDP certificate"}/>
                        </div>
                        <div className={classes.imageContainer}>
                            <img src={'./GMP.png'} alt={"GMP certificate"}/>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default withTranslation()(Services);