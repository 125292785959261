// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorPage_ErrorPage__37gIK{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-flow: column nowrap;\n        flex-flow: column nowrap;\n    -ms-flex-pack: start;\n        justify-content: flex-start;\n    -ms-flex-align: center;\n        align-items: center;\n    text-align: center;\n\n    box-sizing: border-box;\n    width:100%;\n    max-width: 1270px;\n    margin: 0 auto;\n    padding: 300px 50px 400px;\n}\n\n.ErrorPage_ErrorPageLabel__3cBFp{\n    margin:0 0 30px ;\n}\n\n.ErrorPage_ErrorDescription__2zqjL{\n    margin:0 0 60px;\n}\n\n@media all and (max-width: 500px) {\n    \n    .ErrorPage_ErrorPage__37gIK{\n        box-sizing: content-box;\n        box-sizing: initial;\n        width: unset;\n        max-width: unset;\n        margin:unset;\n    }\n}", ""]);
// Exports
exports.locals = {
	"ErrorPage": "ErrorPage_ErrorPage__37gIK",
	"ErrorPageLabel": "ErrorPage_ErrorPageLabel__3cBFp",
	"ErrorDescription": "ErrorPage_ErrorDescription__2zqjL"
};
module.exports = exports;
