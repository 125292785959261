import React from "react";
import './LoadingPage.scss';
import Backdrop from "./backdrop/Backdrop";


interface Props {
  show: boolean
}

const loadingPage: React.FunctionComponent<Props> = (props: Props) => {
  return (props.show ?
      <div style={{textAlign: 'center'}}>
        <Backdrop/>
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div> : null
  )
};

export default loadingPage;