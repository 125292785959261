import React,{useEffect,useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import "./ProductDescription.module.scss";
import image from "../../../../assets/image/close.png";
import ShowMoreText from 'react-show-more-text';

interface Props extends WithTranslation {
    buyElements : any,
    title : string,
    price : number,
    currency : string,
    discount? : number,
    description : any,
    volume : string
}

const ProductDescription: React.FC<Props> = (props: Props) => {
    const {t} = props;

    const [isCzechLang,setIsCzechLang] = useState(true);
    useEffect(() => {
      setIsCzechLang(["en","es"].includes(props.i18n.language)?false:true);
     }, [props.i18n.language]);

    return (
        <div className="ProductDescriptionContainer">
            {/*
            NOVINKA / NAZEV - pokud je isNew true
            props.productNamef
            <p><strong>NOVINKA</strong> <i>/ OMEGA 3 6 9</i></p>--
            */}
            <h1>{t(props.title)}</h1>
            {isCzechLang ? <h2>{props.price} {t('currency')}</h2>:null}
            {props.discount && <h3 className="Discount">{props.discount} {props.currency}</h3>}

            <div className="DesktopDescription">
                <span dangerouslySetInnerHTML={
                    {__html: t(props.description, {interpolation: {escapeValue: false}})}} 
                />
                <div className="Volume">
                    {t('productDetail.volume')}
                    {t(props.volume)}
                </div>
            </div>

            <div className="MobileDescription">
                <ShowMoreText
                            lines={2}
                            more={<div className="ShowMore"><p>{t("products.showMore")}</p><img src={image} alt="show_more_image"/></div>}
                            less={<div className="ShowMore"><p>{t("products.showLess")}</p></div>}
                            expanded={false}
                            anchorClass="AnchorClass"
                            width={1024}
                        >
                            <div className="MobileDescriptionContainer">
                                <span dangerouslySetInnerHTML={
                                {__html: t(props.description, {interpolation: {escapeValue: false}})}} 
                                />
                                <div className="Volume">
                                    {t('productDetail.volume')}<br/>
                                    {t(props.volume)}
                                </div>
                            </div>
                </ShowMoreText>
            </div>
            {isCzechLang ? props.buyElements : null}
        </div>
    );
};

export default withTranslation()(ProductDescription);
