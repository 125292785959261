import React from "react";
import './Checkbox.scss';

interface Props {
  text: string
  onClick?: () => void
}

const Checkbox: React.FC<Props> = (props: Props) => {
  return (
    <div className="content">
      <label className="container">{props.text}
        <input type="checkbox" onClick={props.onClick}/>
        <span className={"checkmark"}/>
      </label>
    </div>
  );
};

export default Checkbox;
