// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContactPage_ContactPage__kPGzl {\n    box-sizing: border-box;\n    width:100%;\n    max-width: 1270px;\n    margin: 0 auto;\n    padding: 0px 50px 200px;\n}\n\n.ContactPage_Title__1s16C {\n    font-weight: 500;\n    margin-top: 0px;\n    margin-bottom: 60px;\n    color: #000000;\n}\n\n.ContactPage_UnderLine__2BscC {\n    color: #000000;\n    background-color: #000000;\n    border-width: 0;\n    height: 1px;\n    margin:0;\n}\n\n.ContactPage_SubTitle__1WAbk {\n    color: #000000;\n    letter-spacing: 3px;\n    margin: 40px 0;\n    font-size: 12px;\n    font-family: Gotham-medium;\n    text-transform: uppercase;\n}\n\n.ContactPage_Paragraph__1nPd2 {\n    font-size: 13px;\n    line-height: 24px;\n    color: #262626;\n}\n\n.ContactPage_Paragraph__1nPd2:last-of-type {\n    margin-bottom: 0;\n}\n\n\n\n.ContactPage_Email__3cKr-{\n    padding: 5px;\n    background: linear-gradient(to bottom, transparent 0%,transparent 50%,rgba(108, 240, 152,0.5) 51%,rgba(108, 240, 152,0.5) 100%);\n    cursor: pointer;\n    color: black;\n    text-decoration: none;\n}\n\n@media screen and (max-width:500px){\n    \n    .ContactPage_Title__1s16C{\n        margin-top: 0px;\n    }\n\n    .ContactPage_ContactPage__kPGzl{\n        padding: 60px 50px 200px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"ContactPage": "ContactPage_ContactPage__kPGzl",
	"Title": "ContactPage_Title__1s16C",
	"UnderLine": "ContactPage_UnderLine__2BscC",
	"SubTitle": "ContactPage_SubTitle__1WAbk",
	"Paragraph": "ContactPage_Paragraph__1nPd2",
	"Email": "ContactPage_Email__3cKr-"
};
module.exports = exports;
