import React from "react";

import Button from "../../../../common/button/Button";

import "../editArticle/styles.scss";

export const ArticlePreview = (props) => {
  const {
    title,
    publicationDate,
    description,
    mySafeHTML,
    imageData,
    t,
    classes,
  } = props;
  return (
    <>
      {" "}
      <div className={classes.BlogPost}>
        <div className={classes.TitleAndDate}>
          <h1 className={classes.Title_Article}>{title}</h1>
          <h2 className={classes.PublicationDate}>{publicationDate}</h2>
        </div>
        <div className={classes.BlogContent}>
          <img alt="article" src={imageData} className={classes.Picture} />
          <div className={classes.TextAndButton}>
            <h4 className={classes.Text}>{description}</h4>
            <div style={{ margin: "1rem" }}>
              <Button text={t("blog.findOutMore")} isEmailButton={false} />
            </div>
          </div>
        </div>
      </div>
      <h1 className={classes.Title}>{title}</h1>
      <h2 className={classes.Date}>{publicationDate}</h2>
      <div
        className="BlogArticleContent"
        dangerouslySetInnerHTML={{ __html: mySafeHTML }}
      />{" "}
    </>
  );
};
