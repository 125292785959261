import React from "react";
import classes from "./Header.module.css";
import "./Header.scss";
import logo from "../../../assets/image/logo.svg";
import Menu from "./menu/Menu";
import DrawerToggleButton from "./slideDrawer/DrawerToggleButton";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteConstants} from "../../routes/RouteConstants";
import cartLogo from "../../../assets/image/cart_logo.svg";
import closeImage from "../../../assets/image/close.png";
import {connect} from "react-redux";
import ProductClass from "../../../templateObjects/product/ProductClass";
import {Location} from "../../../templateObjects/location/Location";

interface Props extends WithTranslation {
  drawerClickHandler: () => void;
  openCartSlider: () => void;
  show: boolean;
  cartItems?: Array<ProductClass>;
  location?: Location;
}

enum HeaderTitleRoutes {
  signin,
  register,
  password,
}

class Header extends React.Component<Props> {

  routeChange = (redirectPath: string) => {
    window.location.href = redirectPath;
  };

  locationName = window.location.href.split("/")[3];


  private getAmmountOfItems(cart:ProductClass[]):number{
    return cart.reduce((a, b) => a + b.amount, 0);
  }

  render() {
    const {t} = this.props;
    return (
      <header className={`${classes.Header} Header${this.props.location}`}>
        <div className={classes.HeaderMenu}>
          <div>
            {this.locationName in HeaderTitleRoutes ? (
              <img
                src={closeImage}
                className={classes.crossImage}
                style={{maxWidth: "15px"}}
                onClick={() => this.routeChange(RouteConstants.aboutUs)}
                alt="close"
              />
            ) : (
              <DrawerToggleButton click={this.props.drawerClickHandler}/>
            )}
          </div>
          {this.locationName in HeaderTitleRoutes ? (
            <div className={classes.loginHeading}>
              {t(`${this.locationName}.title`)}
            </div>
          ) : null}
          <div className={classes.logo}>
            <img alt="logo" src={logo} onClick={() => this.routeChange(RouteConstants.aboutUs)}/>
          </div>
          {!this.props.show && (
            <Menu openCartSlider={this.props.openCartSlider} cartItemsLength={this.props.cartItems?.length}/>
          )}
          <div className={classes.cart}>
            <div className={classes.cartItem}>
          <span>{(this.props.cartItems && this.props.cartItems.length > 0) ? this.getAmmountOfItems(this.props.cartItems): null}</span>
            </div>
            <div onClick={this.props.openCartSlider}>
              <img alt="logo" src={cartLogo}/>
            </div>
          </div>
        </div>
      </header>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    cartItems: state.cartItems,
    location: state.location
  }
};

export default withTranslation()(connect(mapStateToProps, null)(Header));