import "./styles.scss";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { ArticlePreview } from "../changeArticleComponents/ArticlePreview";
import { BlogUrlList } from "../blogUrlList";
import Button from "../../../../common/button/Button";
import DOMPurify from "dompurify";
import { PublicationDateOption } from "../changeArticleComponents/PublicationDateOption";
import { RouteComponentProps } from "react-router-dom";
import { TextEditor } from "../changeArticleComponents/TextEditor";
import { TitleDescriptionAndUpload } from "../changeArticleComponents/titleDescriptionAndUpload";
import axios from "axios";
import classes from "./CreateNewArticle.module.css";
import { connect } from "react-redux";

interface Props extends WithTranslation, RouteComponentProps {
  isAuthenticated?: boolean;
}

const CreateNewArticle: React.FC<Props> = (props) => {
  useEffect(() => {
    if (!props.isAuthenticated) {
      props.history.push("/");
    }
  }, [props.history, props.isAuthenticated]);

  const { t } = props;
  const [upladImage, setUpladImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [htmlText, setHtmlText] = useState("");
  const [publicationDate, setPublicationDate] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const handleChange = (content): void => {
    setHtmlText(content);
  };

  const required = true;

  const handleReaderLoaded = (readerEvt): void => {
    let binaryString = readerEvt.target.result;
    setUpladImage(`data:image/jpeg;base64,${btoa(binaryString)}`);
  };
  const uploadImageFile = (event): void => {
    let file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      // @ts-ignore
      reader.onload = handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const myHTML: string = `${htmlText}`;
  const mySafeHTML: string = DOMPurify.sanitize(myHTML);

  const checkData = (): boolean => {
    if (
      title.length &&
      description.length &&
      htmlText.length &&
      publicationDate.length &&
      upladImage.length
    ) {
      return true;
    }
    return false;
  };
  const sendArticle = (): void => {
    axios
      .post(BlogUrlList.save, {
        title: title,
        description: description,
        publicationDate: String(
          new Date(publicationDate.split(".").reverse().join(","))
        ),
        imageData: upladImage,
        htmlText: htmlText,
      })
      .then((res) => {
        res.status === 200
          ? alert(t("blog.createdSuccessfully"))
          : alert(t("blog.createdUnsuccessfully"));
        props.history.push("/blog");
      });
  };

  const publishArticle = (): void => {
    if (checkData()) {
      sendArticle();
    } else {
      alert(t("blog.somethingIsMissing"));
    }
  };

  return (
    <div className={classes.divMargin}>
      <h1>{t("blog.createNewArticle")}</h1>

      <TitleDescriptionAndUpload
        classes={classes}
        imageData={""}
        required={required}
        title={title}
        description={description}
        fileUpload={uploadImageFile}
        setTitle={setTitle}
        setDescription={setDescription}
        t={t}
      />

      <TextEditor handleChange={handleChange} text={""} t={t} />

      <PublicationDateOption
        setPublicationDate={setPublicationDate}
        originalDate={""}
        classes={classes}
        t={t}
        doNotChangeOption={false}
      />

      <div style={{ margin: "2rem" }}>
        <Button
          text={t("blog.publish")}
          isEmailButton={false}
          clickCallBack={() => publishArticle()}
        />
      </div>
      <div style={{ display: "flex" }}>
        <input
          type="checkbox"
          id="showPreview"
          onChange={() => setShowPreview((previous) => !previous)}
          name="publication_option"
        />
        <h2
          className={classes.PreviewTitle}
          style={{ marginBottom: "2rem", padding: "0 5px" }}
        >
          {t("blog.preview")}
        </h2>
      </div>
      {showPreview ? (
        <ArticlePreview
          title={title}
          description={description}
          publicationDate={publicationDate}
          mySafeHTML={mySafeHTML}
          imageData={upladImage}
          t={t}
          classes={classes}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: { isAuthenticated: boolean }) => {
  return {
    isAuthenticated: state.isAuthenticated,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(CreateNewArticle)
);
