import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import CartLogo from "../../../../assets/image/cart_logo.svg";
import style from "./CartHeader.module.css";
import ProductClass from "../../../../templateObjects/product/ProductClass";
import {connect} from "react-redux";


interface Props extends WithTranslation {
  hidePrice?: boolean,
  openCartSlider?: () => void,
  sliderAlignment?: boolean
  cartItems?: Array<ProductClass>,
  totalPriceOfCart?: number
}

class CartHeader extends React.Component<Props> {

  private getAmmountOfItems(cart:ProductClass[]):number{
    return cart.reduce((a, b) => a + b.amount, 0);
  }

  render() {
    const {t} = this.props;
    return (
      <div className={`${style.CartHeaderComponent} ${this.props.sliderAlignment && style.SliderCartAlign}`}
           onClick={this.props.openCartSlider}>
        {(this.props.cartItems && this.props.cartItems.length > 0) ? <div className={style.CartHeaderItem}>{this.getAmmountOfItems(this.props.cartItems)}</div> : null}
        <img className={style.CartIcon} alt="logo" src={CartLogo}/>
        {(this.props.totalPriceOfCart && this.props.totalPriceOfCart > 0 && !this.props.hidePrice) ?
          <div className={style.CartHeaderPrice}>-&nbsp;{this.props.totalPriceOfCart}&nbsp;{t('currency')}</div> : null}
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    cartItems: state.cartItems,
    totalPriceOfCart: state.totalPriceOfCart
  }
};

export default withTranslation()(connect(mapStateToProps, null)(CartHeader));

