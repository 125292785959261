import React from "react";
import DeliveryClass, {SelectMethod} from "../../../../templateObjects/delivery/DeliveryClass";
import RadioButtonsFields from "../../../common/radioButton/RadioButtonsFields";
import classes from "./CartSelectableComponent.module.css";
import {withTranslation, WithTranslation} from "react-i18next";
import Divider from "../../../common/divider/Divider";

interface Props extends WithTranslation{
    item: DeliveryClass,
    method: SelectMethod,
    index: number,
    select: (method: SelectMethod, index: number) => void;
    checkedByDefault: boolean,
    numberOfOptions:number
}

class CartSelectableComponent extends React.Component<Props>{

    handleClick() {
        this.props.select(this.props.method, this.props.index)
    }

    render() {
        const {t} = this.props;
        return (
            <>
            <div className={classes.CartSelectableComponentWrapper}>
                <span onClick={this.handleClick.bind(this)}>
                    <RadioButtonsFields checkedByDefault={this.props.checkedByDefault} gap={15}
                                        texts={[this.props.item.description]} name={"tmp"}/>
                </span>
                <p className={classes.CartSelectableComponentPrice}>{this.props.item.price}&nbsp;{t("currency")}</p>
            </div>
                {this.props.index !== this.props.numberOfOptions-1 && this.props.numberOfOptions>1 && <div className={classes.Divider}><Divider/></div>}
            </>
        )
    }
}

export default withTranslation()(CartSelectableComponent);