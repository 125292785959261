import React, {useState, ChangeEvent} from "react";
import Button from "../button/Button";
import classes from './EmailInputField.module.css';
import {WithTranslation, withTranslation} from 'react-i18next';
import CorrectMark from "../../../assets/image/correct.svg";
import Axios from "axios";

interface Props extends WithTranslation {
  placeHolder: string
  buttonText: string
  customStyles?: any
}

export enum State {
  ERROR,
  SUCCESS,
  EMPTY,
  NOT_EMPTY
}


const EmailInputField: React.FunctionComponent<Props> = (props: Props) => {

  const yup = require('yup');

  const {t} = props;

  const [disp,setDisp] = useState(false);
  const [value,setEmail] = useState("");
  const [valState,setValState] = useState(State.EMPTY);

  const validation = yup.string().email();

  const validateMe = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if(e.target.value === ""){
      setValState(State.EMPTY);
      return;
    }
    validation.isValid(value).then(changeState);
  };

  const changeState = (valid: boolean) => {
    setValState((valid) ? State.SUCCESS : State.ERROR);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if(valState === State.SUCCESS){
      Axios.post('/api/subscribe', {email: value, enviroment: window.location.hostname}).then((response: any) => {
        setDisp(true);
      }, (error) => {
        console.log(error)
      })
    }
  };

  return (
    <form className={classes.EmailComponent} style={props.customStyles ? props.customStyles.form : null}>
      <div className={!disp ? classes.Thanks : classes.Thanks + ' ' + classes.Displayed}>
        {t('footer.success').toUpperCase()}
        <img src={CorrectMark} alt={"correctMark"}/>
      </div>
      <input className={props.customStyles ? classes.CustomStyled : classes.Input} placeholder={props.placeHolder}
             style={props.customStyles ? props.customStyles.input : null} onChange={validateMe} value={value}/>
      <span className={classes.ButtonWrapper}>
        <Button text={props.buttonText} isEmailButton={true} clickCallBack={handleClick}
                    customStyle={props.customStyles && props.customStyles.button}/>
      </span>
      {valState === State.ERROR && <div className={classes.Error}>{t('footer.error')}</div>}
    </form>
  )
};

export default withTranslation()(EmailInputField);
