// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AboutUs_AboutUs__FOgr3 {\n    line-height: 2em;\n    box-sizing: border-box;\n    width:100%;\n    max-width: 1270px;\n    margin: 0 auto;\n    padding: 200px 50px;\n}\n\n.AboutUs_Header__3oHBz {\n    margin-bottom: 50px;\n    margin-top:0;\n}\n\n.AboutUs_Description__30Q9H {\n    margin-bottom: 50px;\n}\n\n@media all and (max-width: 500px){\n\n    .AboutUs_AboutUs__FOgr3{\n        padding: 150px 50px;\n    }\n\n    .AboutUs_Header__3oHBz{\n        padding-bottom: 40px;\n        margin-top: 0;\n        margin-bottom: 0;\n    }\n\n    .AboutUs_Description__30Q9H {\n        margin-top: 0px;\n    }\n\n    .AboutUs_ButtonContainer__968dG{\n        text-align: center;\n    }\n\n    .AboutUs_ButtonContainer__968dG button{\n        width: 80%;\n        height: 55px;\n        display: block;\n        margin: 0 auto;\n    }\n\n}\n\n@media all and (max-width: 360px) {\n    .AboutUs_ButtonContainer__968dG button{\n        height: 50px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"AboutUs": "AboutUs_AboutUs__FOgr3",
	"Header": "AboutUs_Header__3oHBz",
	"Description": "AboutUs_Description__30Q9H",
	"ButtonContainer": "AboutUs_ButtonContainer__968dG"
};
module.exports = exports;
