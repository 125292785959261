// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Spinner_Spinner__39WHM, .Spinner_SpinnerForSlider__RncZk{\n    display: -ms-flexbox !important;\n    display: flex !important;\n    -ms-flex-direction: row !important;\n        flex-direction: row !important;\n    -ms-flex-pack: distribute !important;\n        justify-content: space-around !important;\n    -ms-flex-align: center !important;\n        align-items: center !important;\n    background-color: #ededed;\n    border-radius: 35px;\n    font-weight: bold;\n    min-height: 52px ;\n    select: none;\n}\n\n.Spinner_SpinnerForSlider__RncZk{\n    min-height:30px;\n    height:30px;\n    width: 100px;\n    margin: auto 0px;\n    font-size: 14px;\n}\n\n.Spinner_Spinner__39WHM div, .Spinner_SpinnerForSlider__RncZk div{\n    cursor : pointer;\n    user-select: none;\n    -ms-user-select: none;\n    -webkit-user-select: none;\n}\n\n.Spinner_SpinnerButton__OpkEQ{\n    width:37.5%;\n    height: 100%;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: row;\n        flex-direction: row;\n    -ms-flex-pack: distribute;\n        justify-content: space-around ;\n    -ms-flex-align: center;\n        align-items: center;\n    font-size: 1.1em;\n    font-weight: bold;\n}\n\n.Spinner_Label__TlzWw{\n    width:25%;\n    height: 100%;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: row;\n        flex-direction: row;\n    -ms-flex-pack: distribute;\n        justify-content: space-around ;\n    -ms-flex-align: center;\n        align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"Spinner": "Spinner_Spinner__39WHM",
	"SpinnerForSlider": "Spinner_SpinnerForSlider__RncZk",
	"SpinnerButton": "Spinner_SpinnerButton__OpkEQ",
	"Label": "Spinner_Label__TlzWw"
};
module.exports = exports;
