import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import classes from "./Registration.module.css";
import RegistrationForm from "../../pageArtefacts/registrationFormComponent/RegistrationForm";

class Registration extends React.Component<WithTranslation> {

  handleSubmit = (event) => {

  };

  render() {
    const {t} = this.props;

    return (
      <div className={classes.Registration}>
          <h5 className={classes.Back}>
              <p onClick={() => window.history.back()}
                 style={{color: '#000000', textDecoration: 'none'}}>{t('registration.back').toUpperCase()}</p>
          </h5>
        <h1 className={classes.Title}>{t('registration.title')}</h1>
        <hr className={classes.UnderLine}/>
        <p className={classes.Info}>{t('registration.info')}</p>
        <RegistrationForm/>

        {/*<Button width={290} height={50} text={t('registration.submit').toUpperCase()} clickCallBack={handleClick} isEmailButton={false}/>*/}

        <form style={{display: "none"}} onSubmit={this.handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="username"
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

}

export default withTranslation()(Registration);