// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DrawerToggleButton_ToggleButton__vcvYf{\n    display: none;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    -ms-flex-pack: distribute;\n        justify-content: space-around;\n    height: 20px;\n    width: 20px;\n    background: transparent;\n    border: none;\n    cursor: pointer;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.DrawerToggleButton_ToggleButton__vcvYf:focus{\n    outline: none;\n}\n\n.DrawerToggleButton_ToggleButtonLine__lHPn9{\n    width: 20px;\n    height: 2px;\n    background: black;\n}\n\n@media (max-width: 1025px){\n    .DrawerToggleButton_ToggleButton__vcvYf{\n        display: -ms-flexbox;\n        display: flex;\n    }\n}", ""]);
// Exports
exports.locals = {
	"ToggleButton": "DrawerToggleButton_ToggleButton__vcvYf",
	"ToggleButtonLine": "DrawerToggleButton_ToggleButtonLine__lHPn9"
};
module.exports = exports;
