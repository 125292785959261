// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DiscountComponent_DiscountComponent__byKGI{\n    width: 470px;\n    position: relative;\n    background-color: #F6EBE7;\n    margin-right: auto;\n    margin-left: auto;\n    margin-top: 90px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-flow: row nowrap;\n        flex-flow: row nowrap;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n    height: 52px;\n}\n\n.DiscountComponent_Input__vb5FU {\n    font-family: Gotham-Light;\n    border: medium none currentColor;\n    border: initial;\n    height: 52px;\n    border-radius: 35px;\n    background-color: white;\n    outline: medium none invert;\n    outline: initial;\n    padding-left: 30px;\n    box-sizing: border-box;\n    color: #000000 !important;\n    width: 100%;\n}\n\n.DiscountComponent_Warning__1cmzk{\n    width: 100%;\n    color:red;\n    border: 1px solid red;\n    height: 50px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-flow: column nowrap;\n        flex-flow: column nowrap;\n    -ms-flex-pack: center;\n        justify-content: center;\n    padding:0 0 0 30px;\n    margin: 25px 0 50px;\n    border-radius:30px;\n    box-sizing: border-box;\n}\n\n.DiscountComponent_DiscountComponent__byKGI > button{\n    position: absolute;\n    right: 0;\n}\n\n@media screen and (max-width:700px){\n    .DiscountComponent_DiscountComponent__byKGI{\n        width: 100%;\n        max-width: 260px;\n        margin: 40px auto;\n    }\n\n    .DiscountComponent_Input__vb5FU {\n        height: 50px;\n        padding-left: 20px;\n    }\n\n    .DiscountComponent_DiscountComponent__byKGI > button{\n        width: 50px;\n        height: 50px;\n    }\n}\n\n\n", ""]);
// Exports
exports.locals = {
	"DiscountComponent": "DiscountComponent_DiscountComponent__byKGI",
	"Input": "DiscountComponent_Input__vb5FU",
	"Warning": "DiscountComponent_Warning__1cmzk"
};
module.exports = exports;
