export default class ProductClass {
  id: number;
  name: string;
  price: number;
  description: string | undefined;
  picture: string[];
  cartIcon: string;
  hoverPicture: string | undefined;
  /*
  * discount - old price of the product
  * */
  discount: number | undefined;
  isNew: boolean;
  amount: number;
  totalPriceByAmount: number;
  category: ProductCategory;
  volume: string;
  secondTitle: string;
  description2: string;
  keyWords: string;
  whyMe: string;
  usage: string;
  ingredients: string;
  extraIngredients: string;
  createdAt: string;
  recommandedProducts: Array<ProductClass> | undefined;
  nameWithCustomLines: string | undefined;

  constructor(id:number,name: string, price: number, description: string | undefined, picture: string[], cartIcon: string, hoverPicture: string | undefined,
              discount: number | undefined, isNew: boolean, amount: number, category: ProductCategory, volume: string,
              secondTitle: string, description2: string, keyWords: string, whyMe: string, usage: string,
              ingredients: string, extraIngredients: string, createdAt: string,
              recommandedProducts: Array<ProductClass> | undefined,nameWithCustomLines: string | undefined) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.description = description;
    this.picture = picture;
    this.cartIcon = cartIcon;
    this.hoverPicture = hoverPicture;
    this.discount = discount;
    this.isNew = isNew;
    this.amount = amount;
    this.category = category;
    this.volume = volume;
    this.secondTitle = secondTitle;
    this.description2 = description2;
    this.keyWords = keyWords;
    this.whyMe = whyMe;
    this.usage = usage;
    this.ingredients = ingredients;
    this.extraIngredients = extraIngredients;
    this.createdAt = createdAt;
    this.recommandedProducts = recommandedProducts;
    this.totalPriceByAmount = this.price * this.amount;
    this.nameWithCustomLines = nameWithCustomLines
  }
}

export enum ChangeAmountEnum {
  DECREMENT = 'DECREMENT',
  INCREMENT = 'INCREMENT'
}

export enum ProductActions {
  ADD_TO_CART = "ADD_TO_CART",
  REMOVE_FROM_CART = "REMOVE_FROM_CART",
  SET_SHIPPING = "ADD_SHIPPING",
  SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD",
  SET_PAYMENT = "ADD_PAYMENT",
  SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE",
  DELETE_CART = "DELETE_CART",
  SET_SHIPPING_TYPE = "SET_SHIPPING_TYPE",
  SET_PACKETA_DATA = "SET_PACKETA_DATA",
  SET_DISCOUNT = "SET_DISCOUNT",
  SET_DISCOUNT_VALUE = "SET_DISCOUNT_VALUE"
}

export enum ProductCategory {
  OMEGA = "OMEGA",
  COLLAGEN = "COLLAGEN",
  CBD = "CBD",
  ANTICEL = "ANTICEL"
}