import React from "react";
import SunEditor from "suneditor-react";
import czechLanguageTranslation from "../CzechLanguageTranslation";

export const TextEditor = (props) => {
  const { handleChange, t, text } = props;
  return (
    <SunEditor
      // @ts-ignore
      lang={czechLanguageTranslation}
      onChange={handleChange}
      defaultValue={text}
      width="90%"
      placeholder={t("blog.textEditorPlaceholder")}
      setOptions={{
        height: 200,
        buttonList: [
          ["font", "fontSize", "formatBlock"],
          [
            "bold",
            "underline",
            "italic",
            "strike",
            "fontColor",
            "hiliteColor",
            "align",
            "indent",
            "outdent",
            "list",
          ],
          ["image", "video", "link"],
          ["print", "preview"],
          ["codeView", "showBlocks", "undo", "redo"],
        ],
      }}
    />
  );
};
