import React from 'react';
import ReactTooltip from "react-tooltip";
import {withTranslation, WithTranslation} from "react-i18next";
import classes from "./LanguageSwitch.module.css";
import "./LanguageSwitch.module.css";
import lngChange from '../../../../utils/languageComponents/LanguageChangeAction';
import {Languages, LOCAL_STORAGE_LANG} from "../../../../utils/languageComponents/LanguageConstants";

const options = [
  {value: Languages.CS, label: 'CZ'},
  {value: Languages.EN, label: 'EN'},
 // {value: Languages.DE, label: 'DE'},
  {value: Languages.ES, label: 'ES'},
 // {value: Languages.RU, label: 'RU'}
];

type option = {
  value: string,
  label: string
}

class LanguageSwitch extends React.Component<WithTranslation> {

  _noShow = () => {
    document.getElementById("language")?.classList.remove("show")
  };

  _Show = () => {
    document.getElementById("language")?.classList.add("show")
  };




  _getLanguageSelected = (): option => {
    if (localStorage.getItem(LOCAL_STORAGE_LANG) == null) return options[0];
    else return options.filter((obj: option) => {
      return obj.value === localStorage.getItem(LOCAL_STORAGE_LANG)
    })[0]
  };

  state = {selectedOption: this._getLanguageSelected()};

  _handleChange = (selectedOption: option) => {
    this.setState({selectedOption});
    lngChange(selectedOption.value);
  };

  render() {
    return (
      <div className={classes.LanguageSwitch}>
        <div data-tip="" data-for='language' onClick={this._Show} onFocus={() => {this._Show()}} onMouseEnter={this._Show}><div> {this.state.selectedOption.label} </div></div>
        <ReactTooltip className='react-tooltip' id='language' place='bottom' type='light'
                      effect='solid' delayUpdate={10} delayHide={200}>
          {
            options.map((each: option, i: number) => {
              return (this.state.selectedOption.value === each.value) ? null :
                <div key={i} className={classes.LanguageList} onClick={() => {
                  this._noShow();
                  this._handleChange(each);
                }}>{each.label}</div>
            })
          }
        </ReactTooltip>
      </div>
    )
  }
}

export default withTranslation()(LanguageSwitch);