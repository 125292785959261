import React, {forwardRef} from "react";
import './RadioButtonsFields.scss';

interface Props {
    texts: string[],
    name: string,
    title?: string,
    gap?: number,
    checkedByDefault?: boolean,
    checkbox?: boolean
}

const RadioButtonsFields = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    let i = Date.now().toString() + Math.random().toString(36).substring(2, 15);

    return (
        <div className="RadioButtonFields">
            {
                props.title ? <p className="title">{props.title}</p> : null
            }
            {
                props.texts.map((text, index) => {
                    return (
                        <React.Fragment key={index}>
                            {!props.checkbox ?
                                <label className="container">
                                    <span style={{paddingLeft: props.gap ? props.gap : 0}}>{text}</span>
                                    <input defaultChecked={props.checkedByDefault} type="radio" name={props.name}
                                           value={text}/>
                                    <span className={"checkmark"}/>
                                </label> :
                                <label htmlFor={i} className="container" style={{cursor: "pointer"}}>
                                    <span style={{paddingLeft: props.gap ? props.gap : 0}}>{text}</span>
                                    <input ref={ref} id={i} style={{display: "none"}}
                                        defaultChecked={props.checkedByDefault}
                                        type="checkbox" name={props.name}
                                        value={text}/>
                                    <label htmlFor={i} className={"checkmark"} style={{cursor: "pointer"}}/>
                                </label>}
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
});

export default RadioButtonsFields;