import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import classes from './ContactPage.module.css';

class ContactPage extends React.Component<WithTranslation> {


  componentDidMount() {
    window.scrollTo(0,0);
  }

  componentDidUpdate() {
    window.scrollTo(0,0);
  }
  
  render() {
    const {t} = this.props;
    return (
      <div className={classes.ContactPage}>
        <h1 className={classes.Title}>{t('footer.contacts.title')}</h1>
        <hr className={classes.UnderLine}/>
        <h4 className={classes.SubTitle}>{t('footer.contacts.contactUs')}</h4>
        <p className={classes.Paragraph}><a className={classes.Email} href="mailto:info@delga.cz" target="_blank" rel="noopener noreferrer">info@delga.cz</a><br/>+420 737 824 991</p>
        <h4 className={classes.SubTitle}>{t('footer.contacts.departure')}</h4>
        <p className={classes.Paragraph}>
          DELGA s.r.o.<br/>
          17. listopadu 237<br/>
          530 02 Pardubice<br/>
          Česká republika<br/>
        </p>
      </div>
    )
  }
}

export default withTranslation()(ContactPage);