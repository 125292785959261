export const INSTAGRAM_ACCESS_TOKEN =
  "IGQVJYZAWhvYzY3MU1TTXctUlpyNGVuaFZAUWENDTHA5Ny05UFdrNndrbUl1S05PcS0xbnNLTmMtY2lFVzdEVEdLdkxDWk9ibVZASS04zX3RVNEVIdi04eWxnTkJPamg1cWo1N0xlV0pZAdk9qdnItak1XLQZDZD";
export const DISCOUNT_MULTIPLIER_DELGA2020 = 0.8;
export const DISCOUNT_MULTIPLIER_CSOB35 = 0.65;
export const DISCOUNT_MULTIPLIER_CLAIRE25 = 0.75;
export const PASSWORD =
  "sha1$5976055b$1$33363498a46a44a45a3ca939133e25a6fb7bfd6d";
export const USERNAME = "delgablog";

export enum DISCOUNT_CODE {
  DELGA2020 = "DELGA2020",
  CSOB35 = "CSOB35",
  CLAIRE25 = "CLAIRE25",
}

export const getDiscountMultiplierByDiscountCode = (
  discountCodeUsed: string
): number => {
  switch (discountCodeUsed) {
    case DISCOUNT_CODE.CSOB35:
      return DISCOUNT_MULTIPLIER_CSOB35;
    case DISCOUNT_CODE.DELGA2020:
      return DISCOUNT_MULTIPLIER_DELGA2020;
    case DISCOUNT_CODE.CLAIRE25:
      return DISCOUNT_MULTIPLIER_CLAIRE25;
    default:
      return 1;
  }
};

export const oneTimeCode = (code) => {
  if(code === "1EXPRES903" || code === "2EXPRES903" || code === "3EXPRES903" || code === "4EXPRES903" || code === "5EXPRES903")
    return 903;
  return 0;
}
