// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DropDownBox_DropDownBox__26NOD p:first-child{\n    margin-top: 40px;\n}\n\n.DropDownBox_DropDownContainer__1hcNh > div > span{\n    display: none;\n}\n\n@media screen and (min-width: 800px){\n    .DropDownBox_DropDownContainer__1hcNh > div > span{\n        display: inline-block;\n    }\n}\n\n@media screen and (max-width: 800px){\n    \n    .DropDownBox_DropDownContainer__1hcNh .DropDownBox_Ingredients__1CbwL {\n        -ms-flex-flow: column;\n            flex-flow: column;\n    }\n}\n\n.DropDownBox_OptionPart__3--13 {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: row;\n        flex-direction: row;\n    cursor: pointer;\n}\n\n.DropDownBox_OptionPart__3--13 > h5{\n    font-family: Gotham-medium;\n    font-size: 0.72rem;\n    font-weight: 600;\n    letter-spacing: 0.25rem;\n    padding-top: 40px;\n    padding-bottom: 40px;\n}\n\n.DropDownBox_DropDownBox__26NOD .DropDownBox_Option__1BQpk {\n    color: #000000;\n    letter-spacing: 3px;\n    margin-bottom: 0px !important;\n    margin-top: 0px !important;\n    width: 95%;\n    font-weight: 600;\n}\n\n.DropDownBox_DropDownBox__26NOD .DropDownBox_DropDownContainer__1hcNh{\n    border-bottom: 1px solid #000000;\n}\n\n.DropDownBox_DropDownBox__26NOD .DropDownBox_DropDownContainer__1hcNh:last-of-type{\n    border-bottom: none;\n}\n\n.DropDownBox_SlowDown__lCEdo{\n    display: none;\n    font-family: Myriad-Pro;\n    line-height: 1.5rem;\n}\n\n.DropDownBox_SlowDown__lCEdo > div:first-of-type{\n    padding-bottom: 20px;\n}\n\n.DropDownBox_OpenButton__2XIyG {\n    margin: auto;\n    width: 16px;\n    height: 16px;\n}\n\n.DropDownBox_UnderLine__384JW {\n    color: #000000;\n    background-color: #000000;\n    border-width: 0;\n    height: 1px;\n    margin-top: 40px;\n}", ""]);
// Exports
exports.locals = {
	"DropDownBox": "DropDownBox_DropDownBox__26NOD",
	"DropDownContainer": "DropDownBox_DropDownContainer__1hcNh",
	"Ingredients": "DropDownBox_Ingredients__1CbwL",
	"OptionPart": "DropDownBox_OptionPart__3--13",
	"Option": "DropDownBox_Option__1BQpk",
	"SlowDown": "DropDownBox_SlowDown__lCEdo",
	"OpenButton": "DropDownBox_OpenButton__2XIyG",
	"UnderLine": "DropDownBox_UnderLine__384JW"
};
module.exports = exports;
