// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartHeader_CartHeaderComponent__2Ekf8{\n    max-width: 180px;\n    text-align: center;\n    font-family: Gotham-Light;\n    font-weight: normal;\n    font-size: 12px;\n    letter-spacing: 3px;\n    text-align: center;\n    padding:5px 0px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    cursor: pointer;\n    margin-left: 3%;\n}\n.CartHeader_CartIcon__PQ0dE{\n    padding-top: 7px;\n    height: 20px;\n    width: 20px;\n}\n.CartHeader_CartHeaderItem__A-NWO{\n    margin-right: 20px;\n}\n.CartHeader_CartHeaderPrice__-t8y8{\n    margin-left: 20px;\n}\n.CartHeader_SliderCartAlign__1UuEG{\n    -ms-flex-pack: end;\n        justify-content: flex-end;\n    -ms-flex-align: center;\n        align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"CartHeaderComponent": "CartHeader_CartHeaderComponent__2Ekf8",
	"CartIcon": "CartHeader_CartIcon__PQ0dE",
	"CartHeaderItem": "CartHeader_CartHeaderItem__A-NWO",
	"CartHeaderPrice": "CartHeader_CartHeaderPrice__-t8y8",
	"SliderCartAlign": "CartHeader_SliderCartAlign__1UuEG"
};
module.exports = exports;
