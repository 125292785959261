class DeliveryClass{
    private _description:string;
    private _price:number;


    constructor(description: string, price: number) {
        this._description = description;
        this._price = price;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get price(): number {
        return this._price;
    }

    set price(value: number) {
        this._price = value;
    }
}

export enum SelectMethod {
    SHIPPING = "shippingMethods",
    PAYMENT = "paymentMethods"
}

export default DeliveryClass;