import React from "react";
import axios from "axios";
import {withTranslation, WithTranslation} from "react-i18next";
import {Link, RouteComponentProps} from "react-router-dom";
import {connect} from "react-redux";

import Button from "../../../common/button/Button";
import LoadingPage from "../../../common/loadingPage/LoadingPage";

import {RouteConstants} from "../../../routes/RouteConstants";
import {BlogUrlList} from "./blogUrlList";
import {returnCzechDateFormat} from "./functions";

import classes from "./Blog.module.css";

import {AuthenticationEnum} from "../../../../templateObjects/authentication/Authentication";


interface Props extends RouteComponentProps, WithTranslation {
  isAuthenticated?: boolean;
  logOff: (isAuth: boolean) => void;
}

interface Article {
  _id: string,
  title: string,
  publicationDate: string,
  description: string,
  imageData: string,
  htmlText: string
}

interface State {
  articles: Article[];
  loaded: boolean;
}

class Blog extends React.Component<Props, State, Article> {
  constructor(props) {
    super(props);
    this.state = {
      articles: [{
        _id: "",
        title: "",
        publicationDate: "",
        description: "",
        imageData: "",
        htmlText: ""
      }
      ]
      ,
      loaded: false,
    };
  }


  componentDidMount(): void {
    window.scrollTo(0, 0);
    axios
      .get(BlogUrlList.articles)
      .then((res) => {
        let {articles} = res.data;
        this.setState({articles});
      })
      .then(() => this.setState({loaded: true}));
  }

  deleteArticle = (id: string): void => {
    axios
      .delete(BlogUrlList.delete, {
        data: {id: id}
      })
      .then((res) => {
        res.status === 200
          ? alert(this.props.t("blog.deletedSuccessfully"))
          : alert(this.props.t("blog.deletedUnsuccessfully"));
        const articleToDelete = this.state.articles.find(article => article._id === id);
        if (articleToDelete) {
          const articleToDeleteIndex = this.state.articles.indexOf(articleToDelete);
          const updatedArticles = this.state.articles.slice()
          updatedArticles.splice(articleToDeleteIndex, 1)
          this.setState({articles: updatedArticles})
        }
      })
  };

  sortingMethodOption = {
    newerToOlder: "newerToOlder",
    olderToNewer: "olderToNewer"
  }

  sortByDate = (type: string, articleOnTheLeft: Article, articleOnTheRight: Article): number => {
    if (type === this.sortingMethodOption.newerToOlder)
      return (
        Number(new Date(articleOnTheRight.publicationDate)) -
        Number(new Date(articleOnTheLeft.publicationDate))
      );
    else if (type === this.sortingMethodOption.olderToNewer)
      return (
        Number(new Date(articleOnTheRight.publicationDate)) -
        Number(new Date(articleOnTheLeft.publicationDate))
      );
    return 0
  };

  renderArticle = (article: Article) => {
    const {t} = this.props;
    return (
      <div className={classes.BlogPost} key={article._id}>
        <div style={{display: "flex"}}>
          <div className={classes.TitleAndDate}>
            <Link
              to={`blog/${article._id}`}
              style={{
                color: "#000000",
                textDecoration: "none",
              }}
            >
              <h1 className={classes.Title}>{article.title}</h1>
            </Link>
            <h2 className={classes.PublicationDate}>
              {returnCzechDateFormat(article.publicationDate)}
            </h2>
          </div>
          {this.props.isAuthenticated ? (

            <div className={classes.EditOrDelete}>
              <Link
                to={`blog/edit/${article._id}`}
                style={{
                  color: "#000000",
                  textDecoration: "none",
                  marginRight: "2rem",
                }}
              >
                <h3 style={{position: "relative", bottom: "5px"}}>&#9997;</h3>
              </Link>
              <h3
                style={{cursor: "pointer"}}
                onClick={() => this.deleteArticle(article._id)}
              >
                &#10006;
              </h3>
            </div>
          ) : (
            <LoadingPage show={!this.state.loaded}/>
          )}
        </div>
        <div className={classes.BlogContent}>
          <Link
            to={`blog/${article._id}`}
            style={{
              color: "#000000",
              textDecoration: "none",
            }}
          >
            <img
              alt="article"
              src={article.imageData}
              className={classes.Picture}
            />
          </Link>
          <div className={classes.TextAndButton}>
            <h4 className={classes.Text}>{article.description}</h4>
            <Link
              to={`blog/${article._id}`}
              style={{
                color: "#000000",
                textDecoration: "none",
              }}
              className={classes.FindOutMore}
            >
              <Button
                text={t("blog.findOutMore")}
                isEmailButton={false}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {t} = this.props;

    const logOff = (isAuth: boolean) => {
      this.props.logOff(isAuth);
      this.props.history.replace('/aboutUs');
      document.documentElement.scrollTop = 0;
    };

    return (
      <React.Fragment>
        <div className={classes.BlogPage}>
          <h1 className={classes.PageTitle}>Blog</h1>
          <div style={{marginBottom: "1rem"}}>
            {this.props.isAuthenticated ? (
              <div className={classes.Buttons}>
                <Link to={RouteConstants.createArticle}>
                  <Button
                    text={t("blog.createNewArticle")}
                    isEmailButton={false}
                  />
                </Link>
                <Link to={RouteConstants.blog}>
                  <Button
                    text={t("blog.signOut")}
                    isEmailButton={false}
                    clickCallBack={() => {
                      logOff(false)
                    }}
                  />
                </Link>
              </div>
            ) : null}
          </div>
          {this.state.loaded ? (
            <div className={classes.Blog}>
              {this.state.articles
                .sort((articleOnTheLeft, articleOnTheRight) => this.sortByDate(this.sortingMethodOption.newerToOlder, articleOnTheLeft, articleOnTheRight))
                .map((article) => {
                  if (new Date(article.publicationDate) > new Date()) {
                    return null;
                  }
                  return this.renderArticle(article);
                })}
            </div>
          ) : (
            <LoadingPage show={!this.state.loaded}/>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: { isAuthenticated: boolean }) => {
  return {
    isAuthenticated: state.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOff: (isAuth: boolean) => dispatch({type: AuthenticationEnum.AUTHORIZATION, isAuthenticated: isAuth})
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Blog));