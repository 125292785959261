// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/myriad/myriad-pro-light.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/myriad/myriad-pro-black.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/fonts/gotham/Gotham-Light.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/fonts/gotham/Gotham-Book.otf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./assets/fonts/gotham/Gotham-Medium.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".App_App__16ZpL{\n    font-family: Gotham-Light;\n    color: #000000;\n    height: 100%;\n    overflow: hidden;\n    white-space: pre-line;\n}\n.App_MainPage__3TRIZ{\n    min-height: 750px;\n    position: relative;\n}\n\n@font-face {\n    font-family: Myriad-Pro;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: Myriad-Pro-Black;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: Gotham-Light;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: Gotham-Book;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n@font-face {\n    font-family: Gotham-Medium;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n    font-weight: normal;\n}\n\n@media all and (max-width: 1220px) {\n    .App_MainPage__3TRIZ {\n        min-height: 0px;\n        /*margin-bottom: 50px;*/\n    }\n}", ""]);
// Exports
exports.locals = {
	"App": "App_App__16ZpL",
	"MainPage": "App_MainPage__3TRIZ"
};
module.exports = exports;
