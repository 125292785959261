// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NewsComponent_News__3nDav {\n    background-color: #E6F5EE; \n}\n\n.NewsComponent_InnerSection__3s9mw {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    -ms-flex-direction: row;\n        flex-direction: row;\n    box-sizing: border-box;\n    width:100%;\n    max-width: 1270px;\n    margin: 0 auto;\n    padding: 0 50px;\n    transform: rotate(0deg);\n}\n\n@media all and (max-width: 1024px){\n\n    .NewsComponent_InnerSection__3s9mw{\n        height: unset !important;\n        min-height: unset !important;\n    }\n}\n\n\n@media all and (max-width: 700px){\n\n    .NewsComponent_InnerSection__3s9mw{\n        width: unset;\n        max-width: unset;\n        padding:unset;\n        padding-top: 120px;\n        margin: 0 0;\n        position: relative;\n        -ms-flex-direction: column;\n            flex-direction: column;\n        -ms-flex-align: center;\n            align-items: center;\n    }\n\n    .NewsComponent_News__3nDav{\n        box-sizing: content-box;\n        min-height: 0;\n    }\n}\n\n@media all and (max-width: 360px) {\n    .NewsComponent_ButtonContainer__p9KMP button{\n        height: 50px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"News": "NewsComponent_News__3nDav",
	"InnerSection": "NewsComponent_InnerSection__3s9mw",
	"ButtonContainer": "NewsComponent_ButtonContainer__p9KMP"
};
module.exports = exports;
