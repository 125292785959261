export enum UserDetailsEnum {
    SET_USER_DETAILS = "SET_USER_DETAILS"
}

export interface UserDetails {
    contact: { name: string, surname: string, email: string, phone: number|undefined },
    address: { address: string, city: string, zip: number|undefined, country: string, company: string, note: string }
    companyDetails: { firm: string, ico: number|undefined, dic: string }|undefined,
    secondaryContact: { name: string, surname: string, email: string, phone: number|undefined }|undefined,
    secondaryAddress: { address: string, city: string, zip: number|undefined, country: string, company: string, note: string }|undefined,
    newletterAllowed: boolean
}