// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button_Button__HKMEa {\n    background-color: #000000;\n    color: #FFFFFF;\n    font-weight: bold;\n    font-family: Gotham-Medium;\n    font-size: 12px;\n    letter-spacing: 2px;\n    width: 200px;\n    height: 52px;\n    border: medium none currentColor;\n    border: initial;\n    border-radius: 35px;\n    outline: medium none invert;\n    outline: initial;\n    user-select: none;\n    -ms-user-select: none;\n    -webkit-user-select: none;\n}\n\n.Button_ButtonResponsive__26f-1{\n    background-color: #000000;\n    color: #FFFFFF;\n    font-weight: bold;\n    font-family: Gotham-Medium;\n    letter-spacing: 2px;\n    width: 200px;\n    height: 52px;\n    border: medium none currentColor;\n    border: initial;\n    border-radius: 35px;\n    outline: medium none invert;\n    outline: initial;\n    user-select: none;\n    -ms-user-select: none;\n    -webkit-user-select: none;\n}\n\n.Button_Button__HKMEa:hover{\n    cursor: pointer;\n}\n\n.Button_EmailButton__2hSHM {\n    position: absolute;\n    right: 0px;\n    background-color: #FFFFFF;\n    color:black;\n}\n\n.Button_ButtonArrowImg__3XRVs{\n    display: none;\n}\n\n.Button_ButtonText__2UfBb{\n    display: -ms-flexbox;\n    display: flex;\n    display: -webkit-flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -webkit-justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n    -webkit-align-items: center;\n}\n\n@media all and (max-width: 500px) {\n\n    .Button_ButtonResponsive__26f-1 {\n        width: 50px;\n        left: -30px;\n    }\n    .Button_EmailButton__2hSHM{\n        max-width: 52px;\n        height: 52px;\n    }\n\n    .Button_EmailButton__2hSHM .Button_ButtonText__2UfBb{\n        display: none;\n    }\n\n    .Button_EmailButton__2hSHM .Button_ButtonArrowImg__3XRVs{\n        display: block;\n        width: 50%;\n        margin-left: 28%;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Button": "Button_Button__HKMEa",
	"ButtonResponsive": "Button_ButtonResponsive__26f-1",
	"EmailButton": "Button_EmailButton__2hSHM",
	"ButtonArrowImg": "Button_ButtonArrowImg__3XRVs",
	"ButtonText": "Button_ButtonText__2UfBb"
};
module.exports = exports;
