import './DisappearingFooterProductDetail.scss'
import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import Spinner from "../../../common/spinner/Spinner";
import Button from "../../../common/button/Button";
import ProductClass from "../../../../templateObjects/product/ProductClass";
import Select from "../../../common/select/Select";

interface State {
    visible: number
}

interface Props extends WithTranslation {
    initial: number,
    valueChanged: (value : number) => void,
    addProductToCart: () => void,
    customStylesButton,
    product: ProductClass
}

class DisappearingFooterProductDetail extends React.Component<Props, State> {

    private isCzechLang:boolean = ["en","es"].includes(this.props.i18n.language) ? false : true;

    render() {
        const {t} = this.props;

        const isVisibleElement = (ele:HTMLElement) => {
            const { top, bottom } = ele.getBoundingClientRect();
            const vHeight = (window.innerHeight || document.documentElement.clientHeight);

            return (
                (top > 0 || bottom > 0) &&
                top < vHeight
            );
        };

        window.addEventListener('scroll', function() {
            let section = document.getElementById("MainButton");
            let mainfooter = document.getElementById("BuyFooterWrapper");

            if(section && mainfooter){
                if(isVisibleElement(section))
                    mainfooter.classList.add("Hidden");
                else
                    mainfooter.classList.remove("Hidden");
            }
        });

        const selectStyles = {
            backgroundColor: 'white',
            border: '1px solid white',

        }

        return (
        <div id="BuyFooterWrapper" className="Hidden">
          <div>
            <div id="BuyFooter">
                <div>
                    <div>
                        <img src={this.props.product.picture && this.props.product.picture[0]} alt={t('productDetail.new')}/>
                    </div>
                    <div style={{margin: 'auto'}}>
                        <h6>{t(this.props.product.name)}</h6>
                    </div>
                </div>
                {this.isCzechLang ? 
                <div className="RightSide">
                    <div style={{width: '60px'}}>
                        <h2>{this.props.product.price}&nbsp;{t("currency")}</h2>
                        {
                            this.props.product.discount && <h3 className="Discount">{this.props.product.discount}&nbsp;{t("currency")}</h3>
                        }
                    </div>
                    <div className="SpinnerContainer">
                        <Spinner min={1} max={120} initial={this.props.initial} onMinus={this.props.valueChanged}
                                 onPlus={this.props.valueChanged}/>
                    </div>
                    <Select min={1} max={120} initial={this.props.initial} onChange={this.props.valueChanged} styleClass={selectStyles}/>

                    
                    <Button text={t('productDetail.addIntoCart').toUpperCase()} isEmailButton={false}
                            clickCallBack={this.props.addProductToCart}
                            customStyle={this.props.customStylesButton}/>
                </div>
                 : null}
            </div>
          </div>
        </div>
        );
    };
}

export default withTranslation()(DisappearingFooterProductDetail);