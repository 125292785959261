// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextPage_TextPage__3TTl- {\n    box-sizing: border-box;\n    width:100%;\n    max-width: 1270px;\n    margin: 0 auto;\n    padding: 0px 50px 200px;\n}\n\n.TextPage_Title__1TAeR {\n    font-weight: 500;\n    margin-top: 0px;\n    margin-bottom: 60px;\n    color: #000000;\n}\n\n.TextPage_UnderLine__MDiXy {\n    color: #000000;\n    background-color: #000000;\n    border-width: 0;\n    height: 1px;\n    margin:0;\n}\n\n.TextPage_TextPage__3TTl- h3,.TextPage_TextPage__3TTl- h4,.TextPage_TextPage__3TTl- p{\n    margin:40px 0;\n}\n\n.TextPage_TextPage__3TTl- h3,.TextPage_TextPage__3TTl- h4{\n    font-family: Gotham-medium;\n    text-transform: uppercase;\n    font-size: 15px;\n    letter-spacing: 3px;\n}\n\n.TextPage_Content__qSq_W p {\n    font-size: 15px;\n    line-height: 1.6em;\n}\n\n.TextPage_Content__qSq_W h4 {\n    color: #000000;\n}\n\n.TextPage_TextPage__3TTl- > p:last-of-type{\n    margin-bottom: 0;\n}\n\n.TextPage_TextPage__3TTl- > p:last-of-type > ul{\n    margin-bottom: 0;\n}\n\n\n@media screen and (max-width:500px){\n    \n    .TextPage_Title__1TAeR{\n        margin-top: 0px;\n    }\n\n    .TextPage_TextPage__3TTl-{\n        padding: 60px 50px 200px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"TextPage": "TextPage_TextPage__3TTl-",
	"Title": "TextPage_Title__1TAeR",
	"UnderLine": "TextPage_UnderLine__MDiXy",
	"Content": "TextPage_Content__qSq_W"
};
module.exports = exports;
