import React from 'react';
import style from "./SlideDrawer.module.css";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteConstants} from "../../../routes/RouteConstants";
import LanguageSwitch from "../languageSwitch/LanguageSwitch";
import './Slidedrawer.scss';
import closeIcon from '../../../../assets/image/close.png';
import facebookLogo from "../../../../assets/image/facebook_black.svg";
import instagramLogo from "../../../../assets/image/instagram_black.svg";
import cartLogo from "../../../../assets/image/cart_logo.svg";
import classes from './SlideDrawer.module.css';
import {connect} from "react-redux";
import ProductClass from "../../../../templateObjects/product/ProductClass";

interface Props extends WithTranslation{
    show: boolean
    click: () => void
    cartItems?: Array<ProductClass>
    openCartSlider: () => void
    closeDrawerSlider: () => void
}

class SideDrawer extends React.Component<Props> {

  _getClassName = (path: string) => {
    if(path === RouteConstants.aboutUs && window.location.pathname === '/') return style.SlideDrawerMenuOptionUnderlined;
    return (window.location.pathname.length > 1 && path.indexOf(window.location.pathname) > -1)? style.SlideDrawerMenuOptionUnderlined : style.SlideDrawerMenuOption;
  }

  _routeChange = (redirectPath:string) => {
    window.location.href = redirectPath
  };

  getAmountOfItems(cart:ProductClass[]):number{
    return cart.reduce((a, b) => a + b.amount, 0);
  }

  closeDrawerAndOpenCart = () => {
    this.props.closeDrawerSlider();
    this.props.openCartSlider();
  }

    render() {
      const {t} = this.props;
      return (
        <nav className={ !this.props.show ? style.SlideDrawer : style.SlideDrawer + ' ' + style.SlideDrawerOpen}>
          <div className={style.SlideDrawerContainer}>
            <div className={style.SlideDrawerCloseContainer}>
              <img className={style.SlideDrawerCloseIcon} src={closeIcon} alt="close_icon" onClick={this.props.click}/>
              <div className={classes.cart}>
                <div className={classes.cartItem}>
                  <span>{(this.props.cartItems && this.props.cartItems.length > 0) ? this.getAmountOfItems(this.props.cartItems): null}</span>
                </div>
                <div onClick={this.closeDrawerAndOpenCart}>
                  <img alt="logo" src={cartLogo}/>
                </div>
              </div>
            </div>
            <div className={classes.Wrapper}>
              <div className={style.SlideDrawerLinks}>
                <div className={this._getClassName(RouteConstants.aboutUs)}
                    onClick={() => this._routeChange(RouteConstants.aboutUs)}><strong>{t('header.aboutUs').toUpperCase()}</strong></div>
                <div className={ this._getClassName(RouteConstants.products) }
                    onClick={() => this._routeChange(RouteConstants.products)}>{t('header.product').toUpperCase()}</div>
                <div className={ this._getClassName(RouteConstants.blog) }
                    onClick={() => this._routeChange(RouteConstants.blog)}>{t('header.blog').toUpperCase()}</div>
                <div className={ this._getClassName(RouteConstants.services) }
                     onClick={() => this._routeChange(RouteConstants.services)}>{t('header.services').toUpperCase()}</div>
              {/*  <div className={this._getClassName(RouteConstants.signin) }
                    onClick={() => this._routeChange(RouteConstants.signin)}>{t('header.signin').toUpperCase()}</div>*/}
                {this.props.show && <div className='SlideDrawerMenuOptionLanguage'><LanguageSwitch/></div>}
              </div>
              <div className={style.SlideDrawerFooter}>
                <div className={classes.SlideDrawerFooterText}
                    onClick={() => this._routeChange(RouteConstants.productReturn)}>{t('footer.postal')}</div>
                <div className={classes.SlideDrawerFooterText}
                    onClick={() => this._routeChange(RouteConstants.personalTerms)}>{t('footer.details')}</div>
                <div className={classes.SlideDrawerFooterText}
                    onClick={() => this._routeChange(RouteConstants.terms)}>{t('footer.terms')}</div>
                <div className={classes.SlideDrawerFooterText}
                    onClick={() => this._routeChange(RouteConstants.faq)}>{t('footer.faq')}</div>
                <div className={classes.SlideDrawerFooterText}
                    onClick={() => this._routeChange(RouteConstants.contact)}>{t('footer.contact')}</div>
                <p className={style.SlideDrawerFooterImages}>
                  <a href={'https://www.facebook.com/kosmetikaspribehem'} rel="noopener noreferrer" target={'_blank'}><img
                    alt='facebookLogo'
                    className={style.FacebookLogo}
                    src={facebookLogo}/></a>
                  <a href={'https://www.instagram.com/delgacosmetics'} rel="noopener noreferrer" target={'_blank'}><img
                    alt='instagramLogo'
                    className={style.InstagramLogo}
                    src={instagramLogo}/></a>
                </p>
              </div>
            </div>
          </div>
        </nav>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartItems
  }
};

export default withTranslation()(connect(mapStateToProps, null)(SideDrawer));
