// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductsFilter_ProductsFilter__2Wu5V {\n    position: relative;\n    box-sizing: border-box;\n    width:100%;\n    max-width: 1270px;\n    margin: 0 auto;\n    padding: 40px 50px 100px;\n}\n\n.ProductsFilter_Container__1WhIg {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n.ProductsFilter_Label__2CTDd {\n    padding-bottom: 7px;\n}\n\n@media all and (max-width: 830px){\n    .ProductsFilter_Container__1WhIg{\n        \n    }\n}\n\n@media all and (max-width: 700px) {\n    .ProductsFilter_ProductsFilter__2Wu5V{\n        padding: 15px 10% 40px;\n    }\n\n    .ProductsFilter_Container__1WhIg{\n        -ms-flex-wrap: nowrap;\n            flex-wrap: nowrap;\n        width: 100%;\n        font-size: 6px;\n    }\n\n    .ProductsFilter_Container__1WhIg > div:first-of-type{\n        margin: 0 10px 0 0;\n    }\n\n    .ProductsFilter_Container__1WhIg > div{\n        width: 50%;\n    }\n\n    .ProductsFilter_Container__1WhIg > div > div > div:first-of-type {\n        height: 50px;\n    }\n\n    .ProductsFilter_Label__2CTDd{\n        display: none;\n    }\n\n    .ProductsFilter_RowLabels__4E3Zt{\n        display: none;\n    }\n}\n\n@media all and (max-width: 500px) {\n    .ProductsFilter_ProductsFilter__2Wu5V{\n        padding: 60px 20px 40px;\n    }\n\n}", ""]);
// Exports
exports.locals = {
	"ProductsFilter": "ProductsFilter_ProductsFilter__2Wu5V",
	"Container": "ProductsFilter_Container__1WhIg",
	"Label": "ProductsFilter_Label__2CTDd",
	"RowLabels": "ProductsFilter_RowLabels__4E3Zt"
};
module.exports = exports;
