import React,{useEffect} from 'react';
import classes from './NewsInfo.module.css';
import Button from "../../../../common/button/Button";
import ScrollButton from "../../../../common/scrollButton/ScrollButton";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteConstants} from "../../../../routes/RouteConstants";

interface Props extends WithTranslation{
  news: string,
  title: string,
  description: string,
  buttonText: string
}

const NewsInfo: React.FunctionComponent<Props> = (props: Props) => {

    const routeChange = (redirectPath: string, divId: string) => {
        window.location.href = redirectPath + (divId !== "" ? "#" + divId : "");
    };

    const updateMinHeight = ()=> {
      const newsComponent = document.getElementById("idNewsComponent");
      if(window.innerWidth < window.innerHeight && newsComponent){
        newsComponent.style.height = "unset";
        newsComponent.style.minHeight = "unset";
      }
      else{
        const height1 = document.getElementById("newsInfo1")?.offsetHeight;
        const height2 = document.getElementById("newsInfo2")?.offsetHeight;
        const height3 = document.getElementById("newsInfo3")?.offsetHeight;
        if(height1 && height2 && height3 && newsComponent){
          newsComponent.style.height = "calc(100vh - 140px)"
          newsComponent.style.minHeight = (height1 + height2 + height3 + 425) + "px";
        }
      }
    }
    
    useEffect(() => {
      window.addEventListener("resize",updateMinHeight);
      return window.addEventListener("resize",updateMinHeight);
    },[]);

    useEffect(() => {
      updateMinHeight();
    },[props.i18n.language]);

    return (
    <div className={classes.NewsInfo}>
      <div className={classes.NewsForYear}>
          <span className={classes.NewsDescription} id="newsInfo1">{props.news}</span>
      </div>
      <div><h1 className={classes.Header} id="newsInfo2">{props.title}</h1></div>
      <div className={classes.Description} id="newsInfo3">{props.description}</div>
      <div className={classes.Reactive}><Button text={props.buttonText.toUpperCase()} isEmailButton={false}
                   clickCallBack={e => routeChange(RouteConstants.products, "cbd")}/></div>
        <div className={classes.ScrollButton}>
            <ScrollButton/>
        </div>
    </div>
  )
};


export default withTranslation()(NewsInfo);
