import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import classes from "./Menu.module.css";
import LanguageSwitch from "../languageSwitch/LanguageSwitch";
import CartHeader from "../cartHeader/CartHeader";
import facebookLogo from "../../../../assets/image/facebook_black.svg";
import instagramLogo from "../../../../assets/image/instagram_black.svg";
import {RouteConstants} from "../../../routes/RouteConstants";
import {connect} from "react-redux";
// @ts-ignore
import PersonLogo from "../../../../assets/image/user_active.svg";

interface Props extends WithTranslation {
  isAuthenticated?: boolean,
  cartItemsLength?: number,
  openCartSlider: () => void
}

class Menu extends React.Component<Props> {
  _getClassName = (path: string) => {
    if (path === RouteConstants.aboutUs && window.location.pathname === "/")
      return classes.MenuOptionUnderlined;
    return window.location.pathname.length > 1 &&
    path.indexOf(window.location.pathname) > -1
      ? classes.MenuOptionUnderlined
      : classes.MenuOption;
  };

  _routeChange = (redirectPath: string) => {
    window.location.href = redirectPath;
  };


  render() {
    //hide on localhost signin/registration
    const renderContentOnLocalhost = () => {
      if (window.location.hostname === 'localhost') {
        return (
          this.props.isAuthenticated ? (
            <div
              className={classes.PersonLogo}
              onClick={() => this._routeChange(RouteConstants.userDetails)}
            >
              <img alt="PersonLogo" src={PersonLogo}/>
            </div>
          ) : (
            <div
              className={this._getClassName(RouteConstants.signin)}
              onClick={() => this._routeChange(RouteConstants.signin)}
            >
              {t("header.signin").toUpperCase()}
            </div>
          )
        )
      } else {
        return null;
      }
    };

    const {t} = this.props;
    return (
      <div className={classes.Menu}>
        <div
          className={this._getClassName(RouteConstants.aboutUs)}
          onClick={() => this._routeChange(RouteConstants.aboutUs)}
        >
          <strong>{t("header.aboutUs").toUpperCase()}</strong>
        </div>
      
        <div
          className={this._getClassName(RouteConstants.products)}
          onClick={() => this._routeChange(RouteConstants.products)}
        >
          {t("header.product").toUpperCase()}
        </div>
          <div
              className={this._getClassName(RouteConstants.services)}
              onClick={() => this._routeChange(RouteConstants.services)}
          >
              {t("header.services").toUpperCase()}
          </div>
        <div
          className={this._getClassName(RouteConstants.blog)}
          onClick={() => this._routeChange(RouteConstants.blog)}
        >
          <strong>{t("header.blog").toUpperCase()}</strong>
        </div>
        {renderContentOnLocalhost()}
        <LanguageSwitch/>
        <CartHeader openCartSlider={this.props.openCartSlider}/>
          <a
            rel="noopener noreferrer"
            href={"https://www.facebook.com/kosmetikaspribehem"}
            target={"_blank"}
            className={(this.props.cartItemsLength===0)?classes.FacebookLogo:classes.FacebookLogoFull}
          >
            <img
              alt="facebookLogo"   
              src={facebookLogo}
            />
          </a>
          <a
            rel="noopener noreferrer"
            href={"https://www.instagram.com/delgacosmetics"}
            target={"_blank"}
            className={classes.InstagramLogo}
          >
            <img
              alt="instagramLogo"
              src={instagramLogo}
            />
          </a>
      </div>
    );
  }
}

const mapStateToProps = (state: { isAuthenticated: boolean }) => {
  return {
    isAuthenticated: state.isAuthenticated,
  };
};

export default withTranslation()(connect(mapStateToProps, null)(Menu));
