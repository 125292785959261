import React from "react";
import classes from './ProductOverviewComponent.module.css';
import MainProduct from "../../../common/mainProduct/MainProduct";
import ProductClass from "../../../../templateObjects/product/ProductClass";
// @ts-ignore
// @ts-ignore
import Button from "../../../common/button/Button"
import {WithTranslation, withTranslation} from "react-i18next";
import ArrowImg from "../../../../assets/image/next.svg"
import {Link} from "react-router-dom";

interface Props extends WithTranslation {
  title: string,
  products: ProductClass[],
  buttonText: string
}

const ProductOverviewComponent: React.FunctionComponent<Props> = (props: Props) => {

  let slideIndex = 1;
  showSlides(slideIndex);

// Next/previous controls
  function plusSlides(n) {
    showSlides(slideIndex += n);
  }

// Thumbnail image controls
  function currentSlide(n) {
    showSlides(slideIndex = n);
  }

  function showSlides(n) {
    let i;
    let slides = document.getElementsByClassName(classes.mySlides) as HTMLCollectionOf<HTMLElement>;
    let dots = document.getElementsByClassName(classes.dot) as HTMLCollectionOf<HTMLElement>;
    if (n > slides.length) {slideIndex = 1}
    if (n < 1) {slideIndex = slides.length}
    for (i = 0;i < slides.length; i++) {
      let x = slides[i] as HTMLElement;
      x.style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(classes.active, classes.inactive);
    }
    if(slides[slideIndex - 1]){
      let y = slides[slideIndex-1] as HTMLElement;
      y.style.display = "block";
      dots[slideIndex-1].className += " " + classes.active;
    }
  }

  const {t} = props;
  
  let startX = 0;

  function touchStart(e: React.TouchEvent<HTMLElement>) {
    startX = e.touches[0].clientX;
    e.preventDefault();
  }

  
  function touchEnd(e: React.TouchEvent<HTMLElement>) {
    const change = startX - e.changedTouches[0].clientX;
    const treshold = window.innerWidth*0.8/3;
    if (change > treshold) {
      plusSlides(+1);
    }
    if (change < -treshold) {
      plusSlides(-1);
    }
    e.preventDefault();
  }


  return (
    <div id="products" className={classes.ProductOverview}>
      <div className={classes.InnerSection}>
        <h1 className={classes.Header}>{props.title}</h1>
          <div className={classes.ProductsSection}>
          {
            props.products.map((product, index) => {
              return <MainProduct buttonText={props.buttonText} key={index} product={product}/>
            })
          }
        </div>
        <div className={classes.ProductsSlider}>
          <div className={classes.slideshowContainer}>
            {props.products.map((product,index) => {
              return (
                  <div className={`${classes.mySlides} ${classes.fade}`} key={index}>
                    {product.discount ? product.price < product.discount ? <p className={classes.Info}><span
                        className={classes.Label}>{t("discount")}</span><span> / </span><span>{product.discount - product.price} {t("currency")}</span>
                    </p> : null : null}
                    <img src={product.picture[product.picture.length - 1]} className={classes.Image} alt="product" onTouchStart={e => {touchStart(e)}} onTouchEnd={e => {touchEnd(e)}}/>
                    <div className={classes.text}>{t(product.name)}</div>
                    <div className={classes.FindMoreButton}>
                      <Link style={{ textDecoration: 'none' }} to={
                        {
                          pathname: `/detail/${product.id}`
                        }
                      }><Button text={t("productPreview.buttonText")} isEmailButton={false} clickCallBack={() => {}}></Button>
                      </Link>
                    </div>
                  </div>
              )
            })}
            <div className={classes.prev} onClick={() => plusSlides(-1)}><img src={ArrowImg} alt="leftArrow" /></div>
            <div className={classes.next} onClick={() => plusSlides(1)}><img src={ArrowImg} alt="rightArrow" /></div>
          </div>
          <br/>
          <div className={classes.dotPackage}>
            {props.products.map((product, index) => {
              return <span className={classes.dot} onClick={() => currentSlide(index + 1)} key={index}/>
            })}
          </div>
        </div>
      </div>
    </div>

  )
};


export default withTranslation()(ProductOverviewComponent);