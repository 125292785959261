// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Menu_Menu__uV5am {\n  display: -ms-flexbox;\n  display: flex;\n  width: 90%;\n  margin-left: 3%;\n  line-height: 3;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  font-family: Gotham-Medium, sans-serif;\n  color:#000000;\n}\n\n.Menu_MenuOption__1RroZ,\n.Menu_MenuOptionUnderlined__2tMIG {\n  font-weight: bold;\n  font-size: 12px;\n  letter-spacing: 2px;\n  padding: 5px 0;\n  cursor: pointer;\n  text-align: center;\n  /*margin: 0 3%;*/\n}\n\n.Menu_MenuOptionUnderlined__2tMIG {\n  text-decoration: underline;\n}\n\n.Menu_PersonLogo__2N3Wv {\n  height: 50px;\n  width: 50px;\n}\n\n.Menu_PersonLogo__2N3Wv:hover {\n  cursor: pointer;\n}\n\n.Menu_MenuOption__1RroZ:hover {\n  text-decoration: underline;\n  font-weight: bold;\n}\n\n.Menu_FacebookLogoFull__3Rrnf{\n  margin-left:6%;\n}\n\n.Menu_FacebookLogo__3J9et{\n  margin-left:3%;\n}\n\n.Menu_InstagramLogo__10WsX{\n  margin-left:3%;\n}\n\n.Menu_FacebookLogo__3J9et img {\n  padding-top: 12px;\n  height: 20px;\n  width: 20px;\n}\n\n.Menu_FacebookLogoFull__3Rrnf img {\n  margin-top: 12px;\n  height: 20px;\n  width: 20px;\n}\n\n.Menu_InstagramLogo__10WsX img {\n  padding-top: 12px;\n  height: 20px;\n  width: 20px;\n}\n\n@media (max-width: 1025px) {\n  .Menu_Menu__uV5am {\n    display: none;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Menu": "Menu_Menu__uV5am",
	"MenuOption": "Menu_MenuOption__1RroZ",
	"MenuOptionUnderlined": "Menu_MenuOptionUnderlined__2tMIG",
	"PersonLogo": "Menu_PersonLogo__2N3Wv",
	"FacebookLogoFull": "Menu_FacebookLogoFull__3Rrnf",
	"FacebookLogo": "Menu_FacebookLogo__3J9et",
	"InstagramLogo": "Menu_InstagramLogo__10WsX"
};
module.exports = exports;
