// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Divider_divider__3_RX_ {\n    margin: 10px 0px;\n    border: none;\n    height: 1px;\n    background-color:black;\n}\n\n.Divider_CartDivider__mnMx-{\n    margin: 60px 0 0;\n    border: none;\n    width:100%;\n    height: 1px;\n    background-color:black;\n}\n\n.Divider_ShippingDivider__2I9af{\n    margin: 30px 0 50px;\n    border: none;\n    height: 1px;\n    background-color:black;\n}\n\n.Divider_OrderDivider__2zwtx {\n    margin: 0px;\n    border: none;\n    height: 1px;\n    background-color:black;\n}\n\n.Divider_SignInExpandableDivider__-12Il {\n    border: none;\n    height: 1px;\n    background-color:black;\n    margin: 60px 0;\n    width: 100%;\n}\n\n.Divider_CartOrderSummary__l3plN {\n    margin-top: 0px;\n    margin-bottom: 25px;\n    border: none;\n    height: 1px;\n    background-color:black;\n}\n\n.Divider_CartOrderSummaryBottom__1UnoI {\n    margin-top: 50px;\n    border: none;\n    height: 1px;\n    background-color:black;\n}\n\n@media all and (max-width: 500px) {\n    .Divider_CartDivider__mnMx- {\n        margin: 40px 0px 0px;\n    }\n\n    .Divider_ShippingDivider__2I9af {\n        margin: 30px 0 35px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"divider": "Divider_divider__3_RX_",
	"CartDivider": "Divider_CartDivider__mnMx-",
	"ShippingDivider": "Divider_ShippingDivider__2I9af",
	"OrderDivider": "Divider_OrderDivider__2zwtx",
	"SignInExpandableDivider": "Divider_SignInExpandableDivider__-12Il",
	"CartOrderSummary": "Divider_CartOrderSummary__l3plN",
	"CartOrderSummaryBottom": "Divider_CartOrderSummaryBottom__1UnoI"
};
module.exports = exports;
