import React from "react";
import classes from './NewsPictureBox.module.css';

interface Props {
  picture: string
}

const NewsPictureBox: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className={classes.NewsPictureBox} style={{backgroundImage: `url('${props.picture}')`}}/>
  )
};


export default NewsPictureBox;