// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeliveryOffer_DeliveryOffer__RLGWL {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-flow: row nowrap;\n        flex-flow: row nowrap;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    max-height: 40px;\n    width: 100%;\n    text-align: center;\n    color: #FFFFFF;\n    font-weight: 500;\n    background-color: #797C7B;\n    overflow: hidden;\n    top:0;\n    position: absolute;\n    z-index: 100;\n}\n\n.DeliveryOffer_DeliveryOffer__RLGWL img {\n    height: 16px;\n    width: 16px;\n    position: absolute;\n    right: 20px;\n    cursor: pointer;\n    transform: rotate(45deg);\n}\n\n.DeliveryOffer_DeliveryOffer__RLGWL p {\n    font-size: 12px;\n    text-transform: uppercase;\n    font-family: Gotham-Medium;\n    letter-spacing: 3px;\n    font-weight: bold;\n}\n\n.DeliveryOffer_Closed__3WFzi{\n    transition: max-height 0.5s ease-out;\n    max-height: 0px;\n}\n\n\n\n@media all and (max-width: 500px) {\n    .DeliveryOffer_DeliveryOffer__RLGWL {\n        display: none\n    }\n}", ""]);
// Exports
exports.locals = {
	"DeliveryOffer": "DeliveryOffer_DeliveryOffer__RLGWL",
	"Closed": "DeliveryOffer_Closed__3WFzi"
};
module.exports = exports;
