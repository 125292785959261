import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {RouteComponentProps} from "react-router-dom";
// @ts-ignore
import Logo from "../../../../assets/image/productDetail.PNG";
import "./ProductDetail.scss";
import {WithTranslation, withTranslation} from "react-i18next";
import ProductClass, {ProductActions} from "../../../../templateObjects/product/ProductClass";
import {connect} from 'react-redux';
import DropDownBox from "../../../common/dropdown/DropDownBox";
import DisappearingFooterProductDetail from '../../pageArtefacts/dissepearingFooterProductDetail/DisappearingFooterProductDetail';
import ProductDescription from "../../pageArtefacts/productDescription/ProductDescription";
import ProductPictureComponent from "../../pageArtefacts/productPictureComponent/ProductPictureComponent";
import ProductDetailDescriptionComponent
  from "../../pageArtefacts/productDetailDescriptionComponent/ProductDetailDescriptionComponent";
import CorrectMark from "../../../../assets/image/correct.svg";
import Spinner from "../../../common/spinner/Spinner";
import Button from "../../../common/button/Button";
import Product from "../../../common/product/Product";
import Select from "../../../common/select/Select";
import RecommendedProducts from "../../../../data/products/RecommendedProducts";

interface RouteInfo {
  id: string;
}

interface Props extends RouteComponentProps<RouteInfo>, WithTranslation {
  addToCart: (product: ProductClass) => void,
  openCart: () => void
}

interface State {
  count: number,
  product: ProductClass | undefined,
  COMBO_CONTENT: [string, string, string, string, string]
}

class ProductDetail extends React.Component<Props, State> {
  // @ts-ignore
  state = {
    count: 1,
    product: this.props.location.state as ProductClass,
    COMBO_CONTENT: ['','','','','']
  };

  componentDidMount(): void {
    window.scrollTo(0, 0);

    let correctProduct : ProductClass;
    RecommendedProducts.forEach(f => {
      let pomProduct = f.filter(a => {
        // @ts-ignore
        return a.id === parseInt(this.props.match.params.id,10)
      })
      if (pomProduct.length > 0) {
        correctProduct = pomProduct[0];
        return;
      }
    })
    // @ts-ignore
    const { whyMe, usage, extraIngredients, createdAt} = correctProduct;

    // @ts-ignore
    this.setState({product: correctProduct, COMBO_CONTENT: [whyMe, usage,'', extraIngredients, createdAt]});
  }

  addProductToCart() {
    const productCopy = {...this.state.product}
    productCopy.recommandedProducts = undefined;
    productCopy.amount = this.state.count;
    this.props.addToCart(productCopy);
    this.props.openCart();
  }

  render() {
    const {t} = this.props;

    const DEFAULT_CURRENCY = 'Kč';

    const TITLES = [
      "productDetail.part1",
      "productDetail.part2",
      "productDetail.part3",
      "productDetail.part4",
      "productDetail.part5",
    ];

    const customStylesButton = {
      marginTop: '40px',
      float: 'right'
    };

    const selectStyles = {
      backgroundColor: '#ededed',
      border: '1px solid #ededed',
      marginTop: '40px',
      marginRight: '10px'
    }

    const valueChanged = (value: number) => {
      this.setState({count: value})
    };


    const addProductToCart = () => {
        this.addProductToCart();
    };

    const BUY_ELEMENTS =
      <div id="MainButton">
        <div className={"SpinnerContainer"}>
          <Spinner min={1} max={120} initial={this.state.count} onMinus={valueChanged} onPlus={valueChanged}/>
        </div>
        <Select min={1} max={120} initial={this.state.count} onChange={valueChanged} styleClass={selectStyles}/>
        <Button text={t('productDetail.addIntoCart').toUpperCase()} isEmailButton={false}
                clickCallBack={addProductToCart} customStyle={customStylesButton}/>
      </div>;

    return (
      <div className="ProductDetailContainer">
        {
          this.state.product ? (
            <React.Fragment>
              <div className="InnerSection">
                <DisappearingFooterProductDetail initial={this.state.count} valueChanged={valueChanged}
                                                 customStylesButton={customStylesButton} product={this.state.product}
                                                 addProductToCart={addProductToCart}/>
                <div className="DetailContent">
                  <ProductPictureComponent showArrows={true}
                                           image={[this.state.product.picture && this.state.product.picture[0]]}
                                           alt={Logo} showStatus={false} showThumbs={false}
                                           transitionTime={1200}/>
                  <ProductDescription buyElements={BUY_ELEMENTS} title={this.state.product.name}
                                      currency={DEFAULT_CURRENCY} price={this.state.product.price}
                                      discount={this.state.product.discount}
                                      description={this.state.product.description} volume={this.state.product.volume}/>
                </div>
                <ProductDetailDescriptionComponent marks={this.state.product.keyWords}
                                                   description={this.state.product.description2}
                                                   title={this.state.product.secondTitle} image={CorrectMark}/>
              </div>
              <div className="ProductAdditionalContainer">
                <DropDownBox titles={TITLES} content={this.state.COMBO_CONTENT}
                             ingredientsContent={this.state.product.ingredients && this.state.product.ingredients}/>
              </div>
              <div className="InnerSection">
                <div className="RecommendProductsContainer">
                  <div className="RecommendProducts">
                    {this.state.product.recommandedProducts && <h4>{t("productDetail.recommend-title")}</h4>}
                    <div className="ProductList">
                      {this.state.product!.recommandedProducts?.map((product, index) => {
                        return (<span onClick={() => window.location.replace(`/detail/${product.id}`)} style={{cursor: 'pointer'}} key={index}><Product product={product}/></span>)
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null
        }

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (product: ProductClass) => dispatch({type: ProductActions.ADD_TO_CART, product: product})
  }
};

export default withTranslation()(connect(null, mapDispatchToProps)(ProductDetail));
