import React from 'react';
import style from "./Backdrop.module.css";

interface Props {
  click: () => void
}

const Backdrop: React.FC<Props> = (props: Props) => (
    <div className={style.Backdrop} onClick={props.click}/>
);

export default Backdrop;