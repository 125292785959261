// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ScrollButton_Scroll__2Rbcm{\n    width: 50px;\n    height: 50px;\n    border-radius: 100%;\n    border: 2px solid #707070;\n    font-size: 22px;\n    overflow: hidden; \n    cursor: pointer;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-flow: column;\n        flex-flow: column;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    margin: 0 auto;\n    position: absolute;\n    right: 0;\n    bottom: 5%; \n}\n\n.ScrollButton_Scroll__2Rbcm i{\n    color: #707070;\n    animation: ScrollButton_scrollDown__1beAo 2s ease infinite;\n    font-weight: 900;\n}\n\n@keyframes ScrollButton_scrollDown__1beAo{\n    0%{\n        transform: translateY(-15px);\n        opacity: 0;\n    }\n    45%{\n        transform: translateY(0px);\n        opacity: 1;\n    }\n    65%{\n        transform: translateY(-5px);\n        opacity: 1;\n    }\n    100%{\n        transform: translateY(15px);\n        opacity: 0;\n    }\n}\n\n@media all and (max-width:1024px){\n    .ScrollButton_Scroll__2Rbcm {\n        bottom: 2%;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Scroll": "ScrollButton_Scroll__2Rbcm",
	"scrollDown": "ScrollButton_scrollDown__1beAo"
};
module.exports = exports;
