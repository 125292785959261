import React from 'react';

import TextPageType from "../../../../templateObjects/textPageType/TextPageType";
// @ts-ignore
import TermsFile from "../../../../assets/documents/Terms.docx";

interface Props {
  type: TextPageType
}

const renderContentData = (props: Props) => {

  const subTitle = {
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '40px'
  } as React.CSSProperties;

  const paragraph = {
    fontSize: '13px',
    lineHeight: '24px',
    color: '#262626'
  } as React.CSSProperties;

  const content = () => {
    switch (props.type){
      case TextPageType.PERSONAL_DETAILS:
        return (
          <React.Fragment>
          <p style={paragraph}>Tyto zásady zpracování osobních údajů (dále jen „Zásady“) stanoví, jak DELGA s.r.o., se sídlem 17. listopadu 237, 530 02 Pardubice, IČO: 066 53 090 chrání a užívá veškeré informace získané prostřednictvím webového rozhraní www.delga.cz (dále jen „Webové rozhraní“).</p>
          <p style={paragraph}>Všechny poskytnuté informace používá delga.cz pouze v souladu s těmito Zásadami. Ochrana osobních dat je pro nás velmi důležitá, proto prosíme o pozorné seznámení s obsahem níže uvedených podmínek, jež informují o nakládání s vašimi osobními údaji a souvisejících právech a povinnostech.</p>
          <p style={paragraph}>Poskytnutím osobních údajů nám dáváte souhlas se zpracováním a správou údajů. Účel a použití údajů vysvětlujeme níže.</p>
          <h4 style={subTitle}>Správce osobních údajů</h4>
            <p style={paragraph}>DELGA s.r.o. <br/>
              17. listopadu 237<br/>
              530 02 Pardubice<br/></p>
            <p style={paragraph}>IČO: 066 53 090<br/>
              DIČ: CZ06653090<br/></p>
            <h4 style={subTitle}>Co jsou osobní údaje?</h4>
            <p style={paragraph}>Osobní údaje jsou informace, které vás mohou identifikovat jako konkrétní fyzickou osobu. Osobními údaji se rozumí zejména (ne výhradně): <br/>
            <ul><li>Identifikační údaje</li></ul>
              např. jméno a příjmení, datum narození, rodné číslo, pohlaví, přihlašovací údaje do uživatelského účtu
            <ul><li>Kontaktní údaje</li></ul>
              např. e-mailová adresa, mobilní telefonní číslo, pracovní telefonní číslo, adresa trvalého bydliště, korespondenční adresa, způsob úhrady, bankovní spojení
              <ul><li>Další údaje</li></ul>
              IP adresa, typ zařízení, prohlížeče a operačního systému, informace získání ze souborů cookies
            </p>
            <p style={paragraph}>
              Při nakládání s osobními údaji postupujeme v souladu s právním řádem České republiky a přímo použitelnými zdroji Evropské unie. Osobní údaje zpracováváme v souladu se zákonem číslo 101/2000 Sb., o ochraně osobních údajů, ve znění pozdějších předpisů a zákonem č. 480/2004 Sb., o některých službách informační společnosti, a dále dle příslušných ustanovení Občanského zákoníku, jakož i příslušných norem EU.
            </p>
            <h4 style={subTitle}>K čemu nám slouží získané informace?</h4>
            <p style={paragraph}>
              Osobní údaje, které nám poskytnete v rámci objednání zboží či zřízení uživatelského účtu, máme bez vašeho výslovného souhlasu právo zpracovávat výlučně za účelem plnění smlouvy, kterým se rozumí dodání zboží. V případě, že jste zboží neobjednali a pouze si zřídili uživatelský účet, můžeme bez vašeho výslovného souhlasu shromažďovat a zpracovávat osobní údaje výlučně za účelem umožnění přístupu, správy a vedení uživatelského účtu. Rovněž máme právo zpracovávat tyto údaje v rámci plnění našich zákonem stanovených povinností, např. povinnosti evidenční apod.
            </p>
            <p style={paragraph}>
              Získané informace nám umožní co nejefektivnější využít obsah Webového rozhraní, zajistit plnění smlouvy, komunikovat s vámi za účelem průzkumu trhu, na vaši e-mailovou adresu zaslat v případě udělení souhlasu obchodní sdělení, nabídku našich služeb a zboží (zasílání obchodních sdělení můžete kdykoli odmítnout), k internímu vedení záznamů, možnosti oznámit vám změny našich služeb a dalším.
            </p>
            <h4 style={subTitle}>Doba využití osobních údajů</h4>
            <p style={paragraph}>
              Vaše osobní údaje užíváme jen po dobu plnění smlouvy a právních povinností. Pokud nám dáte souhlas se zpracováním osobních údajů či užíváme vaši e-mailovou adresu pro zaslání obchodních sdělení, údaje jsou využívány po dobu fungování Webového rozhraní.
            </p>
            <h4 style={subTitle}>Jak spravujeme, zpracováváme a komu předáváme vaše osobní údaje?</h4>
            <p style={paragraph}>
              Pro bezpečnou správu osobních údajů zabezpečujeme Webové rozhraní a ostatní systémy pomocí technických a organizačních opatření proti ztrátě, rozšiřování či pozměňování vašich osobních údajů i proti přístupu neoprávněných osob.
            </p>
            <p style={paragraph}>V případě, že nakupujete bez registrace, tj. bez vytvoření uživatelského účtu, jsou vaše osobní údaje zpracovávány za účelem dokončení objednávky a jejího vyřízení (předání objednávky dopravci).</p>
            <p style={paragraph}>
              Poskytnete-li nám osobní údaje v rámci vytvoření uživatelského účtu, berete tak na vědomí, že budeme zpracovávat vámi sdělené údaje a informace ve vašem uživatelském účtu (historii objednávek a další s tím spojené informace). Pokud nám udělíte souhlas, budeme dále vyhodnocovat vaše uživatelské chování, abychom mohli zobrazovat obsah upravený přímo pro vás. V případě souhlasu se zasíláním obchodních sdělení vám budeme zasílat newslettery, z jejich odběru se můžete kdykoli odhlásit.
            </p>
            <p style={paragraph}>
              Vaše osobní údaje můžeme poskytnout externím společnostem, např. ke službám daňového poradce, účetního, externí firmy zajišťující marketing, společnostem zajišťujícím přepravu a doručení, poskytovatelům IT hostingu a údržby a dalším zpracovatelům osobních údajů, kteří pro nás pracují a se kterými máme uzavřenou odpovídající smlouvu. Pokud chcete vědět, kdo konkrétně vaše údaje zpracovává, poskytneme vám odpověď na základě dotazu.
            </p>
            <h4 style={subTitle}>Práva subjektů údajů</h4>
            <p style={paragraph}>
              V souvislosti se zpracováním osobních údajů vás informujeme o příslušných právních předpisech, tedy o:
            </p>
            <ul style={paragraph}>
              <li>Právu přístupu k osobním údajům (možnost požádat o informaci, zda provádíme zpracování vašich osobních údajů a za jakým účelem je zpracováváme) údaje</li>
            <li>Právu na opravu osobních údajů (na vaši žádost opravíme nepřesné údaje a/nebo doplníme neúplné údaje)</li>
            <li>Právu odvolat souhlas (jestliže zpracováváme vaše osobní údaje pouze na základě vašeho souhlasu, tedy bez jiného zákonného důvodu, můžete tento souhlas kdykoli odvolat prostřednictvím e-mailu zaslaného na adresu info@delga.cz, písemně formou dopisu na naši adresu či jiným způsobem)</li>
            <li>Právu na výmaz neboli Právu být zapomenut (právo požadovat smazání vašich osobních údajů)</li>
            <li>Právu na omezení zpracování osobních údajů (právo požadovat omezení vašich osobních údajů)</li>
            <li>Právu vznést námitku proti zpracování osobních údajů (můžete kdykoli vznést námitku proti zpracování vašich osobních údajů, pokud je zpracováváme pro účely přímého marketingu)</li>
            <li>Právu na přenositelnost údajů (právo požadovat osobní údaje, které jste nám poskytli)</li>
            </ul>
            <p style={paragraph}>
              Svá práva můžete uplatnit prostřednictvím našich kontaktních údajů, požadované informace vám bezodkladně poskytneme. Pokud nebudete spokojeni, máte právo se obrátit na Úřad pro ochranu zpracování osobních údajů, který dohlíží na ochranu osobních údajů. Kdykoli se můžete na Úřad ochrany pro zpracování osobních údajů obrátit přímo.
            </p>
            <p style={paragraph}>
              Zavazujeme se zajistit bezpečnost vašich dat, proto jsme zavedli vhodné postupy pro ochranu a zabezpečení informací, které v rámci Webového rozhraní shromažďujeme. Poskytnuté informace schraňujeme na našich zabezpečených serverech.
            </p>
            <h4 style={subTitle}>Soubory cookies</h4>
            <p style={paragraph}>
              Pojmem cookie se rozumí malý textový soubor ukládaný do počítače nebo jiného zařízení každého zákazníka Webového rozhraní. Cookies nám umožňují sběr informací o konkrétním prohlížeči, užití služeb (odkud uživatel přišel, jaký obsah si na Webovém rozhraní prohlíží, jaká je IP adresa terminálu apod.). Díky cookies si Webové rozhraní na určitou dobu zapamatuje vaše preference a úkony, které jste v rámci něj provedli, a my jsme tak schopni měřit návštěvnost a zobrazovat vám jakožto uživateli pouze relevantní reklamy. Cookies zjednodušují procházení webu, jakmile se na web opětovně vrátíte, díky cookies mohou být dříve zadané informace načteny, abyste mohli snadněji a rychleji využívat všechny funkce. Soubory cookies relace v počítači po zavření prohlížeče nezůstávají, trvalé soubory cookies zůstávají v počítači, dokud je neodstraníte nebo neskončí jejich platnost. Soubory cookies neumožňují identifikovat konkrétní osobu (jejich prostřednictvím nelze zjistit jméno, e-mailové adresy apod.).
            </p>
            <p style={paragraph}>
              Používání souborů cookies slouží k personalizaci obsahu a reklam, poskytování funkcí sociálních médií a analýze návštěvnosti. Díky nasbíraným informacím můžeme vytvořit detailní analýzu, na jejímž základě jsme vám jakožto uživatelům schopni poskytovat kvalitnější služby.
            </p>
            <h4 style={subTitle}>Zpracovatelé osobních údajů</h4>
            <p style={paragraph}>
              K osobním údajům má přístup správce, jeho zaměstnanci a případně také smluvní zpracovatelé. Zpracovatelem osobních údajů se rozumí fyzická nebo právnická osoba, orgán veřejné moci, agentura nebo jiný subjekt, který jménem správce zpracovává osobní údaje. Od správce se zpracovatel liší tím, že v rámci činnosti pro správce může provádět jen takové zpracovatelské operace, kterými jej správce pověří nebo vyplývají z činnosti, pro kterou byl zpracovatel správcem pověřen.
            </p>
            <p style={paragraph}>Přehled zpracovatelů osobních údajů vám poskytneme na základě dotazu.</p>
          </React.Fragment>)
      case TextPageType.TERMS:
        return (
          <React.Fragment>
            <p style={paragraph}>
              Obchodní podmínky obchodní společnosti DELGA s.r.o., 17. listopadu 237, 530 02 Pardubice, IČO: 066 53 090, DIČ: CZ06653090, pro prodej zboží prostřednictvím on-line obchodu umístěného na internetové adrese www.delga.cz.
            </p>
            <h4 style={subTitle}>ÚVODNÍ USTANOVENÍ</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Tyto obchodní podmínky (dále jen „<strong>obchodní podmínky</strong>“) obchodní společnosti <strong>DELGA s.r.o.</strong>, se sídlem 17. listopadu 237, 530 02 Pardubice, identifikační číslo: 066 53 090 (dále jen „<strong>prodávající</strong>“) upravují v souladu s ustanovením § 1751 odst. 1 zákona č. 89/2012 Sb., občanský zákoník (dále jen „<strong>občanský zákoník</strong>“) vzájemná práva a povinnosti smluvních stran vzniklé v souvislosti nebo na základě kupní smlouvy (dále jen „<strong>kupní smlouva</strong>“) uzavírané mezi prodávajícím a jinou fyzickou osobou (dále jen „<strong>kupující</strong>“) prostřednictvím internetového obchodu prodávajícího. Internetový obchod je prodávajícím provozován na webové stránce umístněné na internetové adrese <strong>www.delga.cz</strong> (dále jen „webová stránka“), a to prostřednictvím rozhraní webové stránky (dále jen „<strong>webové rozhraní obchodu</strong>“).
                </li>
                <li>
                  Obchodní podmínky se nevztahují na případy, kdy osoba, která má v úmyslu nakoupit zboží od prodávajícího, je právnickou osobou či osobou, jež jedná při objednávání zboží v rámci své podnikatelské činnosti nebo v rámci svého samostatného výkonu povolání.
                </li>
                <li>
                  Ustanovení odchylná od obchodních podmínek je možné sjednat v kupní smlouvě. Odchylná ujednání v kupní smlouvě mají přednost před ustanoveními obchodních podmínek.
                </li>
                <li>
                  Ustanovení obchodních podmínek jsou nedílnou součástí kupní smlouvy. Kupní smlouva a obchodní podmínky jsou vyhotoveny v českém jazyce. Kupní smlouvu lze uzavřít v českém jazyce.
                </li>
                <li>
                  Znění obchodních podmínek může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>UŽIVATELSKÝ ÚČET</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Na základě registrace kupujícího provedené na webové stránce může kupující přistupovat do svého uživatelského rozhraní. Ze svého uživatelského rozhraní může kupující provádět objednávání zboží (dále jen „<strong>uživatelský účet</strong>“). V případě, že to webové rozhraní obchodu umožňuje, může kupující provádět objednávání zboží též bez registrace přímo z webového rozhraní obchodu.
                </li>
                <li>
                  Při registraci na webové stránce a při objednávání zboží je kupující povinen uvádět správně a pravdivě všechny údaje. Údaje uvedené v uživatelském účtu je kupující při jakékoliv jejich změně povinen aktualizovat. Údaje uvedené kupujícím v uživatelském účtu a při objednávání zboží jsou prodávajícím považovány za správné.
                </li>
                <li>
                  Přístup k uživatelskému účtu je zabezpečen uživatelským jménem a heslem. Kupující je povinen zachovávat mlčenlivost ohledně informací nezbytných k přístupu do jeho uživatelského účtu.
                </li>
                <li>
                  Kupující není oprávněn umožnit využívání uživatelského účtu třetím osobám.
                </li>
                <li>
                  Prodávající může zrušit uživatelský účet, a to zejména v případě, kdy kupující svůj uživatelský účet déle než dva roky nevyužívá, či v případě, kdy kupující poruší své povinnosti z kupní smlouvy (včetně obchodních podmínek).
                </li>
                <li>
                  Kupující bere na vědomí, že uživatelský účet nemusí být dostupný nepřetržitě, a to zejména s ohledem na nutnou údržbu hardwarového a softwarového vybavení prodávajícího, popř. nutnou údržbu hardwarového a softwarového vybavení třetích osob.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>UZAVŘENÍ KUPNÍ SMLOUVY</h4>
            <p style={paragraph}>
              <ol>
                <li>Veškerá prezentace zboží umístěná ve webovém rozhraní obchodu je informativního charakteru a prodávající není povinen uzavřít kupní smlouvu ohledně tohoto zboží. Ustanovení § 1732 odst. 2 občanského zákoníku se nepoužije.</li>
              <li>
                Webové rozhraní obchodu obsahuje informace o zboží, a to včetně uvedení cen jednotlivého zboží. Ceny zboží jsou uvedeny včetně daně z přidané hodnoty a všech souvisejících poplatků. Ceny zboží zůstávají v platnosti po dobu, kdy jsou zobrazovány ve webovém rozhraní obchodu. Tímto ustanovením není omezena možnost prodávajícího uzavřít kupní smlouvu za individuálně sjednaných podmínek.
              </li>
                <li>Webové rozhraní obchodu obsahuje také informace o nákladech spojených s balením a dodáním zboží. Informace o nákladech spojených s balením a dodáním zboží uvedené ve webovém rozhraní obchodu platí pouze v případech, kdy je zboží doručováno v rámci území České republiky.</li>
<li>Pro objednání zboží vyplní kupující objednávkový formulář ve webovém rozhraní obchodu. Objednávkový formulář obsahuje zejména informace o:
<ol type="i">
  <li>
    objednávaném zboží (objednávané zboží „vloží“ kupující do elektronického nákupního košíku webového rozhraní obchodu),
  </li>
  <li>
    způsobu úhrady kupní ceny zboží, údaje o požadovaném způsobu doručení objednávaného zboží a
  </li>
  <li>
    informace o nákladech spojených s dodáním zboží (dále společně jen jako „<strong>objednávka</strong>“).
  </li>
</ol></li>
                <li>
                  Před zasláním objednávky prodávajícímu je kupujícímu umožněno zkontrolovat a měnit údaje, které do objednávky kupující vložil, a to i s ohledem na možnost kupujícího zjišťovat a opravovat chyby vzniklé při zadávání dat do objednávky. Objednávku odešle kupující prodávajícímu kliknutím na tlačítko „odeslat objednávku“. Údaje uvedené v objednávce jsou prodávajícím považovány za správné. Prodávající neprodleně po obdržení objednávky toto obdržení kupujícímu potvrdí elektronickou poštou, a to na adresu elektronické pošty kupujícího uvedenou v uživatelském rozhraní či v objednávce (dále jen „<strong>elektronická adresa kupujícího</strong>“).
                </li>
                <li>
                  Prodávající je vždy oprávněn v závislosti na charakteru objednávky (množství zboží, výše kupní ceny, předpokládané náklady na dopravu) požádat kupujícího o dodatečné potvrzení objednávky (například písemně či telefonicky).
                </li>
                <li>
                  Smluvní vztah mezi prodávajícím a kupujícím vzniká doručením přijetí objednávky (akceptací), jež je prodávajícím zasláno kupujícímu elektronickou poštou, a to na adresu elektronické pošty kupujícího.
                </li>
                <li>
                  Smluvní vztah mezi prodávajícím a kupujícím vzniká doručením přijetí objednávky (akceptací), jež je prodávajícím zasláno kupujícímu elektronickou poštou, a to na adresu elektronické pošty kupujícího.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>CENA ZBOŽÍ A PLATEBNÍ PODMÍNKY</h4>
            <p style={paragraph}>
            <ol>
              <li>
                Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit prodávajícímu následujícími způsoby:
              </li>
              <li>
                bezhotovostně převodem na účet prodávajícího, vedený u společnosti ČSOB banka (dále jen „<strong>účet prodávajícího</strong>“);<br/>
                Číslo účtu pro platby v korunách: 293966625/0300<br/>
                Číslo účtu pro platbu v eurech: 294042520/0300<br/>
                IBAN: CZ47 0300 0000 0002 9404 2520<br/>
                BIC: CEKOCZPP
              </li>
              <li>
                bezhotovostně prostřednictvím platebního systému
              </li>
              <li>
                Společně s kupní cenou je kupující povinen zaplatit prodávajícímu také náklady spojené s balením a dodáním zboží ve smluvené výši. Není-li uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady spojené s dodáním zboží.
              </li>
              <li>
                Prodávající nepožaduje od kupujícího zálohu či jinou obdobnou platbu. Tímto není dotčeno ustanovení čl. 4.6 obchodních podmínek ohledně povinnosti uhradit kupní cenu zboží předem.
              </li>
              <li>
                V případě platby v hotovosti či v případě platby na dobírku je kupní cena splatná při převzetí zboží. V případě bezhotovostní platby je kupní cena splatná do 7 dnů od uzavření kupní smlouvy.
              </li>
              <li>
                V případě bezhotovostní platby je kupující povinen uhrazovat kupní cenu zboží společně s uvedením variabilního symbolu platby. V případě bezhotovostní platby je závazek kupujícího uhradit kupní cenu splněn okamžikem připsání příslušné částky na účet prodávajícího.
              </li>
              <li>
                Prodávající je oprávněn, zejména v případě, že ze strany kupujícího nedojde k dodatečnému potvrzení objednávky (čl. 3.6), požadovat uhrazení celé kupní ceny ještě před odesláním zboží kupujícímu. Ustanovení § 2119 odst. 1 občanského zákoníku se nepoužije.
              </li>
              <li>Případné slevy z ceny zboží poskytnuté prodávajícím kupujícímu nelze vzájemně kombinovat.</li>
            <li>
              Je-li to v obchodním styku obvyklé nebo je-li tak stanoveno obecně závaznými právními předpisy, vystaví prodávající ohledně plateb prováděných na základě kupní smlouvy kupujícímu daňový doklad – fakturu. Prodávající je plátcem daně z přidané hodnoty. Daňový doklad – fakturu vystaví prodávající kupujícímu po uhrazení ceny zboží a zašle jej v elektronické podobě na elektronickou adresu kupujícího.
            </li>
            </ol>
          </p>
            <h4 style={subTitle}>ODSTOUPENÍ OD KUPNÍ SMLOUVY</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Kupující bere na vědomí, že dle ustanovení § 1837 občanského zákoníku, nelze mimo jiné odstoupit od kupní smlouvy o dodávce zboží, které bylo upraveno podle přání kupujícího nebo pro jeho osobu, od kupní smlouvy o dodávce zboží, které podléhá rychlé zkáze, jakož i zboží, které bylo po dodání nenávratně smíseno s jiným zbožím, od kupní smlouvy o dodávce zboží v uzavřeném obalu, které spotřebitel z obalu vyňal a z hygienických důvodů jej není možné vrátit a od kupní smlouvy o dodávce zvukové nebo obrazové nahrávky nebo počítačového programu, pokud porušil jejich původní obal.
                </li>
                <li>
                  Nejedná-li se o případ uvedený v čl. 5.1 či o jiný případ, kdy nelze od kupní smlouvy odstoupit, má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy odstoupit, a to do čtrnácti (14) dnů od převzetí zboží, přičemž v případě, že předmětem kupní smlouvy je několik druhů zboží nebo dodání několika částí, běží tato lhůta ode dne převzetí poslední dodávky zboží. Odstoupení od kupní smlouvy musí být prodávajícímu odesláno ve lhůtě uvedené v předchozí větě. Pro odstoupení od kupní smlouvy může kupující využit vzorový formulář poskytovaný prodávajícím, jenž tvoří přílohu obchodních podmínek.  Odstoupení od kupní smlouvy může kupující zasílat mimo jiné na adresu provozovny prodávajícího či na adresu elektronické pošty prodávajícího Delga.cz, Lonkova 510, 530 09 Pardubice.
                </li>
                <li>
                  V případě odstoupení od kupní smlouvy dle čl. 5.2 obchodních podmínek se kupní smlouva od počátku ruší. Zboží musí být prodávajícímu vráceno do čtrnácti (14) dnů od odstoupení od smlouvy prodávajícímu. Odstoupí-li kupující od kupní smlouvy, nese kupující náklady spojené s navrácením zboží prodávajícímu, a to i v tom případě, kdy zboží nemůže být vráceno pro svou povahu obvyklou poštovní cestou.
                </li>
                <li>
                  V případě odstoupení od smlouvy dle čl. 5.2 obchodních podmínek vrátí prodávající peněžní prostředky přijaté od kupujícího do čtrnácti (14) dnů od odstoupení od kupní smlouvy kupujícím, a to stejným způsobem, jakým je prodávající od kupujícího přijal. Prodávající je taktéž oprávněn vrátit plnění poskytnuté kupujícím již při vrácení zboží kupujícím či jiným způsobem, pokud s tím kupující bude souhlasit a nevzniknou tím kupujícímu další náklady. Odstoupí-li kupující od kupní smlouvy, prodávající není povinen vrátit přijaté peněžní prostředky kupujícímu dříve, než mu kupující zboží vrátí nebo prokáže, že zboží podnikateli odeslal.
                </li>
                <li>
                  Nárok na úhradu škody vzniklé na zboží je prodávající oprávněn jednostranně započíst proti nároku kupujícího na vrácení kupní ceny.
                </li>
                <li>
                  Do doby převzetí zboží kupujícím je prodávající oprávněn kdykoliv od kupní smlouvy odstoupit. V takovém případě vrátí prodávající kupujícímu kupní cenu bez zbytečného odkladu, a to bezhotovostně na účet určený kupujícím.
                </li>
                <li>
                  Je-li společně se zbožím poskytnut kupujícímu dárek, je darovací smlouva mezi prodávajícím a kupujícím uzavřena s rozvazovací podmínkou, že dojde-li k odstoupení od kupní smlouvy kupujícím, pozbývá darovací smlouva ohledně takového dárku účinnosti a kupující je povinen spolu se zbožím prodávajícímu vrátit i poskytnutý dárek.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>PŘEPRAVA A DODÁNÍ ZBOŽÍ</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  V případě, že je způsob dopravy smluven na základě zvláštního požadavku kupujícího, nese kupující riziko a případné dodatečné náklady spojené s tímto způsobem dopravy.
                </li>
                <li>
                  Je-li prodávající podle kupní smlouvy povinen dodat zboží na místo určené kupujícím v objednávce, je kupující povinen převzít zboží při dodání.
                </li>
                <li>
                  V případě, že je z důvodů na straně kupujícího nutno zboží doručovat opakovaně nebo jiným způsobem, než bylo uvedeno v objednávce, je kupující povinen uhradit náklady spojené s opakovaným doručováním zboží, resp. náklady spojené s jiným způsobem doručení.
                </li>
                <li>
                  Při převzetí zboží od přepravce je kupující povinen zkontrolovat neporušenost obalů zboží a v případě jakýchkoliv závad toto neprodleně oznámit přepravci. V případě shledání porušení obalu svědčícího o neoprávněném vniknutí do zásilky nemusí kupující zásilku od přepravce převzít.
                </li>
                <li>
                  Další práva a povinnosti stran při přepravě zboží mohou upravit zvláštní dodací podmínky prodávajícího, jsou-li prodávajícím vydány.
                </li>
                <li>
                  Přeprava zboží je dle výběru zákazníka uskutečňována přepravci Česká pošta, Zásilkovna nebo DPD kurýr. Při volbě přepravce Česká pošta není možná platba na dobírku. Pokud se vám jeden z uvedených přepravců nenabídne, znamená to, že je váš balíček na poslání přes zvoleného přepravce příliš těžký. Zásilkovna je pouze do 8kg, pošta do 25kg.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>PRÁVA Z VADNÉHO PLNĚNÍ</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Práva a povinnosti smluvních stran ohledně práv z vadného plnění se řídí příslušnými obecně závaznými předpisy (zejména ustanoveními § 1914 až 1925, § 2099 až 2117 a § 2161 až 2174 občanského zákoníku).
                </li>
                <li>
                  Prodávající odpovídá kupujícímu, že zboží při převzetí nemá vady. Zejména prodávající odpovídá kupujícímu, že v době, kdy kupující zboží převzal:
                </li>
                <ol style={{listStyleType: 'lower-alpha'}}>
                  <li>
                    má zboží vlastnosti, které si strany ujednaly, a chybí-li ujednání, má takové vlastnosti, které prodávající nebo výrobce popsal nebo které kupující očekával s ohledem na povahu zboží a na základě reklamy jimi prováděné,
                  </li>
                  <li>
                    se zboží hodí k účelu, který pro jeho použití prodávající uvádí nebo ke kterému se zboží tohoto druhu obvykle používá,
                  </li>
                  <li>
                    zboží odpovídá jakostí nebo provedením smluvenému vzorku nebo předloze, byla-li jakost nebo provedení určeno podle smluveného vzorku nebo předlohy,
                  </li>
                  <li>
                    je zboží v odpovídajícím množství, míře nebo hmotnosti a
                  </li>
                  <li>
                    zboží vyhovuje požadavkům právních předpisů.
                  </li>
                </ol>
                <li>
                  Ustanovení uvedená v čl. 7.2 obchodních podmínek se nepoužijí u zboží prodávaného za nižší cenu na vadu, pro kterou byla nižší cena ujednána, na opotřebení zboží způsobené jeho obvyklým užíváním, u použitého zboží na vadu odpovídající míře používání nebo opotřebení, kterou zboží mělo při převzetí kupujícím, nebo vyplývá-li to z povahy zboží.
                </li>
                <li>
                  Projeví-li se vada v průběhu šesti měsíců od převzetí, má se za to, že zboží bylo vadné již při převzetí.
                </li>
                <li>
                  Práva z vadného plnění uplatňuje kupující u prodávajícího na adrese jeho provozovny, v níž je přijetí reklamace možné s ohledem na sortiment prodávaného zboží, případně i v sídle nebo místě podnikání. Za okamžik uplatnění reklamace se považuje okamžik, kdy prodávající obdržel od kupujícího reklamované zboží.
                </li>
                <li>
                  Další práva a povinnosti stran související s odpovědností prodávajícího za vady může upravit reklamační řád prodávajícího.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>DALŠÍ PRÁVA A POVINNOSTI SMLUVNÍCH STRAN</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Kupující nabývá vlastnictví ke zboží zaplacením celé kupní ceny zboží.
                </li>
                <li>
                  Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy chování ve smyslu ustanovení § 1826 odst. 1 písm. e) občanského zákoníku.
                </li>
                <li>
                  Mimosoudní vyřizování stížností spotřebitelů zajišťuje prodávající prostřednictvím elektronické adresy info@delga.cz. Informaci o vyřízení stížnosti kupujícího zašle prodávající na elektronickou adresu kupujícího.
                </li>
                <li>
                  Prodávající je oprávněn k prodeji zboží na základě živnostenského oprávnění. Živnostenskou kontrolu provádí v rámci své působnosti příslušný živnostenský úřad. Dozor nad oblastí ochrany osobních údajů vykonává Úřad pro ochranu osobních údajů. Česká obchodní inspekce vykonává ve vymezeném rozsahu mimo jiné dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.
                </li>
                <li>
                  Kupující tímto přebírá na sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 občanského zákoníku.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>OCHRANA OSOBNÍCH ÚDAJŮ</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Ochrana osobních údajů kupujícího, který je fyzickou osobou, je poskytována zákonem č. 101/2000 Sb., o ochraně osobních údajů, ve znění pozdějších předpisů.
                </li>
                <li>
                  Kupující souhlasí se zpracováním těchto svých osobních údajů: jméno a příjmení, adresa bydliště, identifikační číslo, daňové identifikační číslo, adresa elektronické pošty, telefonní číslo a emailová adresa (dále společně vše jen jako „<strong>osobní údaje</strong>“).
                </li>
                <li>
                  Kupující souhlasí se zpracováním osobních údajů prodávajícím, a to pro účely realizace práv a povinností z kupní smlouvy a pro účely vedení uživatelského účtu. Nezvolí-li kupující jinou možnost, souhlasí se zpracováním osobních údajů prodávajícím také pro účely zasílání informací a obchodních sdělení kupujícímu.
                </li>
                <li>
                  Kupující bere na vědomí, že je povinen své osobní údaje (při registraci, ve svém uživatelském účtu, při objednávce provedené z webového rozhraní obchodu) uvádět správně a pravdivě a že je povinen bez zbytečného odkladu informovat prodávajícího o změně ve svých osobních údajích. Prodávající, jakožto zpracovatel osobních údajů, je zároveň jejich správcem a získané osobní údaje budou zpracovány bezpečnou formou zamezující jejich zneužití.
                </li>
                <li>
                  Zpracováním osobních údajů kupujícího může prodávající pověřit třetí osobu, jakožto zpracovatele, např. se jedná o přepravní služby.
                </li>
                <li>
                  Osobní údaje budou zpracovávány po dobu neurčitou. Osobní údaje budou zpracovávány v elektronické podobě automatizovaným způsobem nebo v tištěné podobě neautomatizovaným způsobem.
                </li>
                <li>
                  Kupující potvrzuje, že poskytnuté osobní údaje jsou přesné a že byl poučen o tom, že se jedná o dobrovolné poskytnutí osobních údajů.
                </li>
                <li>
                  V případě, že by se kupující domníval, že prodávající nebo zpracovatel (čl. 9.5) provádí zpracování jeho osobních údajů, které je v rozporu s ochranou soukromého a osobního života kupujícího nebo v rozporu se zákonem, zejména jsou-li osobní údaje nepřesné s ohledem na účel jejich zpracování, může:
                  <ol style={{listStyleType: 'lower-alpha'}}>
                  <li>požádat prodávajícího nebo zpracovatele o vysvětlení,</li>
                    <li>požadovat, aby prodávající nebo zpracovatel odstranil takto vzniklý stav.</li>
                </ol>
                </li>
                <li>
                  Požádá-li kupující o informaci o zpracování svých osobních údajů, je mu prodávající povinen tuto informaci předat. Prodávající má právo za poskytnutí informace podle předchozí věty požadovat přiměřenou úhradu nepřevyšující náklady nezbytné na poskytnutí informace.
                </li>
                <li>
                  Kupující vstupem na webové stránky www.delga.cz má možnost vyjádřit svůj souhlas s ukládáním tzv. cookies a pixelových tagů v jeho počítači.
                </li>
              </ol>
            </p>
                <h4 style={subTitle}>ZASÍLÁNÍ OBCHODNÍCH SDĚLENÍ A UKLÁDÁNÍ COOKIES</h4>
                <p style={paragraph}>
                  <ol>
                    <li>
                      Kupující souhlasí se zasíláním informací souvisejících se zbožím, službami nebo podnikem prodávajícího na elektronickou adresu kupujícího a dále souhlasí se zasíláním obchodních sdělení prodávajícím na elektronickou adresu kupujícího.
                    </li>
                    <li>
                      Kupující souhlasí s ukládáním tzv. cookies na jeho počítač. V případě, že je nákup na webové stránce možné provést a závazky prodávajícího z kupní smlouvy plnit, aniž by docházelo k ukládání tzv. cookies na počítač kupujícího, může kupující souhlas podle předchozí věty kdykoliv odvolat.
                    </li>
                  </ol>
                </p>
                <h4 style={subTitle}>DORUČOVÁNÍ</h4>
                <p style={paragraph}><ol>
                  <li>Kupujícímu může být doručováno na adresu elektronické pošty uvedenou v jeho uživatelském účtu či uvedenou kupujícím v objednávce.</li>
                </ol></p>
                <h4 style={subTitle}>ZÁVĚREČNÁ USTANOVENÍ</h4>
                <p style={paragraph}>
                  <ol>
                    <li>
                      Pokud vztah založený kupní smlouvou obsahuje mezinárodní (zahraniční) prvek, pak strany sjednávají, že vztah se řídí českým právem. Tímto nejsou dotčena práva spotřebitele vyplývající z obecně závazných právních předpisů.
                    </li>
                    <li>
                      Je-li některé ustanovení obchodních podmínek neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností jednoho ustanovení není dotknutá platnost ostatních ustanovení. Změny a doplňky kupní smlouvy či obchodních podmínek vyžadují písemnou formu.
                    </li>
                    <li>
                      Kupní smlouva včetně obchodních podmínek je archivována prodávajícím v elektronické podobě a není přístupná.
                    </li>
                    <li>
                      Přílohu obchodních podmínek tvoří vzorový formulář pro odstoupení od kupní smlouvy.
                    </li>
                    <li>
                      Kontaktní údaje prodávajícího: adresa pro doručování DELGA s.r.o., 17. listopadu 237, 530 02 Pardubice, adresa elektronické pošty info@delga.cz, telefon +420 737 824 991.
                    </li>
                  </ol>
                </p>
            <h4 style={subTitle}>Členstvím ve věrnostním programu získáte jako člen nové možnosti při nakupování v internetovém obchodu delga.cz.</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Jako člen věrnostního programu máte nabídku internetového obchodu stejně jako upozornění na vybrané produkty uzpůsobené své osobě a svým preferencím. Nebudete muset stále znova vyplňovat Vaše doručovací údaje. Budou u nás uložené
                </li>
                <li>
                  Abychom Vám mohli tyto služby poskytnout, potřebujeme od Vás nezbytně Vámi poskytnuté osobní údaje, které budeme využívat a zpracovávat za níže uvedených podmínek.
                </li>
                <li>
                  Členstvím ušetřít nejen čas, ale i peníze. Členům jsou nabízeny speciální slevy na vybrané zboží.
                </li>
              </ol>
            </p>
              <h4 style={subTitle}>I. Uzavření smlouvy o členství ve věrnostním programu</h4>
              <p style={paragraph}>
                <ol>
                  <li>
                    Kliknutím na „registrovat“ souhlasíte s těmito podmínkami členství ve věrnostním programu a uzavíráte s námi, tedy se společností DELGA s.r.o.,. se sídlem 17. Listopadu 237, 530 02 Pardubice, IČO: 066 53 090 (dále jen DELGA nebo „my“), smlouvu o členství.
                  </li>
                  <li>
                    Členem může být pouze osoba starší 18 let. Uzavřením smlouvy o členství čestně 	prohlašujete, že jste starší 18 let.
                  </li>
                  <li>
                    Dovolujeme si Vás upozornit, že smlouva o členství je samostatnou smlouvou řídící se zde uvedenými pravidly a není spjatá s existencí jiných (zejména kupních) smluv mezi námi uzavřených.
                  </li>
                </ol>
              </p>
            <h4 style={subTitle}>II. Předmět smlouvy</h4>
            <p style={paragraph}>
              <ol>
                <li>Tímto se zavazujeme:
                <ul>
                  <li>Přednostně Vám zasílat informace o speciálních slevových akcích a limitovaných nabídkách</li>
                <li>
                  Přednostně Vám zasílat informace o nových produktech v naší nabídce, předobjednávkách apod.
                </li>
                  <li>
                    Upozornit Vás na Vaše nedokončené objednávky
                  </li>
                  <li>
                    Přizpůsobit nabídku internetového obchodu a zobrazovanou reklamu Vašim preferencím
                  </li>
                  <li>
                    Bezpečně uchovávat Vaše doručovací či fakturační údaje, abyste nám je nemuseli znovu vyplňovat
                  </li>
                  <li>
                    Umožnit Vám přímý přístup k Vaším údajům, které jste nám poskytli a jejich správu
                  </li>
                </ul></li>
                <li>Abychom mohli uskutečnit uvedené závazky, jsme povinni a oprávněni:
                <ul>
                  <li>zpracovávat veškeré Vaše osobní údaje, které jste nám v souvislosti s uzavřením smlouvy o členství nebo společně nákupy v našem obchodě poskytl, jako je jméno a příjmení, datum narození, adresa bydliště, e-mail, telefonní číslo, pohlaví stejně jako informace o nákupech, o zaplacených částkách, uživatelském chování na stránkách internetového obchodu www.delga.cz nebo mobilní aplikaci a rovněž automaticky generované informace. K těmto informacím patří informace o proklicích, jaké produkty jste si na našich webových stránkách prohlíželi, informace o Vašem počítači a internetové připojení, údaje získané o vašem operačního systému,</li>
                <li>
                  veškeré výše uvedené údaje vyhodnocovat (profilovat) nebo nechat vyhodnotit třetí strany. Uvedené údaje mohou být kromě spřízněných osob eshopu (sesterských společností) zpracovány i našimi zpracovateli.
                </li>
                  <li>kontaktovat vás za účelem statistik a dotazníků</li>
                </ul></li>
                <li>
                  Rozsah výše uvedených povinností jsme oprávněni určit a upravovat.
                </li>
                <li>
                  Jako člen jste povinen nezbytné údaje poskytnout a udržovat je aktuální.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>III. Cena za služby</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Členství je bezplatné stejně jako námi poskytované, výše uvedené služby s tímto členstvím související.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>IV.  Práva z vadného plnění a odstoupení od smlouvy</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Na tento vztah se použijí přiměřeně ustanovení o právech z vadného plnění a o odstoupení od smlouvy spotřebitelem.
                </li>
                <li>
                  Při jejich výkladu a užití je třeba brát v potaz odlišnosti spočívající v plnění z kupní smlouvy a ze smlouvy o členství ve věrnostním programu.
                </li>
                <li>
                  VOP společnosti Delga s.r.o. naleznete zde.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>V. Trvání smlouvy</h4>
            <p style={paragraph}>
              <ol>
              <li>
                Smlouva je uzavřena na dobu neurčitou.
              </li>
                <li>
                  Každá ze stran je oprávněna ji vypovědět i bez důvodu. Výpovědní doba činí 30 dnů od doručení výpovědi druhé ze stran.
                </li>
                <li>
                  Zpracovávané osobní údaje budou smazány nejpozději do 30 dnů od ukončení smlouvy.
                </li>
              </ol>
            </p>
            <h4 style={subTitle}>VI. Závěrečná ustanovení</h4>
            <p style={paragraph}>
              <ol>
                <li>
                  Ohledně ochrany osobních údajů dále odkazujeme na dokument Zásady ochrany osobních údajů
                </li>
                <li>
                  Subjektem mimosoudního řízení v případě sporu, případně subjektem k vyřizování stížností kupujících, je Česká obchodní inspekce, se sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa: http://www.coi.cz, zřízená zákonem č. 64/1986 Sb., o České obchodní inspekci, na kterou se kupující může obrátit přes e-podatelnu na webových stránkách České obchodní inspekce. Dále je možné spor řešit on-line prostřednictvím k tomu určené ADR platformy.
                </li>
                <li>
                  Odchýlení se od těchto obchodních podmínek je možné pouze písemným souhlasem obou stran. Doplňující či odlišné podmínky obchodního partnera se na smluvní vztahy uvedené v těchto obchodních podmínkách neaplikují.
                </li>
                <li>
                  Znění těchto obchodních podmínek může DELGA s.r.o. měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.
                </li>
                <li>
                  Tyto OBCHODNÍ PODMÍNKY DELGA klub vstupují v platnost a účinnost dne 1. 5. 2018.
                </li>
                <li>Obchodní podmínky si můžete stáhnout <a href={TermsFile} download='ObchodniPodminky_DELGA.docx'>zde</a>.</li>
              </ol>
            </p>
          </React.Fragment>
        )
      case TextPageType.PRODUCTS_RETURN:
        return (
          <React.Fragment>
            <h3 style={subTitle}>Dodaní zboží</h3>

            <p style={paragraph}><p style={paragraph}>Dodací lhůty</p>
            Zboží se expedujeme zpravidla během 1-2 dní. Balíky posíláme každý den.</p>
            <p style={paragraph}>
              <p style={paragraph}>Rozbitý či poničený balíček</p>
              Pokud se stane a v balíčku se během přepravy něco rozbije, vysype či jinak poničí, balíček si od přepravce převezměte a pak nás kontaktujte. Poničené zboží vám pošleme znovu. Nebudete mít žádné další starosti navíc.
              Nemusíte nijak složitě řešit reklamaci, tu vyřešíme za vás.
            </p>
            <h3 style={subTitle}>Dodání po České republice</h3>
            <p style={paragraph}>
              <p style={paragraph}>Přepravní společnosti</p>
              DPD - přepravuje balíky do 24 hodin od podání, když vás řidič nezastihne, bude vás kontaktovat a domluvíte se na předání balíčku, můžete se domluvit i na dodání na jinou adresu. Svoz máme každý den. Přepravní službu DPD doporučujeme.
              POŠTA - přepravuje balíky do 24 hodin od podání, ale bohužel to není vždy pravidlo.
              ZASILKOVNA - balíček si můžete nechat poslat na pobočku zásilkovny a tam si ji pak vyzvednout.
            </p>
            <p style={paragraph}>
              <p style={paragraph}>Poštovné</p>
              je účtováno dle typu převzetí zboží takto:<br/>
              <strong>Osobní odběr - zdarma</strong><br/>
              <strong>Objednávka nad 1.000,-&nbsp;Kč - poštovné zdarma</strong><br/>
              <strong>Dopravní službou DPD</strong> (doručení do druhého pracovního dne) - 100&nbsp;Kč
              <ol>
                <li>
                  platba předem
                </li>
                <li>dobírka (doběrečné 20,-&nbsp;Kč)</li>
              </ol>
              <strong>Česká pošta</strong> (doručení do druhého pracovního dne) - 100&nbsp;Kč
              <ol>
                <li>
                  platba předem
                </li>
                <li>
                  dobírka  - není možná
                </li>
              </ol>
              <strong>Výdejní místa Zásilkovna</strong> - 45&nbsp;Kč
              <ol>
                <li>
                  platba předem
                </li>
                <li>platba na prodejně (doběrečné 20,-&nbsp;Kč)</li>
              </ol>
            </p>
            <h3 style={subTitle}>Dodaní zboží</h3>
            <p style={paragraph}>Vrácení zboží</p>
            <p style={paragraph}>Na základě zákona č. 367/2000 Sb. má zákazník právo na odstoupení od kupní smlouvy bez udání důvodu do 14 dnů od převzetí. Zboží nesmí vykazovat známky použití a nesmí být poškozeno.
              Zboží zasílejte na adresu provozovny provozovatele, tedy DELGA s.r.o., Lonkova 510, 530 09 Pardubice.  Nezasílejte prosím vrácené zboží na dobírku, zásilky, které nám zákazníci pošlou na dobírku nebudou převzaty. Do doporučené zásilky vložte zboží, daňový doklad, informaci zda vracíte nebo reklamujete a číslo účtu, na který máme poslat částku za vrácené zboží.
              Vrácené zboží s doklady musí být odesláno zpět (nebo alespoň odstoupení od smlouvy musí být písemně nahlášeno) nejpozději 14. den od dne převzetí. Zboží odeslané na adresu prodávajícího v rámci odstoupení od smlouvy bude překontrolováno a následně bude vyplacena fakturovaná částka na číslo bankovního účtu uvedené zákazníkem v reklamační zásilce.</p>
          <p style={paragraph}><strong>Vrácení peněz lze uplatnit, pokud jsou dodrženy tyto podmínky:</strong>
          <ul>
            <li>Právo na vrácení peněz platí do 14dnů od doručení zboží.</li>
            <li>
              Zboží nesmí být poškozené
            </li>
            <li>
              Zboží musí být zabalené v původním obalu
            </li>
          </ul>
          </p>
          </React.Fragment>
        )
      case TextPageType.FAQ:
        return (
            <React.Fragment>
              <h4 style={subTitle}>Jaké osobní údaje delga.cz shromažďuje a zpracovává?</h4>
              <p style={paragraph}>Jedná se o základní identifikační údaje a kontaktní údaje, tj. jméno, příjmení, bydliště, kontaktní adresa, telefon, e-mail.</p>
              <h4 style={subTitle}>K jakým účelům osobní údaje využíváme a zpracováváme?</h4>
              <p style={paragraph}><b>Delga.cz</b> , zpracovává osobní údaje bez potřeby souhlasu za účelem splnění zákonné povinnosti, z titulu oprávněného zájmu (uskutečnění objednávky). Osobní údaje jsou zpracovány v elektronické podobě pomocí prostředků výpočetní techniky.</p>
              <h4 style={subTitle}>Jak dlouho Delga.cz  údaje zpracovává?</h4>
              <p style={paragraph}>Zpracováváme a uchováváme osobní údaje po dobu, která je nezbytná k naplnění účelu jejich zpracovávání, nebo po dobu, na kterou byl poskytnut souhlas se zpracováváním osobních údajů.</p>
              <h4 style={subTitle}>Z jakých zdrojů Delga.cz osobní údaje získává?</h4>
              <p style={paragraph}>Zpracováváme údaje, jež jste nám poskytli v souvislosti s uskutečněním vaší objednávky, případně k odpovědění dotazu.</p>
              <h4 style={subTitle}>Jakým způsobem Delga.cz zajišťuje ochranu osobních údajů?</h4>
              <p style={paragraph}>Zajišťujeme ochranu dat proti neoprávněnému nebo nahodilému přístupu, proti změně, zničení, ztrátě, neoprávněným přenosům, nebo jinému neoprávněnému zpracování, jakož i k jinému zneužití záznamů, které obsahují osobní údaje.</p>
              <p style={paragraph}>Veškeré osoby, které s osobními údaji členů přichází do styku, jsou vázány povinností mlčenlivosti.</p>
              <h4 style={subTitle}>Komu Delga.cz osobní údaje předává?</h4>
              <p style={paragraph}>Státním orgánům, pokud je tato povinnost stanovena zákonem. Přístup k Vašim osobním údajům dále mohou mít servisní organizaci, které pro nás zajišťují provoz internetového obchodu, účetní a osoby zajišťující bezpečnost našich počítačových systémů.</p>
              <h4 style={subTitle}>Jaká jsou vaše práva ve vztahu ke zpracování a předávání osobních údajů?</h4>
              <p style={paragraph}>V souladu s platnou legislativou můžete uplatnit svá práva subjektů údajů. Máte právo na <b>přístup</b> k osobním údajům, právo na <b>přenositelnost</b> vybraných osobních údajů a právo požadovat <b>opravu</b> osobních údajů.  Můžete požádat o <b>výmaz</b> osobních údajů, popř. <b>omezení jejich zpracování</b>. Tato práva můžete uplatnit písemně na adresu sídla.</p>
            </React.Fragment>
        )
      default:
        return <div></div>
    }
  }

  return (
    content()
  )
};

export default renderContentData;