// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartOrder_CartOrderWrapper__2dYDS{\n    margin: 40px 0 0 0;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    -ms-flex-align: baseline;\n        align-items: baseline;\n}\n.CartOrder_CartOrderTextBox__2UPrT{\n    display: inline-block;\n    margin-top: 1%;\n    width: 80%\n}\n\n\n.CartOrder_Link__3m3-a{\n    text-decoration: none;\n    color: black;\n    text-transform: uppercase;\n    font-weight: bold;\n    font-size: 12px;\n    letter-spacing: 3px;\n    color: #000;\n    font-family:Gotham-Medium;\n    margin-left: 20px;\n    display: inline-block;\n}\n\n.CartOrder_CartOrderWrapperMobile__2_nVr {\n    display: none;\n}\n\n.CartOrder_Price__V1oLe{\n    text-decoration: none;\n    color: black;\n    text-transform: uppercase;\n    font-weight: bold;\n    display: inline-block;\n    font-size: 20px;\n}\n\n.CartOrder_CartTotalPrice__3LmIs {\n    display: inline-block;\n    float: right;\n    margin-right: 1%;\n    margin-top: -.38%;\n    margin-right: 10px;\n    font-weight: bold;\n    text-transform: uppercase;\n    font-size: 12px;\n    letter-spacing: 3px;\n    color: #000;\n    font-family:Gotham-Medium;\n}\n\n\n.CartOrder_Label__1-oLw{\n    text-decoration: none;\n    margin-right: 10px\n}\n\n.CartOrder_Price__V1oLe{\n    font-size:1.5em;\n    text-transform: none;\n}\n\n@media all and (max-width: 800px) {\n    \n    .CartOrder_CartOrderWrapper__2dYDS {\n        display: none;\n    }\n\n    .CartOrder_CartOrderWrapperMobile__2_nVr {\n        display: block;\n        text-align: center;\n        width: 100%;\n        margin-top: 40px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"CartOrderWrapper": "CartOrder_CartOrderWrapper__2dYDS",
	"CartOrderTextBox": "CartOrder_CartOrderTextBox__2UPrT",
	"Link": "CartOrder_Link__3m3-a",
	"CartOrderWrapperMobile": "CartOrder_CartOrderWrapperMobile__2_nVr",
	"Price": "CartOrder_Price__V1oLe",
	"CartTotalPrice": "CartOrder_CartTotalPrice__3LmIs",
	"Label": "CartOrder_Label__1-oLw"
};
module.exports = exports;
