// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal_Modal__1AXg0 {\n    position: fixed;\n    z-index: 500;\n    background-color: #FDF8F5;\n    width: 70%;\n    border: 1px solid #ccc;\n    padding: 16px;\n    left: 15%;\n    top: 35%;\n    box-sizing: border-box;\n    transition: all 0.3s ease-out;\n}\n\n@media (min-width: 600px) {\n    .Modal_Modal__1AXg0 {\n        width: 600px;\n        left: calc(50% - 300px);\n    }\n}\n\n.Modal_Header__C8xQy {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n}\n\n.Modal_CloseIcon__CHUSy {\n    margin: auto 0;\n    width: 17px;\n    height: 17px;\n    cursor: pointer;\n}\n\n.Modal_InnerSection__1TNOk {\n    margin: 14px 20px 20px 20px;\n    line-height: 1.3em;\n}\n\n.Modal_Title__3uLoU {\n    font-size: 15px;\n    letter-spacing: 3px;\n    color: #000000;\n}\n\n.Modal_UnderLine__2Tymz {\n    color: #000000;\n    background-color: #000000;\n    border-width: 0;\n    height: 1px;\n}\n\n.Modal_Description__1UViI {\n    padding-top: 5px;\n    padding-bottom: 25px;\n    font-size: 15px;\n}", ""]);
// Exports
exports.locals = {
	"Modal": "Modal_Modal__1AXg0",
	"Header": "Modal_Header__C8xQy",
	"CloseIcon": "Modal_CloseIcon__CHUSy",
	"InnerSection": "Modal_InnerSection__1TNOk",
	"Title": "Modal_Title__3uLoU",
	"UnderLine": "Modal_UnderLine__2Tymz",
	"Description": "Modal_Description__1UViI"
};
module.exports = exports;
