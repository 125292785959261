import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import classes from './ProductPictureComponent.module.css';
import ArrowImg from "../../../../assets/image/next_grey.svg"

interface Props extends WithTranslation{
    image : string[],
    alt : string,
    transitionTime? : number,
    showStatus? : boolean,
    showThumbs? : boolean,
    showArrows? : boolean
}

class ProductPictureComponent extends React.Component<Props> {

    private slideIndex = 1;

    private showSlides(n) {
        let i;
        let slides = document.getElementsByClassName(classes.MySlides) as HTMLCollectionOf<HTMLElement>;
        let dots = document.getElementsByClassName(classes.Dot) as HTMLCollectionOf<HTMLElement>;
        if (n > slides.length) {
            this.slideIndex = 1
        }
        if (n < 1) {
            this.slideIndex = slides.length
        }
        for (i = 0; i < slides.length; i++) {
            let x = slides[i] as HTMLElement;
            x.style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(classes.Active, classes.Inactive);
        }
        if (slides[this.slideIndex - 1]) {
            let y = slides[this.slideIndex - 1] as HTMLElement;
            y.style.display = "block";
            dots[this.slideIndex - 1].className += " " + classes.Active;
        }
    }

    componentDidMount() {
        this.showSlides(this.slideIndex);
    }

    private plusSlides(n) {
        this.showSlides(this.slideIndex += n);
    }

    private currentSlide(n) {
        this.showSlides(this.slideIndex = n);
    }

    render() {
        return (
            <div className={classes.PictureContainer}>
                <div className={classes.SlideshowContainer}>
                    {this.props.image.map((img, index) => {
                        return (
                            <div className={`${classes.MySlides} ${classes.Fade}`} key={index}>
                                <img src={img} className={classes.Image} alt={this.props.alt}/>
                            </div>
                        )
                    })}
                    <div className={classes.Prev} onClick={() => this.plusSlides(-1)}><img src={ArrowImg} alt="left_arrow" /></div>
                    <div className={classes.Next} onClick={() => this.plusSlides(1)}><img src={ArrowImg} alt="right_arrow" /></div>
                </div>
                <div className={classes.DotPackage}>
                    {this.props.image.map((product, index) => {
                        return <span className={classes.Dot} onClick={() => this.currentSlide(index + 1)} key={index}/>
                    })}
                </div>
        </div>
        );
    }
};

export default withTranslation()(ProductPictureComponent);