// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NewsPictureBox_NewsPictureBox__3jgb7 {\n    width: 50%;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: end;\n        align-items: flex-end;\n    -ms-flex-pack: center;\n        justify-content: center;\n    background-repeat: no-repeat;\n    background-position-x: center;\n    background-position-y: bottom;\n    background-size: contain;\n}\n\n@media all and (max-width: 700px){\n    .NewsPictureBox_NewsPictureBox__3jgb7{\n        display: block;\n        margin-left: auto;\n        margin-right: auto;\n        width: 100%;\n        height: 500px;\n    }\n\n    .NewsPictureBox_NewsPictureBox__3jgb7 img{\n        width: 60%;\n        max-width: 320px;\n        display: block;\n        margin: 0 auto;\n        max-height: unset;\n    }\n}", ""]);
// Exports
exports.locals = {
	"NewsPictureBox": "NewsPictureBox_NewsPictureBox__3jgb7"
};
module.exports = exports;
