import CBDProducts from './CBDProducts';
import anticelProducts from './AnticelProducts';
import kolagenProducts from './KolagenProducts';
import omegaProducts from './OmegaProducts';

CBDProducts[0].recommandedProducts = [omegaProducts[1], CBDProducts[2], CBDProducts[4]];
CBDProducts[1].recommandedProducts = [CBDProducts[2], CBDProducts[0], CBDProducts[3]];
CBDProducts[2].recommandedProducts = [CBDProducts[1], CBDProducts[4], omegaProducts[1]];
CBDProducts[3].recommandedProducts = [kolagenProducts[0], CBDProducts[7], CBDProducts[8]];
CBDProducts[4].recommandedProducts = [CBDProducts[1], CBDProducts[2], CBDProducts[0]];
CBDProducts[5].recommandedProducts = [omegaProducts[2], omegaProducts[1], CBDProducts[6]];
CBDProducts[6].recommandedProducts = [CBDProducts[3] , kolagenProducts[0], CBDProducts[7]];
CBDProducts[7].recommandedProducts = [kolagenProducts[0], kolagenProducts[1], CBDProducts[6], CBDProducts[3]];
CBDProducts[8].recommandedProducts = [omegaProducts[3], CBDProducts[3], CBDProducts[6]];
CBDProducts[9].recommandedProducts = [CBDProducts[6], CBDProducts[1], CBDProducts[2]];

omegaProducts[0].recommandedProducts = [omegaProducts[1], CBDProducts[0], CBDProducts[2], CBDProducts[1]];
omegaProducts[1].recommandedProducts = [omegaProducts[0], CBDProducts[0], CBDProducts[2], CBDProducts[1]];
omegaProducts[2].recommandedProducts = [CBDProducts[5], omegaProducts[1], omegaProducts[3]];
omegaProducts[3].recommandedProducts = [CBDProducts[8], CBDProducts[6],CBDProducts[3]];

kolagenProducts[0].recommandedProducts = [kolagenProducts[1], CBDProducts[6], CBDProducts[3]];
kolagenProducts[1].recommandedProducts = [kolagenProducts[0], CBDProducts[6], CBDProducts[3]];

anticelProducts[0].recommandedProducts = [anticelProducts[1], anticelProducts[2]];
anticelProducts[1].recommandedProducts = [anticelProducts[0], anticelProducts[2]];
anticelProducts[2].recommandedProducts = [anticelProducts[0], anticelProducts[1]];

const RecommendedProducts = [
    omegaProducts,
    kolagenProducts,
    CBDProducts,
    anticelProducts
];

export default RecommendedProducts;