import React, {useState} from "react";
import classes from './SocialMediaComponent.module.css';
// @ts-ignore
import ArrowImg from "../../../../assets/image/next.svg";
import instagramLogo from "../../../../assets/image/instagram_white.svg";

interface InstagramData {
    images: string[],
    timestamps: string[],
    links: string[]
}

interface Props {
  title: string,
  description: string,
  instagramData: InstagramData,
  showInstagramImages: boolean
}

const SocialMedia: React.FunctionComponent<Props> = (props: Props) => {
    let slideIndex = 1;
    showSlides(slideIndex);

// Next/previous controls
    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

// Thumbnail image controls
    function currentSlide(n) {
        showSlides(slideIndex = n);
    }

    function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName(classes.mySlides) as HTMLCollectionOf<HTMLElement>;
        let dots = document.getElementsByClassName(classes.dot) as HTMLCollectionOf<HTMLElement>;
        if (n > slides.length) {
            slideIndex = 1
        }
        if (n < 1) {
            slideIndex = slides.length
        }
        for (i = 0; i < slides.length; i++) {
            let x = slides[i] as HTMLElement;
            x.style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(classes.active, classes.inactive);
        }
        if (slides[slideIndex - 1]) {
            let y = slides[slideIndex - 1] as HTMLElement;
            y.style.display = "block";
            dots[slideIndex - 1].className += " " + classes.active;
        }
    }

    let startX = 0;

    function touchStart(e: React.TouchEvent<HTMLElement>) {
      startX = e.touches[0].clientX;
      e.preventDefault();
    }
    
    function touchEnd(e: React.TouchEvent<HTMLElement>) {
      const change = startX - e.changedTouches[0].clientX;
      const treshold = window.innerWidth*0.8/3;
      if (change > treshold) {
        plusSlides(+1);
      }
      if (change < -treshold) {
        plusSlides(-1);
      }
      e.preventDefault();
    }

    return (
        <div className={classes.SocialMedia}>
            <h1>{props.title}</h1>
            <p className={classes.Description}>{props.description}</p>
            {
                    props.showInstagramImages ?
                <>
                <div className={classes.ImagesContainer}>
                    {props.instagramData.images.map((image, index) => {
                        return (
                            <InstagramImage key={index} image={image} link={props.instagramData.links[index]} timestamp={props.instagramData.timestamps[index]}/>
                        )
                    })}
                </div>
                <div className={classes.slideshowContainer}>
                {props.instagramData.images.map((img, index) => {
                    return (
                        <div key={index} className={`${classes.mySlides} ${classes.fade}`} onTouchStart={e => {touchStart(e)}} onTouchEnd={e => {touchEnd(e)}}>
                            <img src={img} alt="instagramImage" className={classes.Image}/>
                        </div>
                    )
                })}
                <div className={classes.prev} onClick={() => plusSlides(-1)}><img src={ArrowImg} alt="leftArrow" /></div>
                <div className={classes.next} onClick={() => plusSlides(1)}><img src={ArrowImg} alt="rightArrow"/></div>
                </div>
                <br/>
                <div className={classes.dotPackage}>
                {props.instagramData.images.map((product, index) => {
                    return <span key={index} className={classes.dot} onClick={() => currentSlide(index + 1)}/>
                })}
                </div>
                </> : null
            }
        </div>
    )
};

const InstagramImage = (props) => {
    const [hovered, setHover] = useState(false);
    const hoverStyle = hovered ? `flex` : `none`;
    const instagramName = "@delgacosmetics";

    const formatTimestamp = (timestamp: string): string => {
        const date = new Date(timestamp);
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    }

    return (
        <div className={classes.InstagramPictureContainer} onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}>
            <img key={props.index} alt="instagramImage" src={props.image} className={classes.Image}/>
            <a href={props.link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>
                <div className={classes.Instagram} style={{display: hoverStyle}}>
                    <img alt="instagramLogo" src={instagramLogo} className={classes.InstagramLogo}/>
                    <p>{instagramName}</p>
                    <p>{formatTimestamp(props.timestamp)}</p>
                </div>
            </a>
        </div>
    );
}

export default SocialMedia;