import React from "react"
import {Link, useHistory} from "react-router-dom";
import {WithTranslation, withTranslation} from "react-i18next";
import classes from "./CartShippingOrderComponent.module.css"
import Button from "../../../common/button/Button"
import {CartStep} from "../../../../store/Reducer";
import {connect} from "react-redux";
import {ProductActions} from "../../../../templateObjects/product/ProductClass";
import {getDiscountMultiplierByDiscountCode, oneTimeCode} from "../../../../utils/config/config";

interface Props extends WithTranslation {
    productsPrice: number,
    shippingPrice: number,
    paymentPrice: number,
    setPaymentPrice: (paymentPrice: number) => void,
    setShippingPrice: (paymentPrice: number) => void,
    setCurrentCartStep: (currentCartStep: number) => void,
    setPaymentType: (paymentTyp: number) => void
    paymentTypeIndex: number,
    hasDiscount: boolean,
    discountValue: string
}

const PAYMENT_TYPE_DOBIRKA = 7;
const PAYMENT_TYPE_ACCOUNT_TRANSFER = 1;

const CartShippingOrderComponent = (props: Props) => {
    let history = useHistory();

    let handleClick = () => {
        props.setShippingPrice(props.shippingPrice);
        props.setPaymentPrice(props.paymentPrice);
        switch (props.paymentTypeIndex) {
            case 0:
                props.setPaymentType(PAYMENT_TYPE_DOBIRKA);
                break;
            case 1:
                props.setPaymentType(PAYMENT_TYPE_ACCOUNT_TRANSFER);
                break;
        }

        history.push("/cart/5");
        props.setCurrentCartStep(5);
    };

    const getShippingPrice = () => {
        return props.productsPrice > 700 ? 0 : props.shippingPrice;
    };

    const {t} = props;

    return(
        <div className={classes.Wrapper}>
            <span className={classes.BackButtonDesktop}>
                <Link to={`3`}
                      className={classes.Link} onClick={() => props.setCurrentCartStep(3)}>{t('cart.shippingAndPaymentStep.back').toUpperCase()}</Link>
            </span>
            <div className={classes.InfoWrapper}>
                <p className={classes.InfoWrapperLabel}>{t("cart.shippingAndPaymentStep.priceForProducts")}<span
                    className={classes.InfoWrapperPrice}>
                    {
                        props.hasDiscount && oneTimeCode(props.discountValue) === 0 ? (props.productsPrice * getDiscountMultiplierByDiscountCode(props.discountValue)).toFixed(2) : (props.productsPrice - oneTimeCode(props.discountValue)) > 0 ? props.productsPrice - oneTimeCode(props.discountValue) : 0
                    }
                    {` ${t("currency")}`}
                </span></p>
                <p className={classes.InfoWrapperLabel}>{t("cart.shippingAndPaymentStep.shipping")}<span
                    className={classes.InfoWrapperPrice}>{props.productsPrice > 700 ? 0 : props.shippingPrice} {t("currency")}</span></p>
                <p className={classes.InfoWrapperLabel}>{t("cart.shippingAndPaymentStep.payment")}<span
                    className={classes.InfoWrapperPrice}>{props.paymentPrice} {t("currency")}</span></p>
                <p className={classes.InfoWrapperLabel}>{t("cart.shippingAndPaymentStep.all")}<span
                    className={classes.InfoWrapperPrice}>
                             {
                                 props.hasDiscount && oneTimeCode(props.discountValue) === 0 ? (props.productsPrice * getDiscountMultiplierByDiscountCode(props.discountValue) + getShippingPrice() + props.paymentPrice).toFixed(2) : (props.productsPrice  + getShippingPrice() + props.paymentPrice - oneTimeCode(props.discountValue)) > 0 ? props.productsPrice  + getShippingPrice() + props.paymentPrice - oneTimeCode(props.discountValue) : 0
                             }
                    {` ${t("currency")}`}
                </span>
                    {
                        props.hasDiscount && oneTimeCode(props.discountValue) === 0? <div className={classes.Label}>{t("cart.summary.discount", {value: 100 - (getDiscountMultiplierByDiscountCode(props.discountValue) * 100)})}</div> : null
                    }
                    {
                        oneTimeCode(props.discountValue) !== 0 ? <div className={classes.Label}>{t("cart.summary.extraDiscount", {value: oneTimeCode(props.discountValue)})}</div> : null
                    }
                </p>
                <Button text={t("cart.shippingAndPaymentStep.continue").toUpperCase()} isEmailButton={false}
                        clickCallBack={handleClick} customStyle={{marginTop: "8%"}}/>

                <div className={classes.BackToLogin}>
                    <Link to={`3`} className={classes.Link} onClick={() => props.setCurrentCartStep(3)}>{t("cart.shippingAndPaymentStep.back")}</Link>
                </div>
            </div>
        </div>
        )
}

const mapStateToProps = state => {
  return {
      hasDiscount: state.hasDiscount,
      discountValue: state.discountValue
  }
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentCartStep: (currentCartStep: number) => dispatch({type: CartStep.SET_CART_STEP, currentCartStep: currentCartStep}),
        setPaymentType: (paymentType: number) => dispatch({type: ProductActions.SET_PAYMENT_TYPE, paymentType: paymentType})
    }
};

export default  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CartShippingOrderComponent));