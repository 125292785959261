import React from 'react';
import classes from './Footer.module.css';
import instagramLogo from '../../../assets/image/instagram_white.svg';
import facebookLogo from '../../../assets/image/facebook_white.svg';
import EmailInputField from "../../common/emailInputField/EmailInputField";
import {withTranslation, WithTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {RouteConstants} from "../../routes/RouteConstants";

class Footer extends React.Component<WithTranslation> {

  render() {
    const {t} = this.props;

    const linkStyle = {
      color: "white",
      textDecoration: "initial"
    };

    const date = new Date();
    const year = String(date.getFullYear());
    const baseCopyright = t('footer.copyright');
    const copyrightArray = baseCopyright.split(" ");
    copyrightArray.splice(2,1,year);
    const currentCopyrightText = copyrightArray.join(" ");

    return (
      <footer className={`${classes.Footer}`}>
        <div className={classes.InnerSection}>
          <div className={classes.ContactForm}>
            <Link to={RouteConstants.productReturn} style={linkStyle}><p>{t('footer.postal')}</p></Link>
            <Link to={RouteConstants.personalTerms} style={linkStyle}><p>{t('footer.details')}</p></Link>
            <Link to={RouteConstants.terms} style={linkStyle}><p>{t('footer.terms')}</p></Link>
            <Link to={RouteConstants.faq} style={linkStyle}><p>{t('footer.faq')}</p></Link>
            <Link style={linkStyle} to={RouteConstants.contact}>
              <p>{t('footer.contact')}</p></Link>
            <a href={'https://www.facebook.com/kosmetikaspribehem'} rel="noopener noreferrer" target={'_blank'}><img
              alt='facebookLogo'
              className={classes.FacebookLogo}
              src={facebookLogo}/></a>
            <a href={'https://www.instagram.com/delgacosmetics'} rel="noopener noreferrer" target={'_blank'}><img
              alt='instagramLogo'
              className={classes.InstagramLogo}
              src={instagramLogo}/></a>
          </div>
          <div className={classes.NewsletterForm}>
            <h1>{t('footer.newsletter')}</h1>
            <p>{t('footer.description')}</p>
              <div className={classes.EmailInputFieldFooter}>
                  <EmailInputField buttonText={t('footer.submit').toUpperCase()} placeHolder={t('footer.emailField')}/>
              </div>
            <div className={classes.MobileContactForm}>
              <Link to={RouteConstants.productReturn} style={linkStyle}><p>{t('footer.postal')}</p></Link>
              <Link to={RouteConstants.personalTerms} style={linkStyle}><p>{t('footer.details')}</p></Link>
              <Link to={RouteConstants.terms} style={linkStyle}><p>{t('footer.terms')}</p></Link>
              <Link to={RouteConstants.faq} style={linkStyle}><p>{t('footer.faq')}</p></Link>
              <Link to={RouteConstants.contact} style={linkStyle}><p>{t('footer.contact')}</p></Link>
              <a href={'https://www.facebook.com/kosmetikaspribehem'} rel="noopener noreferrer" target={'_blank'}><img
                  alt='facebookLogo'
                  className={classes.FacebookLogo}
                  src={facebookLogo}/></a>
              <a href={'https://www.instagram.com/delgacosmetics'} rel="noopener noreferrer" target={'_blank'}><img
                  alt='instagramLogo'
                  className={classes.InstagramLogo}
                  src={instagramLogo}/></a>
            </div>
            <p className={classes.pStyle}>{currentCopyrightText}</p>
          </div>
        </div>
        <div className={classes.EmailInputFieldTabletWrapper}>
                <EmailInputField buttonText={t('footer.submit').toUpperCase()} placeHolder={t('footer.emailField')}/>
        </div>
      </footer>
    )
  }
}

export default withTranslation()(Footer);