// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Backdrop_Backdrop__y8C7W {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.3);\n    z-index: 100;\n    top: 0;\n    left: 0;\n}\n\n@media screen and (max-width: 500px){\n\n    .Backdrop_Backdrop__y8C7W{\n        background: transparent;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Backdrop": "Backdrop_Backdrop__y8C7W"
};
module.exports = exports;
