import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import classes from './ErrorPage.module.css';

import Button from "../../../common/button/Button";
import {RouteComponentProps} from 'react-router-dom';
import {RouteConstants} from "../../../routes/RouteConstants";
import {connect} from "react-redux";
import {Location, LocationActions} from "../../../../templateObjects/location/Location";


interface Props extends WithTranslation{
  setLocation: (location: Location) => void
}
  


class ErrorPage extends React.Component<Props,RouteComponentProps> {

    componentDidMount(){
        this.props.setLocation(Location.ERROR);
        window.scrollTo(0,0);
    }
    
    componentWillUnmount(){
      this.props.setLocation(Location.COMMON);
    }


    routeChange = (redirectPath: string) => {
        window.location.href = redirectPath
    };

    render() {

        const {t} = this.props;

        return (
                <div className={classes.ErrorPage}>
                    <h1 className={classes.ErrorPageLabel}>{t("error.label")}</h1>
                    <p className={classes.ErrorDescription}>{t("error.description1")}<br />{t("error.description2")}</p>
                    <Button text={t("error.button")} isEmailButton={false} clickCallBack={()=>{this.routeChange(RouteConstants.aboutUs)}}/>
                </div>
        
        );
  }
}

const mapDispatchToProps = dispatch => {
    return {
      setLocation: (location: Location) => dispatch({
        type: LocationActions.SET_LOCATION,
        location: location
      }),
    }
  };
  
  export default withTranslation()(connect(null, mapDispatchToProps)(ErrorPage));
