import React, {createRef, useEffect, useState} from "react";
import classes from "./CartContactDetails.module.css"
import InputFieldCommon, {State} from "../../../common/inputField/InputFieldCommon";
import RadioButtonsFields from "../../../common/radioButton/RadioButtonsFields";
import {Link, useHistory} from "react-router-dom";
import Button from "../../../common/button/Button";
import {withTranslation, WithTranslation} from "react-i18next";
import Divider from "../../../common/divider/Divider";
import {UserDetails} from "../../../../templateObjects/userDetails/UserDetails";
import {connect} from "react-redux";
import {CartStep} from "../../../../store/Reducer";
import {getDiscountMultiplierByDiscountCode, oneTimeCode} from "../../../../utils/config/config";

interface Props extends WithTranslation {
    shippingPrice: number,
    paymentPrice: number,
    totalPriceOfCart: number,
    setUserDetails: (userDetails: UserDetails) => void,
    setCurrentCartStep: (currentCartStep: number) => void,
    userDetails: UserDetails,
    hasDiscount: boolean,
    discountValue: string
}

const CartContactDetails = (props: Props) => {

    let {t} = props;

    const initName = props.userDetails?.contact?.name ? props.userDetails.contact.name : "";
    const initSurname = props.userDetails?.contact?.surname ? props.userDetails.contact.surname : "";
    const initEmail = props.userDetails?.contact?.email ? props.userDetails.contact.email : "";
    const initPhone = props.userDetails?.contact?.phone ? props.userDetails.contact.phone : undefined;

    const initAddress = props.userDetails?.address?.address ? props.userDetails.address.address : "";
    const initCity = props.userDetails?.address?.city ? props.userDetails.address.city : "";
    const initZip = props.userDetails?.address?.zip ? props.userDetails.address.zip : undefined;
    const initCountry = props.userDetails?.address?.country ? props.userDetails.address.country : "";
    const initCompany = props.userDetails?.address?.company ? props.userDetails.address.company : "";
    const initNote = props.userDetails?.address?.note ? props.userDetails.address.note : "";

    const initCheckboxCompany = props.userDetails?.companyDetails?.firm && props.userDetails.companyDetails.firm !== "" ? true : false;
    const initCheckboxSecAddress = props.userDetails?.secondaryAddress?.address && props.userDetails.secondaryAddress.address !== "" ? true : false;
    const initCheckboxAgreementWithTerms = props.userDetails?.address?.address && props.userDetails.address.address !== "" ? true : false;
    const initCheckboxNewsletter = props.userDetails?.newletterAllowed === false ? false : true;

    const initFirm = props.userDetails?.companyDetails?.firm ? props.userDetails.companyDetails.firm : "";
    const initIco = props.userDetails?.companyDetails?.ico ? props.userDetails.companyDetails.ico : undefined;
    const initDic = props.userDetails?.companyDetails?.dic ? props.userDetails.companyDetails.dic : "";

    const initAddressSecondary = props.userDetails?.secondaryAddress?.address ? props.userDetails.secondaryAddress.address : "";
    const initCitySecondary = props.userDetails?.secondaryAddress?.city ? props.userDetails.secondaryAddress.city : "";
    const initZipSecondary = props.userDetails?.secondaryAddress?.zip ? props.userDetails.secondaryAddress.zip : undefined;
    const initCountrySecondary = props.userDetails?.secondaryAddress?.country ? props.userDetails.secondaryAddress.country : "";
    const initCompanySecondary = props.userDetails?.secondaryAddress?.company ? props.userDetails.secondaryAddress.company : "";
    const initNoteSecondary = props.userDetails?.secondaryAddress?.note ? props.userDetails.secondaryAddress.note : "";

    

    let [fields, setFields] = useState<UserDetails>({
        contact: {name: initName, surname: initSurname, email: initEmail, phone: initPhone},
        address: {address: initAddress, city: initCity, zip: initZip, country: initCountry, company: initCompany, note: initNote},
        companyDetails: {firm: initFirm, ico: initIco, dic: initDic},
        secondaryContact: {name: "", surname: "", email: "", phone: undefined},
        secondaryAddress: {address: initAddressSecondary, city: initCitySecondary, zip: initZipSecondary, country: initCountrySecondary, company: initCompanySecondary, note: initNoteSecondary},
        newletterAllowed: true
    });

    let history = useHistory();
    let [visibleWarning,setVisibleWarming] = useState(false);
    let [visibleWarningCheck,setVisibleWarmingCheck] = useState(false);

    // checkboxes
    let declarationCheckBoxRef = createRef<HTMLInputElement>();
    let newsletterDeclarationCheckBoxRef = createRef<HTMLInputElement>();
    let companyCheckBoxRef = createRef<HTMLInputElement>();
    let secondaryAddressCheckboxRef = createRef<HTMLInputElement>();

    // mandatoryFieldsRefs
    let firstNameRef = createRef<InputFieldCommon>();
    let surNameRef = createRef<InputFieldCommon>();
    let emailRef = createRef<InputFieldCommon>();
    let phoneRef = createRef<InputFieldCommon>();

    let addressRef = createRef<InputFieldCommon>();
    let cityRef = createRef<InputFieldCommon>();
    let zipRef = createRef<InputFieldCommon>();
    let countryRef = createRef<InputFieldCommon>();
    let companyRef = createRef<InputFieldCommon>();
    let noteRef = createRef<InputFieldCommon>();
    let mandatoryRefs = [firstNameRef, surNameRef, emailRef, phoneRef, addressRef, cityRef, zipRef, countryRef,companyRef,noteRef];

    // companyFieldsRefs
    let companyName = createRef<InputFieldCommon>();
    let ico = createRef<InputFieldCommon>();
    let dic = createRef<InputFieldCommon>();
    let companyRefs = [companyName, ico, dic];

    // SecondaryAddressFieldsRefs
    /* let sFirstNameRef = createRef<InputFieldCommon>();
    let sSurNameRef = createRef<InputFieldCommon>();
    let sEmailRef = createRef<InputFieldCommon>();
    let sPhoneRef = createRef<InputFieldCommon>(); */
    let sAddressRef = createRef<InputFieldCommon>();
    let sCityRef = createRef<InputFieldCommon>();
    let sZipRef = createRef<InputFieldCommon>();
    let sCountryRef = createRef<InputFieldCommon>();
    let sCompanyRef = createRef<InputFieldCommon>();
    let sNoteRef = createRef<InputFieldCommon>();
    let sMandatoryRefs = [/*sFirstNameRef, sSurNameRef, sEmailRef, sPhoneRef,*/ sAddressRef, sCityRef, sZipRef, sCountryRef,sCompanyRef,sNoteRef];

    const yup = require('yup');
    const MAX_NAME = 128;
    const MIN_NAME = 1;

    const PERSONAL_FIELDS = [
        "name",
        "surname",
        "email",
        "phone"
    ];

    const DELIVERY_FIELDS = [
        "address",
        "city",
        "zip",
        "country",
        "company",
        "note"
    ]

    const COMPANY_FIELDS = [
        "firm",
        "ico",
        "dic"
    ]

    const NUMBER_FIELDS = [
        "zip",
        "ico"
    ]

    const VALIDATIONS = {
        name: yup.string().required().max(MAX_NAME).min(MIN_NAME),
        surname: yup.string().required().min(MIN_NAME),
        email: yup.string().email().required(),
        phone: yup.string().matches("^[0-9]{3} [0-9]{3} [0-9]{3}$"),
        company: yup.string().notRequired(),
        note: yup.string().notRequired().max(MAX_NAME),
        address: yup.string().required().min(MIN_NAME),
        city: yup.string().required().max(MAX_NAME).min(MIN_NAME),
        zip: yup.number().required().positive(),
        country: yup.string().required().max(MAX_NAME).min(MIN_NAME),
        firm: yup.string().required().min(MIN_NAME),
        ico: yup.number().required().moreThan(9999999).lessThan(100000000),
        dic: yup.string().matches("^CZ[0-9]{8,10}$")
    };

    const handleClick = () => {
            if (declarationCheckBoxRef!.current!.checked) {
                setVisibleWarmingCheck(false);
                var abort: boolean = false;
                var abortCompany: boolean = false;
                var abortSecondary: boolean = false;
                
                mandatoryRefs.slice(0,mandatoryRefs.length-2).forEach(ref => {
                    if (ref!.current!.validationState !== State.SUCCESS) {
                        //mandatory not fills - abort
                        abort = true;
                    };
                })

                if (companyCheckBoxRef!.current!.checked) {
                    companyRefs.forEach(ref => {
                        if (ref!.current!.validationState !== State.SUCCESS) {
                            abort = true;
                        };
                    })
                }else{
                    abortCompany = true;
                }

                if (secondaryAddressCheckboxRef!.current!.checked) {
                    sMandatoryRefs.slice(0,sMandatoryRefs.length-2).forEach(ref => {
                        if (ref!.current!.validationState !== State.SUCCESS) {
                            abort = true;
                        }
                    })
                    
                }else{
                    abortSecondary = true;
                }

                if(!abort){
                    if(abortCompany){
                        delete fields.companyDetails;
                    }
                    if(abortSecondary){
                        delete fields.secondaryAddress;
                        delete fields.secondaryContact;
                    }

                    if (newsletterDeclarationCheckBoxRef!.current!.checked) {
                        fields.newletterAllowed = true;
                    }else{
                        fields.newletterAllowed = false;
                    }

                    props.setUserDetails(fields);
                    history.push("/cart/4");
                    props.setCurrentCartStep(4);
                }else{
                    setVisibleWarming(true);
                }
            } else {
                setVisibleWarmingCheck(true);
            }
    };

    useEffect(() =>     {
        window.scrollTo(0,0)

        // when company checkbox is unchecked, company fields should be empty
        companyCheckBoxRef!.current!.addEventListener("change", () => {
            if (companyCheckBoxRef.current && !companyCheckBoxRef.current.checked) {
                // using force update because validationState is not proper state like it should be
                companyRefs.forEach(ref => {
                    ref!.current!.validationState = State.EMPTY;
                    ref!.current!.forceUpdate()
                })
            }
        })
        // when newAddress checkbox is unchecked, secondAddress fields should be empty
        secondaryAddressCheckboxRef!.current!.addEventListener("change", () => {
            if (secondaryAddressCheckboxRef.current && !secondaryAddressCheckboxRef.current.checked) {
                // using force update because validationState is not proper state like it should be
                sMandatoryRefs.forEach(ref => {
                    ref!.current!.validationState = State.EMPTY;
                    ref!.current!.forceUpdate()
                })
            }
        })

        let checkBoxes = document.getElementsByClassName(classes.CheckBoxTrigger);
        for (let i = 0; i < checkBoxes.length; i++) {
            checkBoxes[i].children[0].children[0].children[0].children[1].addEventListener("change", () => {
                let divControledByCheckBox = checkBoxes[i].nextElementSibling as HTMLElement;
                if (divControledByCheckBox.style.display !== "block") {
                    divControledByCheckBox.style.display = "block";
                } else {
                    divControledByCheckBox.style.display = "none";
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setDetails = (object: string, field: string, value: string | number) => {
        setFields({...fields, [object]: {...fields[object], [field]: value}})
    }

   const  countries = [
  { value: 'Česká republika', label: 'Česká republika' },
  { value: 'Slovenská republika', label: 'Slovenská republika' }
]

    return (
        <>
            <div className={classes.ContactDetail}>
                <p className={classes.heading}>{t("cart.contactDetails.contactDetails")}</p>
                <div className={classes.InputFieldsGrid}>
                    {PERSONAL_FIELDS.map((field, index) => {
                            return (
                                <div key={index}><InputFieldCommon ref={mandatoryRefs[index]} labelSize={13} placeHolder={""}
                                                       label={t(`cart.contactDetails.${field}`)}
                                                       onChange={e => setDetails("contact", field, e.target.value)}
                                                       height={52} width={262} bgcolor={"#FFF"}
                                                       validate={VALIDATIONS[field]} notEmpty={t("errorEmpty")}
                                                       type={(NUMBER_FIELDS.includes(field)?"number":"text")}
                                                       initialValue={fields.contact[field]}
                                                       name={field} isMandatory={true}/></div>
                            )
                    })}
                </div>
                <p className={classes.heading}>{t("cart.contactDetails.shippingDetails")}</p>
                <div className={classes.InputFieldsGrid}>
                    {DELIVERY_FIELDS.map((field, index) => {
                       return DELIVERY_FIELDS[index] === "country" ? 
                         (
                                <div key={index}><InputFieldCommon countries={countries} ref={mandatoryRefs[index + 4]} 
                              labelSize={13} placeHolder={""}
                                                       label={t(`cart.contactDetails.${field}`)}
                                                       name={field}
                                                       width="100%"
                                                       // @ts-ignore
                                                       onChange={e => setDetails("address", field, e.value)}
                                                       height={52} bgcolor={"#fff"}
                                                       validate={VALIDATIONS[field]} notEmpty={t("errorEmpty")}
                                                       initialValue={fields.address[field]}
                                                       type={(NUMBER_FIELDS.includes(field)?"number":"text")} isMandatory={index <= 3 ? true:false}/></div>
                            ) : 
                          (
                                <div key={index}><InputFieldCommon ref={mandatoryRefs[index + 4]} labelSize={13} placeHolder={""}
                                                       label={t(`cart.contactDetails.${field}`)}
                                                       onChange={e => setDetails("address", field, e.target.value)}
                                                       height={52} width={262} bgcolor={"#fff"}
                                                       validate={VALIDATIONS[field]} notEmpty={t("errorEmpty")}
                                                       initialValue={fields.address[field]}
                                                       type={(NUMBER_FIELDS.includes(field)?"number":"text")} isMandatory={index <= 3 ? true:false}/></div>
                            )  
                            
                    })}
                    <div className={classes.MandatoryFields}>*{t("cart.contactDetails.mandatoryFields")}</div>
                </div>

                <div className={classes.CheckBoxTrigger}>
                    <form>
                        <RadioButtonsFields ref={companyCheckBoxRef} checkbox={true} checkedByDefault={initCheckboxCompany}
                                            texts={[t("cart.contactDetails.firmPurchase")]}
                                            name={"firmPurschase"}/>
                    </form>
                </div>
                <div style={initCheckboxCompany?{display: "block"}:{display: "none"}}>
                    <p className={classes.heading}>{t("cart.contactDetails.billingDetails")}</p>
                    <div className={classes.InputFieldsGrid}>
                        {COMPANY_FIELDS.map((field, index) => {
                                return (<div key={index}><InputFieldCommon ref={companyRefs[index]} labelSize={13} placeHolder={""}
                                                               label={t(`cart.contactDetails.${field}`)}
                                                               onChange={e => setDetails("companyDetails", field, e.target.value)}
                                                               height={52} width={262} bgcolor={"#FFF"}
                                                               validate={VALIDATIONS[field]}
                                                               notEmpty={t("errorEmpty")}
                                                               initialValue={fields.companyDetails? fields.companyDetails[field]: undefined}
                                                               type={(NUMBER_FIELDS.includes(field)?"number":"text")} isMandatory={true}/></div>)
                        })}
                    </div>
                </div>


                <div className={classes.CheckBoxTrigger}>
                    <form>
                        <RadioButtonsFields ref={secondaryAddressCheckboxRef} checkbox={true} checkedByDefault={initCheckboxSecAddress}
                                            texts={[t("cart.contactDetails.anotherAddressDelivery")]}
                                            name={"fsdf"}/>
                    </form>
                </div>
                <div style={initCheckboxSecAddress?{display: "block"}:{display: "none"}}>
                    {/* <p className={classes.heading}>{t("cart.contactDetails.newContactDetails")}</p>
                    <div className={classes.InputFieldsGrid}>
                        {PERSONAL_FIELDS.map((field, index) => {
                                return (
                                    <div><InputFieldCommon ref={sMandatoryRefs[index]} labelSize={13} placeHolder={""}
                                                           label={t(`cart.contactDetails.${field}`)}
                                                           onChange={e => setDetails("secondaryContact", field, e.target.value)}
                                                           height={52} width={262} bgcolor={"#FFF"}
                                                           validate={VALIDATIONS[field]} notEmpty={t("errorEmpty")}
                                                           type={(NUMBER_FIELDS.includes(field)?"number":"text")}
                                                           name={field}/></div>
                                )                      
                        })}
                    </div> */}
                    <p className={classes.heading}>{t("cart.contactDetails.newDeliveryDetails")}</p>
                    <div className={classes.InputFieldsGrid}>
                        {DELIVERY_FIELDS.map((field, index) => {
                       return DELIVERY_FIELDS[index] === "country" ? 
                         (
                                <div key={index}><InputFieldCommon countries={countries} ref={sMandatoryRefs[index]} labelSize={13}
                                                           name={field}
                                                           placeHolder={""}
                                                           label={t(`cart.contactDetails.${field}`)}
                                                           // @ts-ignore
                                                           onChange={e => setDetails("secondaryAddress", field, e.value)}
                                                           height={52} width="100%" bgcolor={"#FFF"}
                                                           validate={VALIDATIONS[field]} notEmpty={t("errorEmpty")}
                                                           initialValue={fields.secondaryAddress? fields.secondaryAddress[field]: undefined}
                                                           type={(NUMBER_FIELDS.includes(field)?"number":"text")} isMandatory={index <= 3 ? true:false}/></div>
                            ) : 
                          (
                                <div key={index}><InputFieldCommon ref={sMandatoryRefs[index]} 
                                                           labelSize={13}
                                                           placeHolder={""}
                                                           label={t(`cart.contactDetails.${field}`)}
                                                           onChange={e => setDetails("secondaryAddress", field, e.target.value)}
                                                           height={52} width={262} bgcolor={"#FFF"}
                                                           validate={VALIDATIONS[field]} notEmpty={t("errorEmpty")}
                                                           initialValue={fields.secondaryAddress? fields.secondaryAddress[field]: undefined}
                                                           type={(NUMBER_FIELDS.includes(field)?"number":"text")} isMandatory={index <= 3 ? true:false}/></div>
                            )  
                            
                    }
                    
                    )}
                        <div className={classes.MandatoryFields}>*{t("cart.contactDetails.mandatoryFields")}</div>
                    </div>
                </div>


                <div className={classes.DeclarationCheckbox}>
                    <form>
                        <RadioButtonsFields ref={declarationCheckBoxRef} checkbox={true} checkedByDefault={initCheckboxAgreementWithTerms}
                                            texts={[t("cart.contactDetails.declaration")]}
                                            name={"fsdf"}/>
                    </form>
                </div>
                <div className={classes.DeclarationCheckbox}>
                    <form>
                        <RadioButtonsFields ref={newsletterDeclarationCheckBoxRef} checkbox={true} checkedByDefault={initCheckboxNewsletter}
                                            texts={[t("cart.contactDetails.newsletterDeclaration")]}
                                            name={"fsdf"}/>
                    </form>
                </div>
                <div className={(visibleWarning)?classes.Warning:classes.NoWarning}>{t("cart.contactDetails.abortMessage")}</div>
                <div className={(visibleWarningCheck)?classes.Warning:classes.NoWarning}>{t("cart.contactDetails.checkBoxMessage")}</div>
                <Divider type={"ShippingDivider"}/>
                <div className={classes.Wrapper}>
                    <span className={classes.BackButtonDesktop}>
                        <Link to={`1`} className={classes.Link} onClick={() => props.setCurrentCartStep(1)}>{t('cart.contactDetails.back').toUpperCase()}</Link>
                    </span>
                    <div className={classes.InfoWrapper}>
                        <p className={classes.InfoWrapperLabel}>{t("cart.shippingAndPaymentStep.priceForProducts")}<span
                            className={classes.InfoWrapperPrice}>
                            {
                                props.hasDiscount && oneTimeCode(props.discountValue) === 0 ? (props.totalPriceOfCart * getDiscountMultiplierByDiscountCode(props.discountValue)).toFixed(2) : (props.totalPriceOfCart - oneTimeCode(props.discountValue)) > 0 ? props.totalPriceOfCart - oneTimeCode(props.discountValue) : 0
                            }
                            {` ${t("currency")}` }
                        </span></p>
                        <p className={classes.InfoWrapperLabel}>{t("cart.order.all")}<span
                            className={classes.InfoWrapperPrice}>
                            {
                                props.hasDiscount && oneTimeCode(props.discountValue) === 0 ? (props.totalPriceOfCart * getDiscountMultiplierByDiscountCode(props.discountValue)).toFixed(2) : (props.totalPriceOfCart - oneTimeCode(props.discountValue)) > 0 ? props.totalPriceOfCart - oneTimeCode(props.discountValue) : 0
                            }
                            {` ${t("currency")}`}
                        </span>
                            {
                                props.hasDiscount && oneTimeCode(props.discountValue) === 0? <div className={classes.Label}>{t("cart.summary.discount", {value: 100 - (getDiscountMultiplierByDiscountCode(props.discountValue) * 100)})}</div> : null
                            }
                            {
                                oneTimeCode(props.discountValue) !== 0 ? <div className={classes.Label}>{t("cart.summary.extraDiscount", {value: oneTimeCode(props.discountValue)})}</div> : null
                            }
                        </p>
                        <Button text={t("cart.shippingAndPaymentStep.continue").toUpperCase()} isEmailButton={false}
                                clickCallBack={() => handleClick()} customStyle={{marginTop: "8%"}}/>
                        <div className={classes.BackToLogin}>
                            <Link to={`1`} className={classes.Link} onClick={() => props.setCurrentCartStep(1)}>{t("cart.contactDetails.back")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        hasDiscount: state.hasDiscount,
        discountValue: state.discountValue
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentCartStep: (currentCartStep: number) => dispatch({type: CartStep.SET_CART_STEP, currentCartStep: currentCartStep})
    }
};

export default  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CartContactDetails));