export const returnCzechDateFormat = (date: string): string => {
  const jsDateFormat = new Date(date);
  const day = jsDateFormat.getDate();
  const month = jsDateFormat.getMonth() + 1;
  const year = jsDateFormat.getFullYear();
  return `${day}.${month}.${year}`;
};

export const returnTodayCzechDateFormat = (): string => {
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  return `${day}.${month}.${year}`;
};

export const returnDateFormatFromCzechDate = (date: string): Date => {
  return new Date(date.split(".").reverse().join(","));
};
