import i18n from "i18next";
import detector from 'i18next-browser-languagedetector';

import translationCs from '../../locales/cs/translation.json';
import translationEn from '../../locales/en/translation.json';
import translationEs from '../../locales/es/translation.json';
import translationDe from '../../locales/de/translation.json';
import translationRu from '../../locales/ru/translation.json';
import {CS, LOCAL_STORAGE_LANG} from "./LanguageConstants";


const localStorageLang = (): string => {
  const lng = localStorage.getItem(LOCAL_STORAGE_LANG);
  return lng ? lng : CS;
};

// the translations
const resources = {
  cs: {
    translation: translationCs
  },
  en: {
    translation: translationEn
  },
  es: {
    translation: translationEs
  },
  de: {
    translation: translationDe
  },
  ru: {
    translation: translationRu
  }
};

i18n
  .use(detector)
  .init({
    lng: localStorageLang(),
    fallbackLng: 'cs',
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;