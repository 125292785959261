import React, {useRef, useState, useEffect} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import './ComboBox.scss';

interface Props extends WithTranslation{
  id: string,
  optionsValue: string[],
  optionsName: string[],
  onSelect: (value: string) => void,
  placeHolder?:string,
  placeHolderMaxRes?:number,
  initIndex?: number
}

type initial = {
  value: string,
  name: string
}

const ComboBox: React.FunctionComponent<Props> = (props: Props) => {

  const [clicked, setClicked] = useState<boolean>(false);
  const [toggle, setToggle] = useState<string>('');
  const [initValue, setInit] = useState<initial>({
    value: props.optionsValue[props.initIndex? props.initIndex: 0],
    name: props.optionsName[props.initIndex? props.initIndex: 0]
  });
  const divElement = useRef<HTMLDivElement>(null);

  const handleClickOnSelect = (): void => {
    divElement.current!.classList.toggle("hideOptions");

    return toggle === '' ? setToggle('open') : setToggle('');
  };

  const handleClickOnItem = (value: string, name: string) => {
    setClicked(true);
    setInit({value: value, name: name});
    props.onSelect(value);
  };

  useEffect(() => {
    if(initValue.value === props.optionsValue[0]){
      setInit({...initValue, name: props.optionsName[0]})
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.optionsName]); 

  const getValue = ()=>{
    if(!clicked && props.placeHolder && props.placeHolderMaxRes && window.innerWidth <= props.placeHolderMaxRes)
    {
      return props.placeHolder;
    }
    return initValue.name;
  };

  return (
    <div className={`custom-select-wrapper ${toggle}`} onClick={handleClickOnSelect} onBlur={handleClickOnSelect}>
      <div className={`custom-select ${toggle}`}>
        <div className="custom-select__trigger" data-value={initValue.value}>
          <span className="value">{getValue()}</span>
          <div className={`arrow ${toggle}`}></div>
        </div>
        <div className="custom-options hideOptions" ref={divElement}>
          {
            props.optionsValue.map((option, index) => {
              if (initValue.value === option) {
                return <span key={index} className="custom-option selected" data-value={option}
                             onClick={() => handleClickOnItem(option, props.optionsName[index])}><strong>{props.optionsName[index]}</strong></span>
              }
              return <span key={index} className="custom-option selected" data-value={option}
                           onClick={() => handleClickOnItem(option, props.optionsName[index])}>{props.optionsName[index]}</span>
            })
          }
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ComboBox);