import React from 'react';
import style from "./CartSlider.module.css";
import {withTranslation, WithTranslation} from "react-i18next";
import CartHeader from "../header/cartHeader/CartHeader";
import ProductClass, {ChangeAmountEnum, ProductActions} from "../../../templateObjects/product/ProductClass";
import Spinner from "../../common/spinner/Spinner";
import {connect} from "react-redux";
import closeIcon from "../../../assets/image/close.png";
import grayCloseIcon from "../../../assets/image/grey-close.png";
import Button from "../../common/button/Button";
import {Link} from 'react-router-dom';
import ArrowImg from "../../../assets/image/next_grey.svg";
import OmegaProducts from '../../../data/products/OmegaProducts';
import {CartStep} from "../../../store/Reducer";

interface Props extends WithTranslation {
  show: boolean,
  click: () => void,

  cartItems?: Array<ProductClass>,
  totalPriceOfCart?: number,
  onIncrementOfAmount?: (product: ProductClass) => void,
  onDecrementOfAmount?: (product: ProductClass) => void,
  removeProduct?: (product: ProductClass) => void,
  addProduct?: (product: ProductClass) => void,
  setCurrentCartStep: (currentCartStep: number) => void
}

interface State{
  isCzechLang:boolean
}

class CartSlider extends React.Component<Props,State> {

  checkLang(){
    return ["en","es"].includes(this.props.i18n.language) ? false : true
  }

  constructor(props) {
    super(props);
    this.state = {isCzechLang: this.checkLang()};
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousState.isCzechLang !== this.checkLang()) {
        this.setState({isCzechLang: this.checkLang()});
    }
}

  _renderCarts = (): React.ReactNode => {
    const {t} = this.props;
    return this.props.cartItems && this.props.cartItems.map((cart: ProductClass, index: number) => {
      return (
        <div className={style.SliderCartSelectedContainer} key={index}>
          <img className={style.SliderCartImage} src={cart.cartIcon} alt='cartIcon'/>
          <div className={style.SliderCartDetailsContainer}>
            <div className={style.CartDetailsNameCloseContainer}>
              <div className={style.SliderCartDetailsItemName}>{t(cart.name)}</div>
              <div onClick={() => this.props.removeProduct && this.props.removeProduct(cart)}
                   className={style.SliderCartDetailsCloseIconDiv}><img
                className={style.SliderCartDetailsCloseIconImage} src={grayCloseIcon} alt='close'/></div>
            </div>
            <div className={style.SliderCartCounterContainer}>
              <Spinner isCartSlider={true} initial={cart.amount} onPlus={() => {
                this.props.onIncrementOfAmount && this.props.onIncrementOfAmount(cart)
              }} onMinus={
                  () => this.props.onDecrementOfAmount && this.props.onDecrementOfAmount(cart)
              } onRemove={
                  () => this.props.removeProduct && this.props.removeProduct(cart)
              }/>
              <div className={style.SliderCartPrice}>
                {cart.discount &&
                <div className={style.SliderCartOldPrice}> {cart.discount * cart.amount} {t('currency')}</div>}
                <div>{cart.totalPriceByAmount} {t('currency')}</div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  _renderFooter = (): React.ReactNode => {
    const {t} = this.props;

    const styleCartButton = {
      'width': '100%'
    };

    const didYouForgetProducts = [
      OmegaProducts[0],
      OmegaProducts[1],
      OmegaProducts[2]
    ];
    const styleAddProductButton = {
      'fontSize': '10px',
      'height': '30px',
      'width': '100px',
      'backgroundColor': 'rgb(217, 217, 217)',
      'color': 'black'
    }

    let slideIndex = 1;
    showSlides(slideIndex);

  // Next/previous controls
    function plusSlides(n) {
      showSlides(slideIndex += n);
    }

    function showSlides(n) {
      let i;
      let slides = document.getElementsByClassName(style.MySlides) as HTMLCollectionOf<HTMLElement>;
      if (n > slides.length) {slideIndex = 1}
      if (n < 1) {slideIndex = slides.length}
      for (i = 0;i < slides.length; i++) {
        let x = slides[i] as HTMLElement;
        x.style.display = "none";
      }
      if(slides[slideIndex - 1]){
        let y = slides[slideIndex-1] as HTMLElement;
        y.style.display = "flex";
      }
    }

    const addProductInCarousel = (product: ProductClass) => {
      const productCopy = {...product}
      productCopy.recommandedProducts = undefined;
      productCopy.amount = 1;
      this.props.addProduct && this.props.addProduct(productCopy);
    }

    return (
      <div className={style.CartSliderFooter}>
        <Link to={'/cart/1'} onClick={() => this.props.setCurrentCartStep(1)}>
          <Button text={((this.props.cartItems && this.props.cartItems.length !== 0) ?
          t('cartSlider.gotocart') + ' / ' + this.props.totalPriceOfCart + t('currency')
          : t('cartSlider.gotocart')).toUpperCase()} isEmailButton={false} customStyle={styleCartButton} clickCallBack={() => {this.props.click()}}></Button>
        </Link>

        <div className={style.DidYouForget}>{t('cartSlider.didyouforget')}</div>

          <div className={style.SlideshowContainer}>
            {didYouForgetProducts.map((product: ProductClass, index: number) => {
              return (
                      <div className={`${style.MySlides} ${style.Fade}`} key={index}>
                          <img src={product.picture[0]} alt="productPicture"/>
                          <div>{t(product.name)}
                                {this.state.isCzechLang ? <div>
                                  {product.discount && <div className={style.ProductContainerDiscount}>{product.discount}&nbsp;{t("currency")}</div>}
                                  <div className={style.ProductContainerPrice}>{product.price}&nbsp;{t("currency")}</div>
                                </div>: null}
                          </div>
                          {this.state.isCzechLang ? <Button text={t("cartSlider.addbutton").toUpperCase()} isEmailButton={false} customStyle={styleAddProductButton}
                              clickCallBack={() => addProductInCarousel(product) }/> : null}
                      </div>
              )
            })}
            <div className={style.Prev} onClick={() => plusSlides(-1)}><img src={ArrowImg} alt="leftArrow" /></div>
            <div className={style.Next} onClick={() => plusSlides(1)}><img src={ArrowImg} alt="rightArrow" /></div>
          </div>
      </div>
    )
  }

  render() {
    const {t} = this.props;
    return (
      <div className={!this.props.show ? style.SlideDrawer : style.SlideDrawer + ' ' + style.SlideDrawerOpen}>
        <div className={style.SlideDrawerContainer}>
          <div className={style.SlideDrawerClose}>
            <img className={style.SlideDrawerCloseIcon} src={closeIcon} onClick={this.props.click} alt=""/>
            <div className={style.CartLabel}>{t('cartSlider.cart').toUpperCase()} </div>
            <CartHeader sliderAlignment={true} hidePrice={true}/>
          </div>

          <div className={style.ProductView}>
            {this.props.cartItems && this.props.cartItems.length !== 0 && this._renderCarts()}
          </div>
          {this._renderFooter()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartItems,
    totalPriceOfCart: state.totalPriceOfCart
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onIncrementOfAmount: (product: ProductClass) => dispatch({type: ChangeAmountEnum.INCREMENT, product: product}),
    onDecrementOfAmount: (product: ProductClass) => dispatch({type: ChangeAmountEnum.DECREMENT, product: product}),
    removeProduct: (product: ProductClass) => dispatch({type: ProductActions.REMOVE_FROM_CART, product: product}),
    addProduct: (product: ProductClass) => dispatch({type: ProductActions.ADD_TO_CART, product: product}),
    setCurrentCartStep: (currentCartStep: number) => dispatch({type: CartStep.SET_CART_STEP, currentCartStep: currentCartStep})
  }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CartSlider));
