import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import classes from './TextPage.module.css';
import TextPageType from "../../../../templateObjects/textPageType/TextPageType";
import ContentData from './ContentData';

interface Props extends WithTranslation {
  type: TextPageType
}

class TextPage extends React.Component<Props> {

  componentDidMount(): void {
    window.scrollTo(0,0);
  }

  componentDidUpdate() {
    window.scrollTo(0,0);
  }

  getType(): string {
    switch (this.props.type) {
      case TextPageType.PERSONAL_DETAILS:
        return 'footer.details';
      case TextPageType.PRODUCTS_RETURN:
        return 'footer.postal';
      case TextPageType.TERMS:
        return 'footer.terms';
      case TextPageType.FAQ:
        return 'footer.faq';
    }
  }

  render() {
    const {t} = this.props;

    return (
      <div className={classes.TextPage}>
        <h1 className={classes.Title}>{t(this.getType())}</h1>
        <hr className={classes.UnderLine}/>
        <ContentData type={this.props.type}/>
      </div>
    );
  }
}

export default withTranslation()(TextPage);