import {WithTranslation, withTranslation} from "react-i18next";

import Axios from "axios";
import Button from "../../../common/button/Button";
import LoadingPage from "../../../common/loadingPage/LoadingPage";
import {ProductActions} from "../../../../templateObjects/product/ProductClass";
import React from "react";
import classes from "./DiscountComponent.module.css";
import {connect} from "react-redux";

interface State {
  showDiscount: boolean,
  codeValue: string,
  discountError: boolean,
  showBackdrop: boolean
}

interface Props extends WithTranslation {
  setHasDiscount: (hasDiscount: boolean) => void,
  hasDiscount: boolean,
  setDiscountValue: (discountValue: string) => void
}

class DiscountComponent extends React.Component<Props, State> {

  state = {
    showDiscount: true,
    codeValue: '',
    discountError: false,
    showBackdrop: false
  };

  componentDidMount(): void {
      if(this.props.hasDiscount){
        this.setState({showDiscount: false})
      }
  }

  render() {
    const {t} = this.props;

    const buttonClick = () => {
      this.setState({showBackdrop: true})
      Axios.post('/api/code', {code: this.state.codeValue.toUpperCase(), enviroment: window.location.hostname}).then((response: any) => {
        if(response.data.canBeUsed){
          this.props.setHasDiscount(true);
          this.props.setDiscountValue(this.state.codeValue.toUpperCase());
          this.setState({showDiscount: false, showBackdrop: false});
        } else{
          this.setState({discountError: true, showBackdrop: false});
        }
      }, (error) => {
        this.setState({discountError: true, showBackdrop: false});
      })
    };

    return (
      <React.Fragment>
         <LoadingPage show={this.state.showBackdrop} />
      {
        this.props.hasDiscount ? null : (
          <div >
            <div className={classes.DiscountComponent}>
            {
              this.state.showDiscount ? (
                <React.Fragment>
                  <input className={classes.Input} placeholder={t("cart.discount.enterCode")}
                         value={this.state.codeValue}
                         onChange={(e) => this.setState({codeValue: e.target.value.toUpperCase()})}/>
                  < Button clickCallBack={buttonClick}
                           isEmailButton={false}
                           text={t("cart.discount.send")}
                           customStyle={{
                             background: "white",
                             color: "#000",
                             border: "1px solid #000",
                           }}/>
                </React.Fragment>) : null
            }
            </div>
              {
                this.state.discountError ? <div className={classes.Warning}>{t("cart.summary.wrongDiscount")}</div> : null
              }
          </div>
        )
      }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    hasDiscount: state.hasDiscount
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setHasDiscount: (hasDiscount: boolean) => dispatch({type: ProductActions.SET_DISCOUNT, hasDiscount: hasDiscount}),
    setDiscountValue: (discountValue: string) => dispatch({type: ProductActions.SET_DISCOUNT_VALUE, discountValue: discountValue})
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DiscountComponent));
