import ProductClass, {ProductCategory} from "../../templateObjects/product/ProductClass";

import komplexGelMainProduct from '../../assets/image/Hyaluron_Collagen_komplex_gel_retina.png';
import komplexGel from '../../assets/image/Hyaluron_Collagen_komplex_gel_retina.jpg';
import komplexKapky from '../../assets/image/Hyaluron_Collagen_komplex_kapky_retina.jpg';
import komplexKapkyMainProduct from '../../assets/image//Hyaluron_Collagen_komplex_kapky_retina.png';
import komplexKapky2 from '../../assets/image/Hyaluron_Collagen_komplex_kapky_retina_2.jpg';

import kapkyCartIcon from '../../assets/image/cartIcons/Hyaluron_Collagen_komplex_kapky_retina.jpg';
import gelCartIcon from '../../assets/image/cartIcons/Hyaluron_Collagen_komplex_gel_retina.jpg';

const product1 = new ProductClass(34172,
    'products.kolagen.product1.name', 849, 'products.kolagen.product1.description',
    [komplexGel,komplexGelMainProduct],gelCartIcon, undefined, undefined, false, 1,
    ProductCategory.COLLAGEN, 'products.kolagen.product1.volume', 'products.kolagen.product1.secondTitle',
    'products.kolagen.product1.description2', 'products.kolagen.product1.keyWords', 'products.kolagen.product1.whyMe',
    'products.kolagen.product1.usage', 'products.kolagen.product1.ingredients', 'products.kolagen.product1.extraIngredients',
    'products.kolagen.product1.createdAt', undefined, 'products.kolagen.product1.nameWithCustomLines');

const product2 = new ProductClass(34590,
    'products.kolagen.product2.name', 899, 'products.kolagen.product2.description',
    [komplexKapky, komplexKapky2,komplexKapkyMainProduct],kapkyCartIcon, undefined, undefined, false, 1,
    ProductCategory.COLLAGEN, 'products.kolagen.product2.volume', 'products.kolagen.product2.secondTitle',
    'products.kolagen.product2.description2', 'products.kolagen.product2.keyWords', 'products.kolagen.product2.whyMe',
    'products.kolagen.product2.usage', 'products.kolagen.product2.ingredients', 'products.kolagen.product2.extraIngredients',
    'products.kolagen.product2.createdAt', undefined, 'products.kolagen.product2.nameWithCustomLines');


const kolagenProducts = [
  product1,
  product2
];

export default kolagenProducts;

