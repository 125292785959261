import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import jquery from 'jquery';
import classes from './DropDownBox.module.css';
import blackCross from '../../../assets/image/cross_black.svg';

interface Props extends WithTranslation {
    titles : string[],
    content: string[],
    ingredientsContent: string
}

type Ingredient = {
    name: string,
    description: string
}

class DropDownBox extends React.Component<Props>{


    render() {
        const {t} = this.props;

        jquery.fn.animateRotate = function (angle, start, duration, easing, complete) {
            var args = jquery.speed(duration, easing, complete);
            var step = args.step;
            return this.each(function (i, e) {
                args.complete = jquery.proxy(args.complete, e);
                args.step = function (now) {
                    jquery.style(e, 'transform', 'rotate(' + now + 'deg)');
                    if (step) return step.apply(e, arguments);
                };

                jquery({deg: start}).animate({deg: angle}, args);
            });
        };

        const slideUpAndDownComponent = (index: number, angle: number) => {
            let id = "#" + classes.OptionPart + "_" + index;
            let idSpan = "#" + classes.OptionPart + "_span_" + index;
            let button = jquery(idSpan);
            let start = angle;

            if (jquery(id).is(":visible")) {
                angle = 0;
                jquery(id).slideUp();
            } else {
                start = 0;
                jquery(id).slideDown();
            }

            jquery(button).animateRotate(angle, start);
        };

        const INGREDIENTS_INDEX = 2;

        const ingredients = Array.from(t(this.props.ingredientsContent, {returnObjects: true}) as Array<Ingredient>);

        return (
            <div className={classes.DropDownBox}>
                {this.props.titles.map((value, index) => {
                    return (
                        <div key={index} className={classes.DropDownContainer}>
                            <div onClick={(e) => {slideUpAndDownComponent(index, 45)}} className={classes.OptionPart}>
                                <h5 className={classes.Option}>{(t(value)).toUpperCase()}</h5>
                                <span id={classes.OptionPart + "_span_" + index} className={classes.OpenButton}><img src={blackCross} alt="blackCross"/></span>
                            </div>
                            <div className={classes.SlowDown} id={classes.OptionPart + "_" + index}>
                                {
                                    index === INGREDIENTS_INDEX ? ingredients.map((ing, index) => {
                                        return <div key={index} style={{display: 'flex', padding: '0px'}} className={classes.Ingredients}>
                                                    <h4 style={{marginTop: '2px', marginBottom: '15px', marginRight: '5px', minWidth: '220px', fontFamily: 'Gotham-Bold'}}>
                                                        {ing.name}
                                                    </h4>
                                                    <p style={{marginTop: '2px', marginBottom: '15px'}}>
                                                        {index === ingredients.length -1 ? ing.description : ing.description.substring(0).toLowerCase()}
                                                    </p>
                                               </div>
                                    }) : t(this.props.content[index])
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default withTranslation()(DropDownBox);