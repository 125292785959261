// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LanguageSwitch_LanguageSwitch__2qr9K{\n  font-weight: bold;\n  font-size: 12px;\n  letter-spacing: 3px;\n  text-align: center;\n  padding:5px 0px;\n  cursor: pointer;\n  margin: 0 3%;\n}\n.LanguageSwitch_LanguageList__3_QZJ{\n  font-family: Gotham-Light;\n  cursor: pointer;\n  height: 25px;\n  width: 50px;\n}\n\n@media screen and (min-width: 1026px){\n  .LanguageSwitch_LanguageSwitch__2qr9K > div:nth-child(2){\n    padding: 20px !important;\n    opacity: 1;\n    margin-top: 0px;\n  }\n\n  .LanguageSwitch_LanguageSwitch__2qr9K > div:nth-child(2) div{\n    height: auto;\n    line-height: 13px;\n    padding-top: 20px;\n  }\n\n  .LanguageSwitch_LanguageSwitch__2qr9K > div:nth-child(2) div:first-of-type{\n    padding-top: 0px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"LanguageSwitch": "LanguageSwitch_LanguageSwitch__2qr9K",
	"LanguageList": "LanguageSwitch_LanguageList__3_QZJ"
};
module.exports = exports;
