import React from "react";
import {connect} from "react-redux";
import SocialMediaComponent from "../../pageArtefacts/socialMediaComponent/SocialMediaComponent";
// @ts-ignore HARDCODED NOW
import logo from '../../../../assets/image/socialPic.PNG';
import AboutUs from "../../pageArtefacts/aboutUsComponent/AboutUsComponent";
import {withTranslation, WithTranslation} from "react-i18next";
import ProductOverviewComponent from "../../pageArtefacts/productOverviewComponent/ProductOverviewComponent";
import NewsComponent from "../../pageArtefacts/newsComponent/NewsComponent";
// @ts-ignore HARDCODED NOW
import {Location, LocationActions} from "../../../../templateObjects/location/Location";
import Backdrop from "../../header/backdrop/Backdrop";
import Modal from "../../../common/modal/Modal";
import KolagenProducts from '../../../../data/products/KolagenProducts';
import CBDProducts from '../../../../data/products/CBDProducts';
import {INSTAGRAM_ACCESS_TOKEN} from '../../../../utils/config/config';

interface State {
  modalOpen: boolean,
  instagramImagesLoaded: boolean
}

interface Props extends WithTranslation{
  setLocation: (location: Location) => void
}

class LandingPage extends React.Component<Props,State> {

  state = {
    modalOpen: false,
    instagramImagesLoaded: false
  };

  socialObject = {
    images: [logo, logo, logo, logo, logo, logo],
    timestamps: ["", "", "", "", "", ""],
    links: ["", "", "", "", "", ""]
  };

  componentDidMount(): void {
    this.props.setLocation(Location.LANDING_PAGE);
    setTimeout(() => {
      this.setState({modalOpen: false})
    }, 350);
    this.getImagesFromInstagram()
        .then(() => this.setState({instagramImagesLoaded: true}))
        .catch(() => this.setState({instagramImagesLoaded: false}));
  }

  componentWillUnmount():void{
    this.props.setLocation(Location.COMMON);
  }

  _backdropDisableHandler = () => {
    this.setState((prevState: State) => {
      return {modalOpen: !prevState.modalOpen}
    })
  };

  getImagesFromInstagram = async () => {
    const url = 'https://graph.instagram.com/me/media?fields=id&access_token=' + INSTAGRAM_ACCESS_TOKEN;
    const response = await fetch(url, { method: 'GET' });

    if (response.status === 200) {
      let jsonResponse = await response.json();

      for (let i = 0; i < Math.min(jsonResponse.data.length, this.socialObject.images.length); i++) {
        await this.getImageFromInstagram(jsonResponse.data[i].id, i);
      }
    } else {
      throw new Error("Response from Instagram API with status " + response.status);
    }
  }

  getImageFromInstagram = async(imageId, index) => {
    const url = 'https://graph.instagram.com/' + imageId + '?fields=id,media_url,timestamp,permalink&access_token=' + INSTAGRAM_ACCESS_TOKEN;
    const response = await fetch(url, { method: 'GET' });

    if (response.status === 200) {
      const jsonResponse = await response.json();
      this.socialObject.images[index] = jsonResponse.media_url;
      this.socialObject.timestamps[index] = jsonResponse.timestamp;
      this.socialObject.links[index] = jsonResponse.permalink;
    } else {
      throw new Error("Response from Instagram API with status " + response.status);
    }
  }

  render() {
    const {t} = this.props;

    const productOverview = {
      title: t('landingPage.productOverviewHeader'),
      products: [KolagenProducts[1],KolagenProducts[0],CBDProducts[1],CBDProducts[3],CBDProducts[7],CBDProducts[0]]
    };

    const newsLetterObject = {
      title: 'Sleva 10% na první nákup',
      description: 'lor sit amet, consectetuer adipiscing elit. Morbi imperdiet, mauris ac auctor dictum, ' +
        ' nisl ligula egestas nulla, et sollicitudin sem purus in lacus. Phasellus rhoncus. Etiam quis quam. ' +
        ' Nulla non arcu lacinia neque faucibus fringilla. Aliquam in lorem sit amet leo accumsan lacinia.',
      buttontext: t('ok')
    };


    return (
      <div style={{overflow: "hidden"}}>
        {
          this.state.modalOpen ? <Backdrop click={() => {
          }}/> : null
        }
        <Modal show={this.state.modalOpen} title={newsLetterObject.title}
               description={newsLetterObject.description} buttonText={newsLetterObject.buttontext} emailField={true}
               closeModal={this._backdropDisableHandler}/>
        <NewsComponent/>
        <AboutUs title={t('delgaHistory.title')} description={t('delgaHistory.description1')}
                 description2={t('delgaHistory.description2')} description3={t('delgaHistory.description3')}
                 buttonText={t('delgaHistory.more').toUpperCase()}/>
        <ProductOverviewComponent title={productOverview.title}
                                  products={productOverview.products} buttonText={t('aboutUs.buttonText')}/>
        <SocialMediaComponent title={t('socialMedia.title')} description={t('socialMedia.description')}
                              instagramData={this.socialObject} showInstagramImages={this.state.instagramImagesLoaded}/>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLocation: (location: Location) => dispatch({
      type: LocationActions.SET_LOCATION,
      location: location
    }),
  }
};

export default withTranslation()(connect(null, mapDispatchToProps)(LandingPage));