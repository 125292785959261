// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Registration_Registration__Ktrlg {\n    margin-top: 80px;\n    text-align: center;\n    display: block;\n}\n\n.Registration_Registration__Ktrlg form > div:first-of-type{\n    -ms-flex-pack: center !important;\n        justify-content: center !important;\n}\n\n.Registration_Registration__Ktrlg .Registration_Title__10yPT {\n    color: black;\n}\n\n.Registration_Info__jH-oG {\n    padding-top: 15px;\n    font-size: 0.9em;\n    color: #000000;\n    font-weight: bold;\n    letter-spacing: 0.25em;\n    text-transform: uppercase;\n}\n\n.Registration_Registration__Ktrlg .Registration_UnderLine__3BID4 {\n    color: #000000;\n    background-color: #000000;\n    border-width: 0;\n    height: 1px;\n    width: 520px;\n    margin-bottom: 20px;\n}\n\n.Registration_Registration__Ktrlg .Registration_Back__1EaMo {\n    letter-spacing: 3px;\n    margin-top: 35px;\n    margin-bottom: 35px;\n}\n\n.Registration_Registration__Ktrlg .Registration_Back__1EaMo{\n    text-align: left !important;\n    padding-left: 50px;\n}\n\n@media (min-width: 801px) {\n    .Registration_Registration__Ktrlg .Registration_Back__1EaMo {\n        display: none;\n    }\n}\n\n@media (max-width: 800px) {\n    .Registration_Registration__Ktrlg .Registration_UnderLine__3BID4 {\n        display: none;\n    }\n\n    .Registration_Title__10yPT {\n        display: none;\n    }\n\n    .Registration_Title__10yPT {\n        text-transform: uppercase;\n        font-size: 15px;\n        letter-spacing: 3px;\n        margin-bottom: 30px;\n    }\n\n    .Registration_Registration__Ktrlg {\n        margin-top: 70px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Registration": "Registration_Registration__Ktrlg",
	"Title": "Registration_Title__10yPT",
	"Info": "Registration_Info__jH-oG",
	"UnderLine": "Registration_UnderLine__3BID4",
	"Back": "Registration_Back__1EaMo"
};
module.exports = exports;
