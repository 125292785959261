import React from "react";
import Button from "../button/Button";
import classes from './MainProduct.module.css';
import ProductClass from "../../../templateObjects/product/ProductClass";
import TextEllipsis from 'react-text-ellipsis';
import {Link} from "react-router-dom";
import {withTranslation, WithTranslation} from "react-i18next";

interface Props extends WithTranslation{
  product: ProductClass,
  buttonText: string
}

const MainProduct: React.FunctionComponent<Props> = (props: Props) => {
  const {t} = props;

  return (
    <div className={classes.Product}>
      <img className={classes.Image} alt={props.product.picture[props.product.picture.length-1]} src={props.product.picture[props.product.picture.length-1]}/>
        {props.product.nameWithCustomLines && <h3>{t(props.product.nameWithCustomLines)}</h3>}
        {props.product.description && <span><TextEllipsis lines={4} ellipsisChars={'...'} >{t(props.product.description)}..</TextEllipsis></span>}
      <Link to={
        {
          pathname: `/detail/${props.product.id}`
        }
      }><Button clickCallBack={() => {}} text={props.buttonText.toUpperCase()} isEmailButton={false}/></Link>
    </div>
  )
};

export default withTranslation()(MainProduct);