// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartShippingOrderComponent_Wrapper__2lOWm {\n    margin-top: 2%;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    -ms-flex-align: baseline;\n        align-items: baseline;\n}\n\n.CartShippingOrderComponent_InfoWrapper__3C_3C{\n    text-align: right;\n}\n\n.CartShippingOrderComponent_InfoWrapperLabel__CrCT7 {\n    color: black;\n    font-weight: bold;\n    text-transform: uppercase;\n    letter-spacing: 3px;\n    font-size:12px;\n    font-family: Gotham-Medium;\n}\n\n.CartShippingOrderComponent_InfoWrapperPrice__yUo9c{\n    font-weight: normal;\n    display: inline-block;\n    min-width: 100px;\n    letter-spacing: 0px;\n    text-transform: none;\n    font-family: Gotham-Light;\n    font-size: 14px;\n}\n\n.CartShippingOrderComponent_InfoWrapperPrice__yUo9c:last-of-type{\n    text-transform: none;\n}\n\n.CartShippingOrderComponent_InfoWrapper__3C_3C p:first-of-type{\n    margin: 0;\n}\n\n.CartShippingOrderComponent_InfoWrapper__3C_3C p:last-of-type{\n    margin-top: 10%;\n}\n\n.CartShippingOrderComponent_InfoWrapper__3C_3C p:last-of-type span{\n    color: black;\n    font-weight: bold;\n    font-size: 18px;\n}\n\n.CartShippingOrderComponent_Link__1UEbA{\n    text-decoration: none;\n    color: black;\n    font-weight: bold;\n    text-transform: uppercase;\n    letter-spacing: 3px;\n    font-size:12px;\n    font-family: Gotham-Medium;\n}\n\n.CartShippingOrderComponent_BackToLogin__2ruFb {\n    display: none;\n}\n\n@media all and (max-width: 800px) {\n    .CartShippingOrderComponent_Wrapper__2lOWm{\n        display: block;\n    }\n\n    .CartShippingOrderComponent_BackButtonDesktop__nUBta{\n        display: none;\n    }\n\n    .CartShippingOrderComponent_BackToLogin__2ruFb{\n        display: block;\n        margin: 12% 0 0;\n    }\n}\n\n@media all and (max-width: 360px){\n\n    .CartShippingOrderComponent_InfoWrapperPrice__yUo9c{\n        min-width: 80px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Wrapper": "CartShippingOrderComponent_Wrapper__2lOWm",
	"InfoWrapper": "CartShippingOrderComponent_InfoWrapper__3C_3C",
	"InfoWrapperLabel": "CartShippingOrderComponent_InfoWrapperLabel__CrCT7",
	"InfoWrapperPrice": "CartShippingOrderComponent_InfoWrapperPrice__yUo9c",
	"Link": "CartShippingOrderComponent_Link__1UEbA",
	"BackToLogin": "CartShippingOrderComponent_BackToLogin__2ruFb",
	"BackButtonDesktop": "CartShippingOrderComponent_BackButtonDesktop__nUBta"
};
module.exports = exports;
