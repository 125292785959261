import React from "react";
import classes from "./Select.module.scss"

interface Props {
    initial : number,
    min : number,
    max : number,
    onChange : (value: number) => void,
    styleClass?: object
}

class Select extends React.Component<Props>{

    _handleChange = (e) => {
        let {value} = e.target;

        this.props.onChange(Number(value));
    };


    render() {
        const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i);

        return (
            <select value={this.props.initial} className={classes.CustomSelect} onChange={this._handleChange} style={this.props.styleClass}>
                {
                    range(this.props.min, this.props.max).map((x, index) => {
                            return <option value={x} key={index}>{x}</option>
                    })
                }
            </select>
        )
    }

}

export default Select;