import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { WithTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import axios from "axios";

import LoadingPage from "../../../../common/loadingPage/LoadingPage";
import { BlogUrlList } from "../blogUrlList";
import { returnCzechDateFormat } from "../functions";

import classes from "./BlogArticle.module.css";
import "./styles.scss";

interface RouteInfo {
  id: string;
}

interface Props extends RouteComponentProps<RouteInfo>, WithTranslation {}

interface State {
  article: any;
  loaded: boolean;
  htmlText: String;
}

class BlogArticle extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      article: {},
      loaded: false,
      htmlText: ""
    };
  }

  componentDidMount(): void {
    window.scrollTo(0,0);
   
         axios
      .get(BlogUrlList.articleData, {
        params: {id: this.props.match.params.id }
      })
      .then((res) => {
        let article = res.data;
        if (article.article === null ) {this.props.history.push("/blog")}
        else {
        this.setState({ article });
        this.setState({ loaded: true }) }
      })
      .catch(() => this.props.history.push("/blog"))
  }

  render() {
    const myHTML:string = `${this.state.loaded ? `${this.state.article.article.htmlText}`: null}`;
    const mySafeHTML: string = DOMPurify.sanitize(myHTML);
    return (

      <div className={classes.BlogArticle}>
        {this.state.loaded ? (
          <>
            <h1 className={classes.Title}>{this.state.article.article.title}</h1>
            <h2 className={classes.Date}>
              {returnCzechDateFormat(this.state.article.article.publicationDate)}
            </h2>
            <div
          className="BlogArticleHtmlContent"
          dangerouslySetInnerHTML={{ __html: mySafeHTML }}
        />
          </>
        ) : ( <LoadingPage show={!this.state.loaded} /> ) }
      </div>
    );
  }

}

export default BlogArticle;
