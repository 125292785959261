import React, {useEffect, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import classes from "./OrderSummary.module.css"
import {Link} from "react-router-dom";
import ProductClass, {ProductActions} from "../../../../templateObjects/product/ProductClass";
import {UserDetails} from "../../../../templateObjects/userDetails/UserDetails";
import {RouteConstants} from "../../../routes/RouteConstants";
import Button from "../../../common/button/Button";
import {CartStep} from "../../../../store/Reducer";
import {connect} from "react-redux";
import LoadingPage from '../../../common/loadingPage/LoadingPage';
import {getDiscountMultiplierByDiscountCode, oneTimeCode} from "../../../../utils/config/config";
import Axios from "axios";


interface Props extends WithTranslation {
  shippingPrice: number,
  paymentPrice: number,
  totalPrice: number,
  products: ProductClass[],
  userDetails: UserDetails,
  setCurrentCartStep: (currentCartStep: number) => void,
  shippingMethod: string,
  paymentType: number,
  deleteProductInCart: () => void,
  shippingType: string,
  packetaId: number,
  packetaInfo: string,
  hasDiscount: boolean,
  discountValue: string
}


const OrderSummary: React.FC<Props> = (props: Props) => {

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [isOrdered, setIsOrdered] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isWarning,setIsWarning] = useState(false);
  const [errMessage, setErrorMessage] = useState('cart.summary.warning');
  const [script, setScript] = useState<HTMLScriptElement>();
  const [secondScript, setSecondScript] = useState<HTMLScriptElement>();

  const {t} = props;

  useEffect(() => {
    window.scrollTo(0, 0)

    return () => {
      if(script){
        document.head.removeChild(script);
      }
      if(secondScript){
        document.head.removeChild(secondScript)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShippingPrice = () => {
    return props.totalPrice > 700 ? 0 : props.shippingPrice;
  };

  const submitOrder = (): void => {
    const SHOP_CURRENCY = 'CZK';
    setShowBackdrop(true);

    let countedPrice = props.totalPrice + getShippingPrice() + props.paymentPrice;

    const APIproducts = props.products.map((product: ProductClass) => {
      return (
        {
          productId: product.id,
          quantity: product.amount
        }
      )
    });
    setIsSubmitted(true);
    Axios.post('/api/order', {
      lang: props.i18n.language,
      enviroment: window.location.hostname,
      cartItems: APIproducts,
      customer: {
        contactPerson: {
          firstName: props.userDetails.contact.name,
          lastName: props.userDetails.contact.surname,
          email: props.userDetails.contact.email,
          phone: props.userDetails.contact.phone
        },
        company: {
          name: props.userDetails.companyDetails ? props.userDetails.companyDetails.firm : null,
          ico: props.userDetails.companyDetails ? props.userDetails.companyDetails.ico : null,
          dic: props.userDetails.companyDetails ? props.userDetails.companyDetails.dic : null,
        },
        address: {
          city: props.userDetails.address.city,
          zip: props.userDetails.address.zip,
          street: props.userDetails.address.address,
          country: props.userDetails.address.country,
          comment: props.userDetails.address.note,
          companyName: props.userDetails.address.company
        },
        deliveryAddress: props.userDetails.secondaryAddress ? {
          city: props.userDetails.secondaryAddress.city,
          zip: props.userDetails.secondaryAddress.zip,
          street: props.userDetails.secondaryAddress.address,
          country: props.userDetails.secondaryAddress.country,
          comment: props.userDetails.secondaryAddress.note,
          companyName: props.userDetails.secondaryAddress.company
        } : null
      },
      zasilkovnaAddressLine: props.packetaInfo,
      zasilkovnaId: props.packetaId,
      deliveryType: props.shippingType,
      currency: SHOP_CURRENCY,
      newsletter: props.userDetails.newletterAllowed,
      paymentType: props.paymentType,
      totalPrice: countedPrice,
      saleCode: props.hasDiscount ? props.discountValue : null
    }).then((response: any) => {
      

      const sc = document.createElement('script');
      setScript(sc);

      sc.src = 'https://www.seznam.cz/rs/static/rc.js';
      sc.async = true;
      sc.type = "text/javascript";

      const secondScript = document.createElement('script');
      secondScript.type = "text/javascript";
      setSecondScript(sc);

      secondScript.innerHTML = `\n// Měřicí kód Sklik.cz \nvar seznam_cId = 100071706;\n var seznam_value = ${countedPrice}; \n`
      document.head.appendChild(secondScript)
      document.head.appendChild(sc);


      // @ts-ignore
      gtag('event', 'purchase', {
        "transaction_id": response.data.orderId.toString(),
        "value": countedPrice,
        "currency": "CZK",
        "shipping": props.shippingPrice,
        "items": [...props.products.map(pr => {
          return {
            "id": pr.id,
            "amount": pr.amount,
            "name": t(pr.name)
          }
        })]
      });

      setOrderId(response.data.orderId);
      setIsOrdered(true);
      props.deleteProductInCart();
      setShowBackdrop(false);
      setIsWarning(false);

      window.scrollTo({top: 0, behavior: 'smooth'})
    }, (error) => {
      setIsWarning(true);
      if(error.response && error.response.data.messages){
        setErrorMessage(error.response.data.messages[0].message)
      }
      setIsSubmitted(false)
      setShowBackdrop(false)
    })
  };

  const invoiceAddress = () => {

    if (props.userDetails && props.userDetails.contact && props.userDetails.address) {

      const contact = [props.userDetails.contact.name,
        props.userDetails.contact.surname,
        props.userDetails.contact.email,
        props.userDetails.contact.phone];

      const adress = [props.userDetails.address.address,
        props.userDetails.address.city,
        props.userDetails.address.zip,
        props.userDetails.address.country];

      return (
        <React.Fragment>
          <h4 className={classes.AddressLabel}>{t("cart.summary.invoiceAddress")}</h4>
          <div className={classes.AddressData}>
            <span className={classes.Contact}>{contact[0]} {contact[1]}</span>
            {
              contact.slice(2).map((contact, index) => {
                return <span className={classes.Contact} key={index}>, {contact}</span>
              })
            }
            <br/>
            <span className={classes.Adress}>{adress[0]}</span>
            {
              adress.slice(1).map((adress, index) => {
                return <span className={classes.Adress} key={index}>, {adress}</span>
              })
            }
          </div>
        </React.Fragment>
      )

    } else {
      return null;
    }
  }

  const deliveryAddress = () => {
    //TODO: merge with invoice address
    if (props.userDetails && props.userDetails.secondaryContact && props.userDetails.secondaryAddress) {

      const adress = [props.userDetails.secondaryAddress.address,
        props.userDetails.secondaryAddress.city,
        props.userDetails.secondaryAddress.zip,
        props.userDetails.secondaryAddress.country];

      return (
        <React.Fragment>
          <h4 className={classes.AddressLabel}>{t("cart.summary.deliveryAddress")}</h4>
          <div className={classes.AddressData}>
            <span className={classes.Adress}>{adress[0]}</span>
            {
              adress.slice(1).map((adress, index) => {
                return <span className={classes.Adress} key={index}>, {adress}</span>
              })
            }
          </div>
        </React.Fragment>
      )

    } else {
      return null;
    }
  }

  const billingInformation = () => {

    if (props.userDetails && props.userDetails.companyDetails) {

      return (
        <React.Fragment>
          <h4 className={classes.InvoiceLabel}>{t("cart.summary.invoiceData")}</h4>
          <div className={classes.InvoiceData}>
            <div className={classes.Firm}>{props.userDetails.companyDetails.firm}</div>
            <div className={classes.Firm}>{t("cart.summary.ico")}:&nbsp;{props.userDetails.companyDetails.ico}</div>
            <div className={classes.Firm}>{t("cart.summary.dic")}:&nbsp;{props.userDetails.companyDetails.dic}</div>
          </div>
        </React.Fragment>
      )
    } else {
      return null;
    }
  }

  const emailAddress = () => {

    if (props.userDetails && props.userDetails.contact) {

      return (
        <span className={classes.EmailAddress}>{props.userDetails.contact.email}</span>
      )
    }
  }

  return (
    <React.Fragment>
      <LoadingPage show={showBackdrop} />
    <div className={classes.OrderSummary}>
      {isSubmitted ?
        (<React.Fragment>
          <h4 className={classes.OrderTitle}>{t("cart.summary.yourOrder")}
            {orderId}
            {t("cart.summary.yourOrderEnd")}</h4>
          <p className={classes.SentMail}>{t("cart.summary.sentToMail")}{emailAddress()}</p></React.Fragment>) : null
      }
      {
        isOrdered ? null : <h4 className={classes.OrderSummaryLabel}>{t("cart.summary.orderSummaryLabel")}</h4>
      }
      {
        isOrdered ? null : (
          <div className={classes.OrderSummaryProducts}>
            {
              props.products.map((product,index) => {
                return <div className={classes.Product} key={index}>
                  <div>{t(product.name)}</div>
                  {
                    product.amount > 1 ? <div>{product.amount + ' ks'}</div> : null
                  }
                  <div className={classes.Price}>{product.price * product.amount}&nbsp;{t("currency")}</div>
                </div>
              })
            }
            <div className={classes.Product}>
              <div>{t("cart.summary.orderSummaryShipping")} {props.shippingMethod}</div>
              <div className={classes.Price}>{getShippingPrice()}&nbsp;{t("currency")}</div>
            </div>
            <div className={classes.Product}>
              <div>{t("cart.summary.orderSummaryPayment")}</div>
              <div className={classes.Price}>{props.paymentPrice}&nbsp;{t("currency")}</div>
            </div>
          </div>
        )
      }
      {
        invoiceAddress()
      }
      {
        deliveryAddress()
      }
      {
        billingInformation()
      }
      <div className={(isWarning)?classes.Warning:classes.NoWarning}>{errMessage !== 'cart.summary.warning' ? errMessage : t("cart.summary.warning")}</div>
      <div className={classes.OrderSummaryFooter}>
        <Link to={isSubmitted ? RouteConstants.aboutUs : `4`} className={classes.Link}
              onClick={() => isSubmitted ? null : props.setCurrentCartStep(4)}>
          {isSubmitted ? t("cart.summary.backToShop") : t("cart.summary.backToPreviousPage")}</Link>
        {
          isSubmitted ? null : (
            <div className={classes.TotalPrice}>
              <div>{t("cart.order.all")}</div>
              <div
                className={classes.TotalPriceValue}>
                {
                  props.hasDiscount && oneTimeCode(props.discountValue) === 0 ? (props.totalPrice * getDiscountMultiplierByDiscountCode(props.discountValue) + getShippingPrice() + props.paymentPrice).toFixed(2) : (props.totalPrice  + getShippingPrice() + props.paymentPrice - oneTimeCode(props.discountValue)) > 0 ? props.totalPrice  + getShippingPrice() + props.paymentPrice - oneTimeCode(props.discountValue) : 0
                }
                &nbsp;{t("currency")}</div>

            </div>
          )
        }
      </div>
      {
        isSubmitted ? null :
          (
            <div style={{textAlign: 'right'}}>
              {
                props.hasDiscount && oneTimeCode(props.discountValue) === 0?
                  <div className={classes.Label} style={{fontWeight: 'bold'}}>{t("cart.summary.discount", {value: 100 - (getDiscountMultiplierByDiscountCode(props.discountValue) * 100)})}</div> : null
              }
              {
                oneTimeCode(props.discountValue) !== 0 ? <div className={classes.Label} style={{fontWeight: 'bold'}}>{t("cart.summary.extraDiscount", {value: oneTimeCode(props.discountValue)})}</div> : null
              }
              <Button text={t("cart.summary.submitOrder").toUpperCase()}
                                                     isEmailButton={false}
                                                     clickCallBack={() => submitOrder()}
                                                     customStyle={{marginTop: "5%"}}/></div>)
      }
    </div>
    </React.Fragment>
  )
};

const mapStateToProps = (state) => {
  return {
    paymentType: state.paymentType,
    hasDiscount: state.hasDiscount,
    discountValue: state.discountValue
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentCartStep: (currentCartStep: number) => dispatch({
      type: CartStep.SET_CART_STEP,
      currentCartStep: currentCartStep
    }),
    deleteProductInCart: () => dispatch({type: ProductActions.DELETE_CART})
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderSummary));



