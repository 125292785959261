import React from "react";
import classes from "./CartOverview.module.css";
import {WithTranslation, withTranslation} from "react-i18next";


class CartOverview extends React.Component<WithTranslation>{
    render() {
        const {t} = this.props;
        return (
            <div className={classes.CartOverviewGrid}>
                <span className={`${classes.CardOverviewItem} ${classes.CartProduct}`}>{t("cart.overview.product")}</span>
                <span className={`${classes.CardOverviewItem} ${classes.CartPrice}`}>{t("cart.overview.price")}</span>
                <span className={`${classes.CardOverviewItem} ${classes.CardAmount}`}>{t("cart.overview.amount")}</span>
                <span className={`${classes.CardOverviewItem} ${classes.CartOverallPrice}`}>{t("cart.overview.total")}</span>
            </div>
        )
    }
}

export default withTranslation()(CartOverview);