import React,{useState} from 'react';
import classes from './DeliveryOffer.module.css';
import closeIcon from "../../../assets/image/cross.svg";

interface Props {
  text: string
}

const DeliveryOffer: React.FC<Props> = (props: Props) => {

  const [closed,setClosed] = useState(false);

  return (
    <div className={!closed ? classes.DeliveryOffer : classes.DeliveryOffer + ' ' + classes.Closed}>
      <p>{props.text}</p>
      <img className={classes.DeliveryOfferCloseIcon} src={closeIcon} onClick={()=>setClosed(true)} alt=""/>
    </div>
  )
};

export default DeliveryOffer;