import React from "react";
import classes from "./Spinner.module.css";

interface Props {
    initial : number,
    min ? : number,
    max ? : number,
    onRemove ? : () => void,
    onMinus : (value: number) => void
    onPlus : (value: number) => void
    isCartSlider? : boolean,
}

class Spinner extends React.Component<Props>{

    _handleMinus = () => {
        if(this.props.min && (this.props.min < this.props.initial )) {
            this.props.onMinus(this.props.initial - 1)
        }
        else if(this.props.initial !== 1) this.props.onMinus(this.props.initial - 1)
        else if (this.props.onRemove) {
            this.props.onRemove()
        }
    };

    _handlePlus = () => {
        if(this.props.max && (this.props.max > this.props.initial)){
           this.props.onPlus(this.props.initial + 1)
        }
        else this.props.onPlus(this.props.initial + 1)
    };
    render(){
        return(
          <div className={!this.props.isCartSlider? classes.Spinner : classes.SpinnerForSlider}>
            <div className={classes.SpinnerButton} onClick={this._handleMinus}>-</div>
            <div className={classes.Label}>{this.props.initial}</div>
            <div className={classes.SpinnerButton} onClick={this._handlePlus}>+</div>
        </div>);
    }
}

export default Spinner;