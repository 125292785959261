import React, {useState,useEffect} from "react";
import classes from './Product.module.css';
import ProductClass from "../../../templateObjects/product/ProductClass";
import {withTranslation, WithTranslation} from "react-i18next";

interface Props extends WithTranslation {
  product: ProductClass
}

const Product: React.FC<Props> = (props: Props) => {
  const {t} = props;
  const [hovered, setHover] = useState(false);
  
  const [showPrice,setShowPrice] = useState(true);
  useEffect(() => {
    setShowPrice(["en","es"].includes(props.i18n.language)?false:true);
   }, [props.i18n.language]);

  let hoverStyle = hovered && props.product.hoverPicture ? `url(${props.product.hoverPicture})` : `url(${props.product.picture[0]})`;

  return (
    <div className={classes.Product}>
      <div style={{backgroundImage: hoverStyle, backgroundSize: '100%'}} className={classes.Image} onMouseEnter={() => setHover(true)}
           onMouseLeave={() => {
             setHover(false)
           }}>
        {
            props.product.discount ? <div style={{textAlign: "center"}}>
                <strong className={classes.DiscountTitle}>{t('discount').toUpperCase() + ' / '}</strong>
                <strong className={classes.Discount}>{`${props.product.discount} ${t('currency')}`}</strong>
            </div> : null
        }
        {
          props.product.isNew ? <p className={classes.New}>{t('new').toUpperCase()}</p> : null
        }
      </div>
      <div className={classes.ProductDescription}>
          <p className={classes.Name}>{t(props.product.name)}</p>
          {showPrice ? <p className={classes.Price}>{`${props.product.price} ${t('currency')}`}</p>:null}
      </div>
    </div>
  )
};

export default withTranslation()(Product);