import React from "react";
import DeliveryClass, {SelectMethod} from "../../../../templateObjects/delivery/DeliveryClass";
import classes from "./CartShippingAndPayment.module.css";
import Divider from "../../../common/divider/Divider"
import CartSelectableComponent from "../cartSelectableComponent/CartSelectableComponent"
import CartShippingOrderComponent from "../cartShippingOrderComponent/CartShippingOrderComponent"
import {withTranslation, WithTranslation} from "react-i18next";
import Packeta from '../../../../lib/Packeta';
import {connect} from "react-redux";
import { UserDetails } from "../../../../templateObjects/userDetails/UserDetails";


interface Props extends WithTranslation {
    totalPrice: number,
    setPaymentPrice: (paymentPrice: number) => void,
    setShippingPrice: (paymentPrice: number) => void,
    setShippingMethod: (shippingMethod: string) => void,
    setShippingType: (shippingType: string) => void,
    setPacketaInfo: (id: number, info: string) => void,
    userDetails?: UserDetails,
    shippingType?: string,
    paymentType?: number
}

const CESKA_POSTA = 'CESKA_POSTA';
const ZASILKOVNA = 'ZASILKOVNA';
const DPD = 'DPD';
const OSOBNI_ODBER = 'OSOBNI_ODBER';

class CartShippingAndPayment extends React.Component<Props> {

    getShippingTypeIndex = (shippingType: string):number => {
      if(!this.fromSlovakia){
      switch (shippingType) {
        case "CESKA_POSTA":
          return 0;
        case "ZASILKOVNA":
          return 1;
        case "DPD":
          return 2;
        case "OSOBNI_ODBER":
          return 3;
        default:
          return 0;
      }
    }else{
      return 0;
    }
    }
    
    fromSlovakia: boolean = this.props.userDetails?.address.country.toLowerCase() === "slovensko" ? true : false;
    initPaymentIndex:number = this.props.paymentType? this.props.paymentType === 7 ? 0 : 1 : 0;
    initShippingIndex:number = this.props.shippingType? this.getShippingTypeIndex(this.props.shippingType) : 0;

    state = !this.fromSlovakia ? {
        shippingMethods: [
            new DeliveryClass("Česká pošta", this.props.totalPrice > 700 ? 0 : 100),
            new DeliveryClass("Zásilkovna (ČR + SK)", this.props.totalPrice > 700 ? 0 : 45),
            new DeliveryClass("DPD kurýr", this.props.totalPrice > 700 ? 0 : 100),
            new DeliveryClass("Osobní odběr na Lonkova 510 Pardubice DELGA s.r.o", 0)
        ],
        paymentMethods: [
            new DeliveryClass("Dobírka", 20),
            new DeliveryClass("Převodem z účtu", 0)
        ],
        selectedShippingMethodIndex: this.initShippingIndex,
        selectedPaymentMethodIndex: this.initPaymentIndex
    }:{
        shippingMethods: [
            new DeliveryClass("Zásilkovna (ČR + SK)", 45)
        ],
        paymentMethods: [
            new DeliveryClass("Dobírka", 20)
        ],
        selectedShippingMethodIndex: 0,
        selectedPaymentMethodIndex: 0
    };

    componentDidMount() {
        window.scrollTo(0,0);
        if(!this.initShippingIndex){
          if(!this.fromSlovakia){
            this.props.setShippingType(CESKA_POSTA);
          }else{
            this.props.setShippingType(ZASILKOVNA);
          }
          this.props.setShippingMethod(" - " + this.state.shippingMethods[0].description);  
        }
    }

    setShippingTypeBySelection = (index) => {
      if(!this.fromSlovakia){
      switch (index) {
        case 0:
          this.props.setShippingType(CESKA_POSTA)
          break;
        case 1:
          this.props.setShippingType(ZASILKOVNA)
          break;
        case 2:
          this.props.setShippingType(DPD)
          break;
        case 3:
          this.props.setShippingType(OSOBNI_ODBER)
          break;
      }
      }else{
        this.props.setShippingType(ZASILKOVNA)
      }
    }

    selectMethod(method: SelectMethod, index: number) {
        if (method === SelectMethod.SHIPPING) {
            let shippingMethod = this.state.shippingMethods[index].description;
            this.props.setShippingMethod(" - " + shippingMethod);
            this.setShippingTypeBySelection(index);
            this.setState({selectedShippingMethodIndex: index})
          if(!this.fromSlovakia){
            if (index === 1) {
              Packeta.Widget.pick('a57acc1626c48ee4', (point) => {
                  if (point) {
                      shippingMethod = "Zásilkovna (" + point.place + " - " + point.nameStreet + ")";
                      this.props.setPacketaInfo(point.id, point.place + " - " + point.nameStreet);
                      this.props.setShippingMethod(" - " + shippingMethod);
                      let shippingMethods = [...this.state.shippingMethods];
                      shippingMethods[1].description = shippingMethod;
                      this.setState({shippingMethods: shippingMethods});
                  }
              }, {country: 'cz,sk'});
            } else {
                let shippingMethods = [...this.state.shippingMethods];
                shippingMethods[1].description = "Zásilkovna (ČR + SK)";
                this.setState({shippingMethods: shippingMethods});
          }
        }else{
            Packeta.Widget.pick('a57acc1626c48ee4', (point) => {
              if (point) {
                  shippingMethod = "Zásilkovna (" + point.place + " - " + point.nameStreet + ")";
                  this.props.setPacketaInfo(point.id, point.place + " - " + point.nameStreet);
                  this.props.setShippingMethod(" - " + shippingMethod);
                  let shippingMethods = [...this.state.shippingMethods];
                  shippingMethods[0].description = shippingMethod;
                  this.setState({shippingMethods: shippingMethods});
              }
          }, {country: 'cz,sk'});
        }
        } else {
            this.setState({selectedPaymentMethodIndex: index})
        }
    }

    render(){
        const {t} = this.props;
        return (
            <div>
                <p className={classes.heading}>{t("cart.shippingAndPaymentStep.chooseShippingMethod")}</p>
                <form>
                    {this.state.shippingMethods.map((deliver, index) => {
                        return <CartSelectableComponent checkedByDefault={this.state.selectedShippingMethodIndex === index} key={index} item={deliver}
                                                        method={SelectMethod.SHIPPING}
                                                        index={index} select={this.selectMethod.bind(this)}
                                                        numberOfOptions={this.state.shippingMethods.length}/>
            }   )}
                </form>
                <Divider type={"ShippingDivider"}/>
                <p className={classes.heading}>{t("cart.shippingAndPaymentStep.choosePaymentMethod")}</p>
                <form>
                    {this.state.paymentMethods.map((deliver, index) => {
                        return <CartSelectableComponent checkedByDefault={this.state.selectedPaymentMethodIndex === index} key={index} item={deliver}
                                                        method={SelectMethod.PAYMENT}
                                                        index={index} select={this.selectMethod.bind(this)}
                                                        numberOfOptions={this.state.paymentMethods.length}/>

                })}
                </form>
              <Divider type="ShippingDivider"/>
                <CartShippingOrderComponent
                    setPaymentPrice={this.props.setPaymentPrice}
                    setShippingPrice={this.props.setShippingPrice}
                    paymentTypeIndex={this.state.selectedPaymentMethodIndex}
                    shippingPrice={this.state.shippingMethods[this.state.selectedShippingMethodIndex].price}
                    paymentPrice={this.state.paymentMethods[this.state.selectedPaymentMethodIndex].price}
                    productsPrice={this.props.totalPrice}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
  return {
      userDetails: state.userDetails,
      shippingType: state.shippingType,
      paymentType: state.paymentType
  }
}

export default withTranslation()(connect(mapStateToProps, null)(CartShippingAndPayment));