import React from "react";
import classes from "./CartSignInOptions.module.css";
import CartSignInOption from "../cartSignInOption/CartSignInOption";
import {withTranslation, WithTranslation} from "react-i18next";
import {Link} from "react-router-dom"
import {CartStep} from "../../../../store/Reducer";
import {connect} from "react-redux";

interface Props extends WithTranslation{
  setCurrentCartStep: (currentCartStep: number) => void
}

class CartSignInOptions extends React.Component<Props>{
    
    componentDidMount(){
        window.scrollTo(0,0);
    }

    render(){
        const {t} = this.props;
        return (
            <div className={classes.CartSignInOptionsContainer}>
                <CartSignInOption type={"registered"} classProp={classes.CartSignInOption} headline={t("cart.signIn.headings.registered")} description={["iscing elit. Vivamus  leo. Aliquam erat volutpat. Nam quis nulla."]}/>
                <CartSignInOption type={"firstTimer"} classProp={classes.CartSignInOption} headline={t("cart.signIn.headings.firstTime")} description={[t("cart.signIn.descriptions.firstTimer1"),t("cart.signIn.descriptions.firstTimer2"),t("cart.signIn.descriptions.firstTimer3")]}/>
                <CartSignInOption type={"oneTimer"} classProp={classes.CartSignInOption} headline={t("cart.signIn.headings.oneTimer")} description={[t("cart.signIn.descriptions.oneTimer")]}/>
                <span className={classes.BackToCart}><Link to={`/cart/1`} onClick={() => this.props.setCurrentCartStep(1)}>{t("cart.signIn.backToCart")}</Link></span>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentCartStep: (currentCartStep: number) => dispatch({type: CartStep.SET_CART_STEP, currentCartStep: currentCartStep})
  }
};

export default  withTranslation()(connect(null, mapDispatchToProps)(CartSignInOptions));