import ProductClass, {ProductCategory} from "../../templateObjects/product/ProductClass";

import samponNaVlasyCBD from '../../assets/image/Sampon_na_vlasy_s_CBD_retina.jpg';
import mastCBD from '../../assets/image/Mast_CBD_retina.jpg';
import detskyKremOpruzeninyCBD from '../../assets/image/Detsky_krem_na_opruzeniny_CBD_retina.jpg';
import intimniGelCBD from '../../assets/image/Intimni_myci_gel_CBD_retina.jpg';
import sprchovyGelCBD from '../../assets/image/Sprchovy_gel_s_CBD_retina.jpg';
import sprchovyOlejCBD from '../../assets/image/Sprchovy_olej_s_CBD_retina.jpg';
import balzamNaRty from '../../assets/image/Balzam_na_rty_CBD_retina.jpg';
import gelSKyselinouCBD from '../../assets/image/Gel_s_kyselinou_hyaluronovou_CBD_retina.jpg';
import teloveMleko from '../../assets/image/Telove_mleko_s_CBD_retina.jpg';
import dezioralCBD from '../../assets/image/Dezioral_CBD_olej_sprej_retina.jpg';

import olejCBD200mgMainProduct from '../../assets/image/Sprchovy_olej_s_CBD_retina.png';
import mlekoMainProduct from '../../assets/image/Telove_mleko_s_CBD_retina.png';
import gelMainProduct from '../../assets/image/Gel_s_kyselinou_hyaluronovou_CBD_retina.png';
import samponMainProduct from '../../assets/image/Sampon_na_vlasy_s_CBD_retina.png';

import samponCartIcon from '../../assets/image/cartIcons/Sampon_na_vlasy_s_CBD_retina.jpg';
import sprchovyOlejCartIcon from '../../assets/image/cartIcons/Sprchovy_olej_s_CBD_retina.jpg';
import sprchovyGelCartIcon from '../../assets/image/cartIcons/Sprchovy_gel_s_CBD_retina.jpg';
import teloveMlekoCartIcon from '../../assets/image/cartIcons/Telove_mleko_s_CBD_retina.jpg';
import imtimniGelCartIcon from '../../assets/image/cartIcons/Intimni_myci_gel_CBD_retina.jpg';
import detskyKremCartIcon from '../../assets/image/cartIcons/Detsky_krem_na_opruzeniny_CBD_retina.jpg';
import mastCartIcon from '../../assets/image/cartIcons/Mast_CBD_retina.jpg';
import gelSKyselinouCartIcon from '../../assets/image/cartIcons/Gel_s_kyselinou_hyaluronovou_CBD_retina.jpg';
import balzamNaRtyCartIcon from '../../assets/image/cartIcons/Balzam_na_rty_CBD_retina.jpg';
import dezioralCBDIcon from '../../assets/image/cartIcons/Dezioral_CBD_olej_sprej_retina_kosik.jpg';

const product1 = new ProductClass(34208,
    'products.cbd.product1.name', 329, 'products.cbd.product1.description',
    [samponNaVlasyCBD,samponMainProduct],samponCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product1.volume', 'products.cbd.product1.secondTitle',
    'products.cbd.product1.description2', 'products.cbd.product1.keyWords', 'products.cbd.product1.whyMe',
    'products.cbd.product1.usage', 'products.cbd.product1.ingredients', 'products.cbd.product1.extraIngredients',
    'products.cbd.product1.createdAt', undefined, 'products.cbd.product1.nameWithCustomLines');

const product2 = new ProductClass(34207,
    'products.cbd.product2.name', 329, 'products.cbd.product2.description',
    [sprchovyOlejCBD,olejCBD200mgMainProduct],sprchovyOlejCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product2.volume', 'products.cbd.product2.secondTitle',
    'products.cbd.product2.description2', 'products.cbd.product2.keyWords', 'products.cbd.product2.whyMe',
    'products.cbd.product2.usage', 'products.cbd.product2.ingredients', 'products.cbd.product2.extraIngredients',
    'products.cbd.product2.createdAt', undefined, 'products.cbd.product2.nameWithCustomLines');

const product3 = new ProductClass(34209,
    'products.cbd.product3.name', 269, 'products.cbd.product3.description',
    [sprchovyGelCBD],sprchovyGelCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product3.volume', 'products.cbd.product3.secondTitle',
    'products.cbd.product3.description2', 'products.cbd.product3.keyWords', 'products.cbd.product3.whyMe',
    'products.cbd.product3.usage', 'products.cbd.product3.ingredients', 'products.cbd.product3.extraIngredients',
    'products.cbd.product3.createdAt', undefined, undefined);

const product4 = new ProductClass(34212,
    'products.cbd.product4.name', 359, 'products.cbd.product4.description',
    [teloveMleko,mlekoMainProduct],teloveMlekoCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product4.volume', 'products.cbd.product4.secondTitle',
    'products.cbd.product4.description2', 'products.cbd.product4.keyWords', 'products.cbd.product4.whyMe',
    'products.cbd.product4.usage', 'products.cbd.product4.ingredients', 'products.cbd.product4.extraIngredients',
    'products.cbd.product4.createdAt', undefined, 'products.cbd.product4.nameWithCustomLines');

const product5 = new ProductClass(34211,
    'products.cbd.product5.name', 199, 'products.cbd.product5.description',
    [intimniGelCBD],imtimniGelCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product5.volume', 'products.cbd.product5.secondTitle',
    'products.cbd.product5.description2', 'products.cbd.product5.keyWords', 'products.cbd.product5.whyMe',
    'products.cbd.product5.usage', 'products.cbd.product5.ingredients', 'products.cbd.product5.extraIngredients',
    'products.cbd.product5.createdAt', undefined, undefined);

const product6 = new ProductClass(34210,
    'products.cbd.product6.name', 259, 'products.cbd.product6.description',
    [detskyKremOpruzeninyCBD],detskyKremCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product6.volume', 'products.cbd.product6.secondTitle',
    'products.cbd.product6.description2', 'products.cbd.product6.keyWords', 'products.cbd.product6.whyMe',
    'products.cbd.product6.usage', 'products.cbd.product6.ingredients', 'products.cbd.product6.extraIngredients',
    'products.cbd.product6.createdAt', undefined, undefined);

const product7 = new ProductClass(34213,
    'products.cbd.product7.name', 389, 'products.cbd.product7.description',
    [mastCBD],mastCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product7.volume', 'products.cbd.product7.secondTitle',
    'products.cbd.product7.description2', 'products.cbd.product7.keyWords', 'products.cbd.product7.whyMe',
    'products.cbd.product7.usage', 'products.cbd.product7.ingredients', 'products.cbd.product7.extraIngredients',
    'products.cbd.product7.createdAt', undefined, undefined);

const product8 = new ProductClass(34728,
    'products.cbd.product8.name', 899, 'products.cbd.product8.description',
    [gelSKyselinouCBD,gelMainProduct],gelSKyselinouCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product8.volume', 'products.cbd.product8.secondTitle',
    'products.cbd.product8.description2', 'products.cbd.product8.keyWords', 'products.cbd.product8.whyMe',
    'products.cbd.product8.usage', 'products.cbd.product8.ingredients', 'products.cbd.product8.extraIngredients',
    'products.cbd.product8.createdAt', undefined, 'products.cbd.product8.nameWithCustomLines');

const product9 = new ProductClass(34703,
    'products.cbd.product9.name', 129, 'products.cbd.product9.description',
    [balzamNaRty],balzamNaRtyCartIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product9.volume', 'products.cbd.product9.secondTitle',
    'products.cbd.product9.description2', 'products.cbd.product9.keyWords', 'products.cbd.product9.whyMe',
    'products.cbd.product9.usage', 'products.cbd.product9.ingredients', 'products.cbd.product9.extraIngredients',
    'products.cbd.product9.createdAt', undefined, undefined);

const product10 = new ProductClass(37735,
    'products.cbd.product10.name', 1159, 'products.cbd.product10.description',
    [dezioralCBD],dezioralCBDIcon, undefined, undefined, false, 1,
    ProductCategory.CBD, 'products.cbd.product10.volume', 'products.cbd.product10.secondTitle',
    'products.cbd.product10.description2', 'products.cbd.product10.keyWords', 'products.cbd.product10.whyMe',
    'products.cbd.product10.usage', 'products.cbd.product10.ingredients', 'products.cbd.product10.extraIngredients',
    'products.cbd.product10.createdAt', undefined, undefined);

const cbdProducts = [
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product8,
  product9,
  product10
];

export default cbdProducts;