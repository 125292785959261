const czechLanguageTranslation = {
    toolbar: {
        default: "Výchozí",
        save: "Uložit",
        font: "Písmo",
        formats: "Formáty",
        fontSize: "Velkost",
        bold: "Tučně",
        underline: "Podčárknout",
        italic: "Kurzíva",
        strike: "Pročárknout",
        subscript: "Dolní index",
        superscript: "Horní index",
        removeFormat: "Zrušit formátování",
        fontColor: "Farba písma",
        hiliteColor: "Hilite Color",
        indent: "Odrážka vpravo",
        outdent: "Odrážka vlevo",
        align: "Zarovnat",
        alignLeft: "Zarovnat doleva",
        alignRight: "Zarovnat doprava",
        alignCenter: "Zarovnat na střed",
        alignJustify: "Zarovnat",
        list: "Seznam",
        orderList: "Seznam s pořadím",
        unorderList: "Seznam bez pořadí",
        horizontalRule: "Vodorovná čára",
        hr_solid: "Pevná vodorovná čára",
        hr_dotted: "Tečkovaná vodorovná čára",
        hr_dashed: "Přerušovaná vodorovná čára",
        table: "Tabule",
        link: "Link",
        image: "Obrázek",
        video: "Video",
        fullScreen: "Celá obrazovka",
        showBlocks: "Ukázat bloky",
        codeView: "Zobrazit kód",
        undo: "Krok zpět",
        redo: "Krok vpřed",
        preview: "Náhled",
        print: "Tisknout",
        tag_p: "Paragraf",
        tag_div: "Normální (DIV)",
        tag_h: "Nadpis h",
        tag_blockquote: "Citace",
        tag_pre: "Kód",
        template: "Šablona",
      },
      dialogBox: {
        linkBox: {
          title: "Vložit link",
          url: "URL pro link",
          text: "Text k zobrazení",
          newWindowCheck: "Otevřít v novém okně",
        },
        imageBox: {
          title: "Název",
          file: "Nahrát z počítače",
          url: "URL adresa",
          altText: "Alternativní text",
        },
        videoBox: {
          title: "Název",
          url: "URL adresa",
        },
        caption: "Vložit popis",
        close: "Zavřít",
        submitButton: "Odeslat",
        revertButton: "Vrátit zpět",
        proportion: "Omezit proporce",
        width: "Šířka",
        height: "Výška",
        basic: "Základní",
        left: "Vlevo",
        right: "Vpravo",
        center: "Střed",
      },
  
      controller: {
        edit: "Změnit",
        remove: "Odstranit",
        insertRowAbove: "Vložit řádek nahoru",
        insertRowBelow: "Vložit řádek dolu",
        deleteRow: "Odstranit řádek",
        insertColumnBefore: "Vložit stloupec před",
        insertColumnAfter: "Vložit stloupec po",
        deleteColumn: "Odstranit stloupec",
        resize100: "Na velkost 100%",
        resize75: "Na velkost 75%",
        resize50: "Na velkost 50%",
        resize25: "Na velkost 25%",
        mirrorHorizontal: "Zrcadlově, Vodorovně",
        mirrorVertical: "Zrcadlově, Svisle",
        rotateLeft: "Točit doleva",
        rotateRight: "Točit doprava",
        maxSize: "Maximální velkost",
        minSize: "Minimální velkost",
        tableHeader: "Nadpis řádku",
        mergeCells: "Sloučit buňky",
        splitCells: "Rozdělit buňky",
        HorizontalSplit: "Vodorovné rozdělení",
        VerticalSplit: "Svislé rozdělení",
      },
};

export default czechLanguageTranslation;