import React from "react";
import Button from "../../../common/button/Button";
import {Link, useHistory} from "react-router-dom";
import classes from "./CartOrder.module.css";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteConstants} from "../../../routes/RouteConstants";
import {CartStep} from "../../../../store/Reducer";
import {connect} from "react-redux";
import {getDiscountMultiplierByDiscountCode, oneTimeCode} from "../../../../utils/config/config";

interface Props extends WithTranslation {
  totalPrice: number,
  currentStep: number,
  setCurrentCartStep: (currentCartStep: number) => void,
  hasDiscount: boolean,
  discountValue: string
}

const CartOrder = (props: Props) => {
  const {t} = props;
  let history = useHistory();

  const handleClick = () => {
    const nextStep = window.location.hostname === 'localhost' ? props.currentStep + 1 : props.currentStep + 2;
    history.push(`/cart/${nextStep}`)
    props.setCurrentCartStep(nextStep);
  };


  return (
    <div>
      <div className={classes.CartOrderWrapper}>
            <span className={classes.CartOrderTextBox}>
                <Link to={RouteConstants.aboutUs} className={classes.Link}
                >{t("cart.order.back")}</Link>
                <span className={classes.CartTotalPrice}>
                     <span className={classes.Label}>{`${t("cart.order.all")}:`}</span>
                     <span className={classes.Price}>
                         {
                           props.hasDiscount && oneTimeCode(props.discountValue) === 0 ? (props.totalPrice * getDiscountMultiplierByDiscountCode(props.discountValue)).toFixed(2) : (props.totalPrice - oneTimeCode(props.discountValue)) > 0 ? props.totalPrice - oneTimeCode(props.discountValue) : 0
                         }
                       {` ${t("currency")}`}
                     </span>
                  {
                    props.hasDiscount && oneTimeCode(props.discountValue) === 0? <div className={classes.Label}>{t("cart.summary.discount", {value: 100 - (getDiscountMultiplierByDiscountCode(props.discountValue) * 100)})}</div> : null
                  }

                  {
                    oneTimeCode(props.discountValue) !== 0 ? <div className={classes.Label}>{t("cart.summary.extraDiscount", {value: oneTimeCode(props.discountValue)})}</div> : null
                  }
                </span>
            </span>
        <span>
                <Button text={t("cart.order.order")} isEmailButton={false} clickCallBack={handleClick}
                        customStyle={{textTransform: "uppercase"}}/>
            </span>
      </div>
      <div className={classes.CartOrderWrapperMobile}>
        <div style={{marginBottom: "20px"}}>
          <span className={classes.Label}>{t("cart.order.all")}</span>
          <span className={classes.Price}>
                  {
                    props.hasDiscount && oneTimeCode(props.discountValue) === 0 ? (props.totalPrice * getDiscountMultiplierByDiscountCode(props.discountValue)).toFixed(2) : (props.totalPrice - oneTimeCode(props.discountValue)) > 0 ? props.totalPrice - oneTimeCode(props.discountValue) : 0
                  }
            {t("currency")}
                </span>
          {
            props.hasDiscount && oneTimeCode(props.discountValue) === 0? <div className={classes.Label}>{t("cart.summary.discount", {value: 100 - (getDiscountMultiplierByDiscountCode(props.discountValue) * 100)})}</div> : null
          }

          {
            oneTimeCode(props.discountValue) !== 0 ? <div className={classes.Label}>{t("cart.summary.extraDiscount", {value: oneTimeCode(props.discountValue)})}</div> : null
          }
        </div>
        <div>
          <Button text={t("cart.order.order")} isEmailButton={false} clickCallBack={handleClick}
                  customStyle={{
                    display: "block",
                    textTransform: "uppercase",
                    width: "60%",
                    height: "50px",
                    margin: "0 auto"
                  }}/>
        </div>
        <div style={{marginTop: "40px"}}>
          <Link to={RouteConstants.aboutUs} className={classes.Link}
          >{t("cart.order.back")}</Link>
        </div>
      </div>
    </div>
  )

};

const mapStateToProps = state => {
  return {
    hasDiscount: state.hasDiscount,
    discountValue: state.discountValue
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentCartStep: (currentCartStep: number) => dispatch({
      type: CartStep.SET_CART_STEP,
      currentCartStep: currentCartStep
    })
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CartOrder));