// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NewsInfo_NewsInfo__353pV {\n    max-width: 50%;\n    position: relative;\n}\n\n.NewsInfo_NewsForYear__3A3cD {\n    letter-spacing: 3px;\n    padding-top: 200px;\n    color: #000000;\n}\n\n.NewsInfo_Header__1RC5T {\n    color: #000000;\n}\n\n.NewsInfo_Description__1acD4 {\n    margin-bottom: 55px;\n    line-height: 1.7em;\n}\n\n.NewsInfo_Reactive__25nfS{\n    margin-bottom: 100px;\n}\n\n@media all and (max-width: 700px){\n\n    .NewsInfo_ScrollButton__1qUws{\n        display: none;\n    }\n\n    .NewsInfo_NewsInfo__353pV{\n        display: block;\n        margin: 0 auto;\n        text-align: center;\n        max-width: 100%;\n        width: 100%;\n    }\n\n    .NewsInfo_Description__1acD4{\n        display: none;\n    }\n\n    .NewsInfo_Reactive__25nfS{\n        display: block;\n        width: 100%;\n        max-width: 400px;\n        position: fixed;\n        top: 85%;\n        height: 55px;\n        left: 50%;\n        transform: translateX(-50%);\n        text-transform: uppercase;\n    }\n\n    .NewsInfo_Reactive__25nfS button{\n        width: 70%;\n        height: 55px;\n    }\n\n    .NewsInfo_Header__1RC5T{\n        display: block;\n        margin: 0 auto;\n        max-width: 355px;\n        margin-top: 10px;\n    }\n\n    .NewsInfo_NewsForYear__3A3cD{\n        font-size: 10px;\n        padding-top:0px;\n    }\n\n    .NewsInfo_NewsDescription__2K4bz{\n        font-weight: 900;\n        color: #000;\n    }\n}\n\n@media all and (max-width: 360px){\n\n    .NewsInfo_Reactive__25nfS button{\n        height: 50px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"NewsInfo": "NewsInfo_NewsInfo__353pV",
	"NewsForYear": "NewsInfo_NewsForYear__3A3cD",
	"Header": "NewsInfo_Header__1RC5T",
	"Description": "NewsInfo_Description__1acD4",
	"Reactive": "NewsInfo_Reactive__25nfS",
	"ScrollButton": "NewsInfo_ScrollButton__1qUws",
	"NewsDescription": "NewsInfo_NewsDescription__2K4bz"
};
module.exports = exports;
