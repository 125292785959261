// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartShippingAndPayment_heading__Gv9-b{\n    font-weight: bold;\n    text-transform: uppercase;\n    letter-spacing: 3px;\n    font-size:12px;\n    font-family: Gotham-Medium;\n    margin-bottom: 2%;\n}\n", ""]);
// Exports
exports.locals = {
	"heading": "CartShippingAndPayment_heading__Gv9-b"
};
module.exports = exports;
