// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MainProduct_Product__3Kd6A {\n    text-align: center;\n    max-width: 370px;\n    padding-top: 80px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-flow: column nowrap;\n        flex-flow: column nowrap;\n    -ms-flex-pack: start;\n        justify-content: flex-start;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n.MainProduct_Image__qCWPv {\n    width: 370px;\n    height: 470px;\n}\n\n.MainProduct_Product__3Kd6A h3{\n    font-size: 22px;\n    line-height: 26px;\n    max-width: 370px;\n    white-space: pre-line;\n}\n\n.MainProduct_Product__3Kd6A span{\n    font-size: 14px;\n    line-height: 22px;\n    margin: 0 0 40px;\n    width: 100% !important;\n    height: 88px !important;\n    overflow: hidden;\n}\n", ""]);
// Exports
exports.locals = {
	"Product": "MainProduct_Product__3Kd6A",
	"Image": "MainProduct_Image__qCWPv"
};
module.exports = exports;
