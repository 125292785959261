import React from 'react';
import classes from './Modal.module.css';
import EmailInputField from '../emailInputField/EmailInputField';
import closeIcon from './../../../assets/image/close.png';
import {WithTranslation, withTranslation} from 'react-i18next';

interface Props extends WithTranslation {
  show: boolean,
  title: string,
  description: string,
  buttonText: string,
  emailField: boolean,
  closeModal: () => void
}

const Modal: React.FC<Props> = (props: Props) => {

  const customStyles = {
    button: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #000000',
      color: '#000000'
    },
    input: {
      backgroundColor: '#FFFFFF'
    },
    form: {
      marginTop: '30px'
    }
  };

  const {t} = props;

  return (
    <div className={classes.Modal}
         style={{
           transform: props.show ? 'translateY(0)' : 'translateY(-100%)',
           opacity: props.show ? 1 : 0,
           visibility: props.show ? 'visible' : 'collapse'
         }}>
      <div className={classes.InnerSection}>
        <div className={classes.Header}>
          <h4 className={classes.Title}>{props.title}</h4>
          <img src={closeIcon} alt={closeIcon} className={classes.CloseIcon} onClick={() => props.closeModal()}/>
        </div>
        <p className={classes.Description}>
          {props.description}
        </p>
        <hr className={classes.UnderLine}/>
        <EmailInputField buttonText={props.buttonText} customStyles={customStyles}
                         placeHolder={t('footer.emailField')}/>
      </div>
    </div>
  );
};

export default withTranslation()(Modal);