import React, { ChangeEvent, FormEventHandler } from "react";

import CorrectMark from "../../../assets/image/correct.svg";
import Select from "react-select";
import { StringSchema } from "yup";
import classes from "./InputFieldCommon.module.css";

interface Props {
  countries?: { value: string; label: string }[];
  placeHolder: string;
  name?: string;
  label?: string;
  labelSize?: number;
  error?: string;
  notEmpty?: string;
  height?: number;
  width?: number | string;
  state?: State;
  oninput?: FormEventHandler<HTMLInputElement>;
  bgcolor?: string;
  validate?: StringSchema;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  initialValue?: string;
  isMandatory?: boolean;
}

export enum State {
  ERROR,
  SUCCESS,
  EMPTY,
  NOT_EMPTY,
}

class InputFieldCommon extends React.Component<Props> {
  public validationState: State;

  constructor(props: Props) {
    super(props);
    this.validationState = this.props.state ? this.props.state : State.EMPTY;
    if (this.props.initialValue) {
      this.validationState = State.SUCCESS;
    }
  }

  validateMe = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      this.setState({ state: State.EMPTY });
      this.validationState = State.EMPTY;
      this.props.validate &&
        this.props.validate
          .isValid(e.target.value)
          .then(this.changeStateOnEmpty);
    } else {
      this.props.validate &&
        this.props.validate.isValid(e.target.value).then(this.changeState);
    }

    this.props.oninput?.(e);
  };

  validateSelect = (e: any) => {
    if (e.value.length === 0) {
      this.setState({ state: State.EMPTY });
      this.validationState = State.EMPTY;
      this.props.validate &&
        this.props.validate.isValid(e.value).then(this.changeStateOnEmpty);
    } else {
      this.props.validate &&
        this.props.validate.isValid(e.value).then(this.changeState);
    }

    this.props.oninput?.(e);
  };

  changeStateOnEmpty = (valid: boolean) => {
    this.setState({ state: valid ? State.EMPTY : State.NOT_EMPTY });
    this.validationState = valid ? State.EMPTY : State.NOT_EMPTY;
  };

  changeState = (valid: boolean) => {
    this.setState({ state: valid ? State.SUCCESS : State.ERROR });
    this.validationState = valid ? State.SUCCESS : State.ERROR;
  };

  onInputEvent = (e: any) => {
    if (this.props.oninput) this.props.oninput(e);
    this.validateMe(e);
  };

  onSelectInputEvent = (e: any) => {
    if (this.props.oninput) this.props.oninput(e);
    this.validateSelect(e);
  };

  onChangeEvent = (e: any) => {
    if (this.props.name === "phone") {
      var target = e.target,
        position = target.selectionEnd,
        length = target.value.length;
      target.value = target.value
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{3})/g, "$1 ")
        .trim();
      target.selectionEnd = position +=
        target.value.charAt(position - 1) === " " &&
        target.value.charAt(length - 1) === " " &&
        length !== target.value.length
          ? 1
          : 0;
      this.validateMe(e);
    }
    this.props.onChange(e);
    this.validateMe(e);
  };

  onChangeSelectEvent = (e: any) => {
    this.props.onChange(e);
    this.validateSelect(e);
  };


  customStyles = {
    container: (provided) => {
      const width = "100%";

      return { ...provided, width };
    },
    singleValue: (provided) => {
      const width = "100%";

      return { ...provided, width };
    },
    textContainer: (provided) => {
      const fontFamily = "Arial";
      const textAlign = "center";

      return { ...provided, fontFamily, textAlign };
    },
    input: (provided) => {
      const width = "100%";
      const caretColor = "#fff";

      return { ...provided, width, caretColor };
    },
    option: (provided) => {
      const textAlign = "center";
      const fontStyle = "normal";
      const fontFamily = "Arial";

      return { ...provided, textAlign, fontStyle, fontFamily };
    },
    valueContainer: (provided) => {
      const width = "100%";
      const textAlign = "center";
      const fontFamily = "Arial";

      return { ...provided, width, textAlign, fontFamily };
    },
    control: (provided) => {
      const borderRadius = "30px";
      const width = "100%";
      const height = "52px";
      const border = "none";

      return { ...provided, borderRadius, border, height, width };
    },
  };

  render() {
    const inlineStyle = {
      height:
        typeof this.props.height === "string"
          ? this.props.height + "%"
          : this.props.height,
      width:
        typeof this.props.width === "string"
          ? this.props.width + "%"
          : this.props.width,
    };

    return (
      <div
        className={
          this.props.isMandatory && this.props.isMandatory === true
            ? `${classes.InputFieldCommon} ${classes.Mandatory}`
            : classes.InputFieldCommon
        }
      >
        {this.props.label && (
          <label style={{ fontSize: this.props.labelSize || "16px" }}>
            {this.props.label}:
          </label>
        )}
        <div
          className={
            this.validationState === State.ERROR ||
            this.validationState === State.NOT_EMPTY
              ? classes.Error
              : this.validationState === State.SUCCESS
              ? classes.Success
              : ""
          }
          style={{ backgroundColor: this.props.bgcolor }}
        >
          <div
            className={classes.TextContainer}
            style={
              this.props.height && this.props.width ? inlineStyle : undefined
            }
          >
            {this.props.countries ? (
              <Select
                noOptionsMessage={() => ""}
                className={classes.TextContainer}
                placeholder={this.props.placeHolder}
                defaultInputValue={this.props.initialValue}
                name={this.props.name ? this.props.name : "ahoj"}
                onChange={(e) => this.onChangeSelectEvent(e)}
                onInput={(e) => this.onSelectInputEvent(e)}
                options={this.props.countries}
                styles={this.customStyles}
              />
            ) : (
              <input
                style={{ width: "100%" }}
                name={this.props.name ? this.props.name : "ahoj"}
                type={this.props.type ? this.props.type : ""}
                placeholder={this.props.placeHolder}
                onInput={(e) => this.onInputEvent(e)}
                onChange={(e) => this.onChangeEvent(e)}
                defaultValue={this.props.initialValue}
              />
            )}
          </div>
          {this.validationState === State.SUCCESS && (
            <img
              className={
                this.props.name === "country"
                  ? `${classes.CorrectMarkSelect}`
                  : `${classes.CorrectMark}`
              }
              alt="logo"
              src={CorrectMark}
            />
          )}
        </div>
        {this.props.error && this.validationState === State.ERROR && (
          <i>{this.props.error}</i>
        )}
        {this.props.notEmpty && this.validationState === State.NOT_EMPTY && (
          <i>{this.props.notEmpty}</i>
        )}
      </div>
    );
  }
}

export default InputFieldCommon;
