// @ts-ignore
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "./styles.scss";

import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  returnCzechDateFormat,
  returnDateFormatFromCzechDate,
} from "../functions";

import { ArticlePreview } from "../changeArticleComponents/ArticlePreview";
import { BlogUrlList } from "../blogUrlList";
import Button from "../../../../common/button/Button";
import DOMPurify from "dompurify";
import LoadingPage from "../../../../common/loadingPage/LoadingPage";
import { PublicationDateOption } from "../changeArticleComponents/PublicationDateOption";
import { RouteComponentProps } from "react-router-dom";
import { TextEditor } from "../changeArticleComponents/TextEditor";
import { TitleDescriptionAndUpload } from "../changeArticleComponents/titleDescriptionAndUpload";
import axios from "axios";
import classes from "./EditArticle.module.css";
import { connect } from "react-redux";

interface MatchParams {
  id: string;
}

interface Props extends WithTranslation, RouteComponentProps<MatchParams> {
  isAuthenticated?: boolean;
}

const EditArticle: React.FC<Props> = (props) => {
  const { t } = props;

  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [text, setText] = useState("");
  const [imageData, setImageData] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [publicationDate, setPublicationDate] = useState("");

  const handleChange = (content): void => {
    setText(content);
  };

  const originalDate: string = returnCzechDateFormat(publicationDate);

  const required = true;

  useEffect((): void => {
    if (!props.isAuthenticated) {
      props.history.push("/");
    }

    window.scrollTo(0, 0);
    const fetchData = () => {
      axios
        .get(BlogUrlList.fullData, {
          params: { id: props.match.params.id },
        })
        .then((res) => {
          let loadedArticle = res.data;
          setTitle(loadedArticle.article.title);
          setDescription(loadedArticle.article.description);
          setText(loadedArticle.article.htmlText);
          setTitle(loadedArticle.article.title);
          setImageData(loadedArticle.article.imageData);
          setPublicationDate(
            returnCzechDateFormat(loadedArticle.article.publicationDate)
          );
          setLoaded(true);
        });
    };
    fetchData();
  }, [props.history, props.isAuthenticated, props.match.params.id]);

  const handleReaderLoaded = (readersetImageEvt): void => {
    let binaryString: string = readersetImageEvt.target.result;
    setImageData(`data:image/jpeg;base64,${btoa(binaryString)}`);
  };
  const fileUpload = (event): void => {
    let file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      // @ts-ignore
      reader.onload = handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const htmlText: string = `${text}`;
  const mySafeHTML: string = DOMPurify.sanitize(htmlText);

  const publishUpdatedArticle = (): void => {
    axios
      .post(BlogUrlList.update, {
        id: props.match.params.id,
        title: title,
        description: description,
        publicationDate: String(returnDateFormatFromCzechDate(publicationDate)),
        imageData: imageData,
        htmlText: htmlText,
      })
      .then((res) => {
        res.status === 200
          ? alert(t("blog.updatedSuccessfully"))
          : alert(t("blog.updatedUnsuccessfully"));
        props.history.push("/blog");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.divMargin}>
      {loaded ? (
        <>
          <TitleDescriptionAndUpload
            classes={classes}
            imageData={imageData}
            required={required}
            title={title}
            description={description}
            fileUpload={fileUpload}
            setTitle={setTitle}
            setDescription={setDescription}
            t={t}
          />
          <TextEditor handleChange={handleChange} text={text} t={t} />
          <PublicationDateOption
            setPublicationDate={setPublicationDate}
            originalDate={originalDate}
            classes={classes}
            t={t}
            doNotChangeOption={true}
          />
          <div style={{ margin: "2rem" }}>
            <Button
              text={t("blog.publish")}
              isEmailButton={false}
              clickCallBack={() => publishUpdatedArticle()}
            />
          </div>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              id="showPreview"
              onChange={() => setShowPreview((previous) => !previous)}
              name="publication_option"
            />
            <h2
              className={classes.PreviewTitle}
              style={{ marginBottom: "2rem" }}
            >
              {t("blog.preview")}
            </h2>
          </div>
          {showPreview ? (
            <ArticlePreview
              title={title}
              description={description}
              publicationDate={publicationDate}
              mySafeHTML={mySafeHTML}
              imageData={imageData}
              t={t}
              classes={classes}
            />
          ) : (
            <LoadingPage show={!loaded} />
          )}{" "}
        </>
      ) : (
        <LoadingPage show={!loaded} />
      )}
    </div>
  );
};

const mapStateToProps = (state: { isAuthenticated: boolean }) => {
  return {
    isAuthenticated: state.isAuthenticated,
  };
};

export default withTranslation()(connect(mapStateToProps, null)(EditArticle));
