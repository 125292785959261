import ProductClass, {ProductCategory} from "../../templateObjects/product/ProductClass";

import samponOmega369 from '../../assets/image/Sampon_na_vlasy_s_Omega369_retina.jpg';
import detskyKremNaOpruzeniny from '../../assets/image/Detsky_krem_na_opruzeniny_Omega369_retina.jpg';
import balzamNaRty from '../../assets/image/Balzam_na_rty_Omega369_retina.jpg';
import olejSOmega from '../../assets/image/Hypoalergenni_sprchovy_olej_s_Omega369_retina.jpg';

import samponOmegaCartIcon from '../../assets/image/cartIcons/Sampon_na_vlasy_s_Omega369_retina.jpg';
import balzamNaRtyOmegaCartIcon from '../../assets/image/cartIcons/Balzam_na_rty_Omega369_retina.jpg';
import sprchovyOlejOmegaCartIcon from '../../assets/image/cartIcons/Hypoalergenni_sprchovy_olej_s_Omega369_retina.jpg';
import detskyKremOmegaCartIcon from '../../assets/image/cartIcons/Detsky_krem_na_opruzeniny_Omega369_retina.jpg';



const product1 = new ProductClass(24981,
    'products.omega.product1.name', 249, 'products.omega.product1.description',
  [samponOmega369],samponOmegaCartIcon, undefined, undefined, false, 1,
    ProductCategory.OMEGA, 'products.omega.product1.volume', 'products.omega.product1.secondTitle',
    'products.omega.product1.description2', 'products.omega.product1.keyWords', 'products.omega.product1.whyMe',
  'products.omega.product1.usage', 'products.omega.product1.ingredients', 'products.omega.product1.extraIngredients',
    'products.omega.product1.createdAt', undefined, undefined);

const product2 = new ProductClass(24980,'products.omega.product2.name', 249, 'products.omega.product2.description',
  [olejSOmega],sprchovyOlejOmegaCartIcon, undefined, undefined, false, 1,
    ProductCategory.OMEGA, 'products.omega.product2.volume', 'products.omega.product2.secondTitle',
    'products.omega.product2.description2', 'products.omega.product2.keyWords', 'products.omega.product2.whyMe',
  'products.omega.product2.usage', 'products.omega.product2.ingredients', 'products.omega.product2.extraIngredients',
  'products.omega.product2.createdAt',undefined, undefined);

const product3 = new ProductClass(24982,'products.omega.product3.name', 229, 'products.omega.product3.description',
    [detskyKremNaOpruzeniny],detskyKremOmegaCartIcon, undefined, undefined, false, 1,
    ProductCategory.OMEGA, 'products.omega.product3.volume', 'products.omega.product3.secondTitle',
    'products.omega.product3.description2', 'products.omega.product3.keyWords', 'products.omega.product3.whyMe',
    'products.omega.product3.usage', 'products.omega.product3.ingredients', 'products.omega.product3.extraIngredients',
    'products.omega.product3.createdAt',undefined, undefined);

const product4 = new ProductClass(24983,'products.omega.product4.name', 90, 'products.omega.product4.description',
    [balzamNaRty],balzamNaRtyOmegaCartIcon, undefined, undefined, false, 1,
    ProductCategory.OMEGA, 'products.omega.product4.volume', 'products.omega.product4.secondTitle',
    'products.omega.product4.description2', 'products.omega.product4.keyWords', 'products.omega.product4.whyMe',
    'products.omega.product4.usage', 'products.omega.product4.ingredients', 'products.omega.product4.extraIngredients',
    'products.omega.product4.createdAt',undefined, undefined);

const omegaProducts = [
  product1,
  product2,
  product3,
  product4
];

export default omegaProducts;