import ProductClass, {ChangeAmountEnum, ProductActions} from "../templateObjects/product/ProductClass";
import {AuthenticationEnum} from '../templateObjects/authentication/Authentication';
import {UserDetails, UserDetailsEnum} from "../templateObjects/userDetails/UserDetails";
import {Location, LocationActions} from '../templateObjects/location/Location';


interface State {
    cartItems: Array<ProductClass>,
    totalPriceOfCart: number,
    shippingPrice: number,
    paymentPrice: number,
    isAuthenticated: boolean,
    userDetails: UserDetails,
    location: Location,
    currentCartStep: number,
    shippingMethod: string,
    paymentType: number,
    shippingType: string,
    packetaId: number,
    packetaInfo: string,
    hasDiscount: boolean,
    discountValue: string
}

type ActionObject = {
    type: ChangeAmountEnum | ProductActions | AuthenticationEnum | UserDetailsEnum | LocationActions | CartStep,
    product: ProductClass,
    isAuthenticated: boolean,
    paymentAndShippingAdjustment: number,
    paymentPrice: number,
    paymentType: number,
    shippingPrice: number,
    userDetails: UserDetails,
    location: Location,
    currentCartStep: number,
    shippingMethod: string,
    shippingType: string,
    packetaId: number,
    packetaInfo: string,
    hasDiscount: boolean,
    discountValue: string
}

export enum CartStep {
  SET_CART_STEP = 'SET_CART_STEP'
}

const initialState: State = {
    cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems') as string) : [],
    totalPriceOfCart: localStorage.getItem('totalPriceOfCart') ? parseInt(localStorage.getItem('totalPriceOfCart') as string) : 0,
    shippingPrice: localStorage.getItem("shippingPrice") ? parseInt(localStorage.getItem("shippingPrice") as string) : 0,
    paymentPrice: localStorage.getItem("paymentPrice") ? parseInt(localStorage.getItem("paymentPrice") as string) : 0,
    paymentType: localStorage.getItem("paymentType") ?  parseInt(localStorage.getItem("paymentType") as string) : 0,
    isAuthenticated: localStorage.getItem('isAuthenticated') ? localStorage.getItem('isAuthenticated') as string === 'true' : false,
    userDetails: localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails") as string) : {},
    location: Location.COMMON,
    currentCartStep: localStorage.getItem("currentCartStep") ? +(JSON.parse(localStorage.getItem("currentCartStep") as string)) : 1,
    shippingMethod: localStorage.getItem('shippingMethod') ? localStorage.getItem('shippingMethod') as string : "",
    shippingType: localStorage.getItem('shippingType') ? localStorage.getItem('shippingType') as string : "",
    packetaId: localStorage.getItem("packetaId") ? +(JSON.parse(localStorage.getItem("packetaId") as string)) : 0,
    packetaInfo: localStorage.getItem('packetaInfo') ? localStorage.getItem('packetaInfo') as string : "",
    hasDiscount: localStorage.getItem('hasDiscount') ? localStorage.getItem('hasDiscount') as string === 'true' : false,
    discountValue: localStorage.getItem('discountValue') ? localStorage.getItem('discountValue') as string : ""
};

const calculateTotalPrice = (cartItems: Array<ProductClass>) => {
  let price = 0;
  cartItems.forEach((product) => {
    if (product.price) {
      price += (product.price * product.amount)
    }
  });
  return price;
};

const saveCartInLocalStorage = (cartItems: Array<ProductClass>): number => {
  const totalPrice = calculateTotalPrice(cartItems);
  localStorage.setItem('totalPriceOfCart', totalPrice.toString());
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
  return totalPrice;
};

const increaseAmountIfProductIsInCart = (product: ProductClass,cartInsert: Array<ProductClass>):boolean =>{
  const nameOfProducts = cartInsert.map(a => a.name);
  const index: number = nameOfProducts.indexOf(product.name);
  if(index === -1){
    return false;
  }else{
    cartInsert[index].amount+=product.amount;
    cartInsert[index].totalPriceByAmount = recalculateTotalPriceByAmount(cartInsert[index].price, cartInsert[index].amount);
    return true;
  }
}


const recalculateTotalPriceByAmount = (price: number, amount: number): number => {
  return price * amount;
};

const rootReducer = (state = initialState, action: ActionObject): State => {
  switch (action.type) {
    case ChangeAmountEnum.INCREMENT:
      let newProductsInc = state.cartItems.slice();
      if (state.cartItems && state.cartItems.length > 0) {
        const index = state.cartItems.indexOf(action.product);
        newProductsInc[index].amount++;
        newProductsInc[index].totalPriceByAmount = recalculateTotalPriceByAmount(newProductsInc[index].price, newProductsInc[index].amount)
      }
      const totalPrice = saveCartInLocalStorage(newProductsInc);
      return {
        ...state,
        cartItems: newProductsInc,
        totalPriceOfCart: totalPrice
      };
    case ChangeAmountEnum.DECREMENT:
      let newProductsDec = state.cartItems.slice();
      if (state.cartItems && state.cartItems.length > 0) {
        const index = state.cartItems.indexOf(action.product);
        newProductsDec[index].amount--;
        newProductsDec[index].totalPriceByAmount = recalculateTotalPriceByAmount(newProductsDec[index].price, newProductsDec[index].amount)
      }
      const totalPrice2 = saveCartInLocalStorage(newProductsDec);
      return {
        ...state,
        cartItems: newProductsDec,
        totalPriceOfCart: totalPrice2
      };
    case ProductActions.ADD_TO_CART:
      let cartInsert = state.cartItems.slice();
      if(!increaseAmountIfProductIsInCart(action.product,cartInsert)){
        action.product.totalPriceByAmount = recalculateTotalPriceByAmount(action.product.price, action.product.amount);
        cartInsert.push(action.product);
      }
      const totalPrice3 = saveCartInLocalStorage(cartInsert);
      return {
        ...state,
        cartItems: cartInsert,
        totalPriceOfCart: totalPrice3
      };
    case ProductActions.REMOVE_FROM_CART:
      let cartRemove = state.cartItems.slice();
      cartRemove.splice(cartRemove.indexOf(action.product), 1);
      const totalPrice4 = saveCartInLocalStorage(cartRemove);
      return {
        ...state,
        cartItems: cartRemove,
        totalPriceOfCart: totalPrice4
      };
    case ProductActions.SET_PAYMENT:
          localStorage.setItem("paymentPrice", action.paymentPrice.toString());
          return {
              ...state,
              paymentPrice: action.paymentPrice
          }
    case ProductActions.SET_PAYMENT_TYPE:
      localStorage.setItem("paymentType", action.paymentType.toString());
      return {
        ...state,
        paymentType: action.paymentType
      }
    case ProductActions.SET_SHIPPING:
          localStorage.setItem("shippingPrice", action.shippingPrice.toString());
          return {
              ...state,
              shippingPrice: action.shippingPrice
          }
    case ProductActions.DELETE_CART:
      const ttPrice = saveCartInLocalStorage([]);
      //not setting it right away, because of staying on last page when order submitted
      localStorage.setItem("currentCartStep", '1');

      localStorage.setItem("shippingPrice", '0');
      localStorage.setItem("paymentPrice", '0');
      localStorage.setItem("hasDiscount", 'false');
      localStorage.setItem("discountValue", '');

      return {
        ...state,
        cartItems: [],
        totalPriceOfCart: ttPrice,
        shippingPrice: 0,
        paymentPrice: 0,
        hasDiscount: false,
        discountValue: ''
      };
    case ProductActions.SET_SHIPPING_METHOD:
            localStorage.setItem("shippingMethod", action.shippingMethod.toString());
            return {
                ...state,
                shippingMethod: action.shippingMethod
            }
    case ProductActions.SET_SHIPPING_TYPE:
      localStorage.setItem("shippingType", action.shippingType.toString());
      return {
        ...state,
        shippingType: action.shippingType
      }
    case ProductActions.SET_PACKETA_DATA:
        localStorage.setItem('packetaId', action.packetaId.toString());
      localStorage.setItem('packetaInfo', action.packetaInfo.toString());
        return {
          ...state,
          packetaId: action.packetaId,
          packetaInfo: action.packetaInfo
        };
    case AuthenticationEnum.AUTHORIZATION:
      localStorage.setItem('isAuthenticated', action.isAuthenticated.toString());
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      };
    case ProductActions.SET_DISCOUNT:
      localStorage.setItem('hasDiscount', action.hasDiscount.toString());
      return {
        ...state,
        hasDiscount: action.hasDiscount
      };
    case ProductActions.SET_DISCOUNT_VALUE:
      localStorage.setItem('discountValue', action.discountValue);
      return {
        ...state,
        discountValue: action.discountValue
      };
    case UserDetailsEnum.SET_USER_DETAILS:
          localStorage.setItem("userDetails", JSON.stringify(action.userDetails));
          return {
              ...state,
              userDetails: action.userDetails
          }
    case LocationActions.SET_LOCATION:
          return {
            ...state,
            location: action.location
          }
    case CartStep.SET_CART_STEP:
      localStorage.setItem("currentCartStep", action.currentCartStep.toString());
      return {
        ...state,
        currentCartStep: action.currentCartStep
      };
    default:
      return state;
  }
};

export default rootReducer;