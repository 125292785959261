// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BlogArticle_Title__2m7Zu {\n    font-family: Gotham-Medium;\n    font-size: 3rem;\n    font-weight: 700;\n    margin: 0;\n}\n.BlogArticle_BlogArticle__1BYoN {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    box-sizing: border-box;\n    width: 100%;\n    max-width: 1270px;\n    margin: 0 auto;\n    padding: 0 50px;\n}\n.BlogArticle_Date__2-IRw {\n    padding-right: 5px;\n}\n\n@media all and (max-width: 800px) {\n    .BlogArticle_Date__2-IRw {\n        text-align: center;\n    }\n    .BlogArticle_Title__2m7Zu {\n        font-size: 2rem;\n        font-weight: 700;\n        text-align: center;\n        line-height: 1;\n    } \n}\n\n@media all and (max-width: 500px) {  \n    .BlogArticle_Date__2-IRw {\n        text-align: center;\n    }\n    .BlogArticle_Title__2m7Zu {\n        font-size: 1.5rem;\n        font-weight: 700;\n        text-align: center;\n        margin-top: 4rem;\n        line-height: 1;\n    } \n}", ""]);
// Exports
exports.locals = {
	"Title": "BlogArticle_Title__2m7Zu",
	"BlogArticle": "BlogArticle_BlogArticle__1BYoN",
	"Date": "BlogArticle_Date__2-IRw"
};
module.exports = exports;
