// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartSelectableComponent_CartSelectableComponentWrapper__1ixMC{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    -ms-flex-align: baseline;\n        align-items: baseline;\n}\n\n.CartSelectableComponent_CartSelectableComponentPrice__3kPrF{\n    text-decoration: none;\n    font-weight: bold;\n    display: inline-block;\n    font-size: 15px;\n}\n\n.CartSelectableComponent_Divider__BZ1Zn {\n    display: none;\n}\n\n.CartSelectableComponent_Divider__BZ1Zn hr{\n    background-color:rgb(201, 201, 201);\n}\n\n@media all and (max-width: 500px) {\n\n    .CartSelectableComponent_Divider__BZ1Zn {\n        display: block;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"CartSelectableComponentWrapper": "CartSelectableComponent_CartSelectableComponentWrapper__1ixMC",
	"CartSelectableComponentPrice": "CartSelectableComponent_CartSelectableComponentPrice__3kPrF",
	"Divider": "CartSelectableComponent_Divider__BZ1Zn"
};
module.exports = exports;
