import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import ComboBox from "../../../common/combobox/ComboBox";
import classes from './ProductsFilter.module.css';
import Checkbox from '../../../common/checkbox/Checkbox';

type MyState = {
  width: number,
  height: number,
};

interface MyProps extends WithTranslation {
  viewList: Array<string>,
  onViewChange: (value: string) => void,
  onHideLabelChange: () => void
  onOrderByChange: (value: string) => void
  sectionList: Array<string>
  initFilter: string
}

class ProductsFilter extends React.Component<MyProps,MyState> {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const {t} = this.props;
    const firstCombobox = {
      id: 'showProducts',
      optionsValue: ['all', ...this.props.sectionList],
      optionsName: [t('products.productsFilter.all'), ...this.props.viewList]
    };

    const secondCombobox = {
      id: 'filterBy',
      optionsValue: ['none', 'favorite', 'lowerPrice', 'upperPrice', 'nameA', 'nameZ'],
      optionsName: ['-', t('products.filter.favorite'), t('products.filter.lowerPrice'),
        t('products.filter.upperPrice'), t('products.filter.nameA'), t('products.filter.nameZ')]
    };

    if(this.state.width <= 700){
      secondCombobox.optionsName[0] = t('products.filter.filterBy');
    }

    const filterProps = this.props.initFilter === "all" ? {
      placeHolder:t('products.productsFilter.show'),
      placeHolderMaxRes:700
    } : {
      initIndex:this.props.sectionList.indexOf(this.props.initFilter)+1
    }
  

    return (
      <div className={classes.ProductsFilter}>
        <div className={classes.Container}>
          <label className={classes.Label}>{t('products.productsFilter.show')}</label>
           <ComboBox id={firstCombobox.id} optionsValue={firstCombobox.optionsValue}
                    optionsName={firstCombobox.optionsName} onSelect={this.props.onViewChange} {...filterProps}/>
          <label className={classes.Label}>{t('products.filter.filterBy')}</label>
          <ComboBox id={secondCombobox.id} optionsValue={secondCombobox.optionsValue}
                    optionsName={secondCombobox.optionsName} onSelect={this.props.onOrderByChange}/>
          <span className={classes.RowLabels}>
            <Checkbox text={t('products.checkboxText')} onClick={this.props.onHideLabelChange}/>
          </span>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ProductsFilter);