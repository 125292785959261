import i18n from "./i18n";
import {EN, LOCAL_STORAGE_LANG} from "./LanguageConstants";

const lngChange = (lng: string): void => {
  if (i18n.language === EN) {
    localStorage.setItem(LOCAL_STORAGE_LANG, lng);
    i18n.changeLanguage(lng);
  } else {
    localStorage.setItem(LOCAL_STORAGE_LANG, lng);
    i18n.changeLanguage(lng);
  }
  //TODO - finishe also other langueages
};

export default lngChange;