// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Select_CustomSelect__2lVAN {\n  color: black;\n  border-radius: 35px;\n  font-size: 16px;\n  font-family: 'Gotham-Light';\n  font-weight: bold;\n  appearance: none;\n  cursor: pointer;\n  height: 52px;\n  width: 52px;\n  padding-left: 17px;\n  -webkit-padding-start: 21px; }\n\n@media screen and (min-width: 1024px) {\n  .Select_CustomSelect__2lVAN {\n    display: none; } }\n", ""]);
// Exports
exports.locals = {
	"CustomSelect": "Select_CustomSelect__2lVAN"
};
module.exports = exports;
