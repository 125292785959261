export const RouteConstants = {
  home: '/',
  aboutUs: '/aboutUs',
  contact: '/contact',
  error: '/error',
  products: '/products',
  services: '/services',
  signin: '/cc0256df40cbc924af2b31aeccb869b0',
  password: '/password',
  registration: '/register',
  personalTerms: '/personalTerms',
  productReturn: '/productReturn',
  terms: '/terms',
  faq: '/faq',
  productDetail: '/detail/:id',
  userDetails: '/userDetails',
  cart: "/cart/:step",
  blog: "/blog",
  blogEditing: "/blog-editing",
  blogArticle: "/blog/:id",
  createArticle: "/blog/create-article",
  editArticle: "/blog/edit/:id",
};