import React from "react";
import classes from "./UserDetails.module.css";
import Button from "../../../common/button/Button";
import {WithTranslation, withTranslation} from "react-i18next";
import {Link, RouteComponentProps} from "react-router-dom";
import {RouteConstants} from "../../../routes/RouteConstants";
/* import PersonalFormComponent from "../../pageArtefacts/personalFormComponent/PersonalFormComponent";
import PaymentInformationComponent from "../../pageArtefacts/paymentInfoComponent/PaymentInformationComponent";
import OrderHistory from "../../pageArtefacts/orderHistoryComponent/OrderHistoryComponent";
import NewsletterForm from "../../pageArtefacts/newsletterComponent/NewsletterFormComponent";
import AccountDelete from "../../pageArtefacts/accountDeleteComponent/AccountDeleteComponent";
import AccessFormComponent from "../../pageArtefacts/accessFormComponent/AccessFormComponent"; */
import {connect} from "react-redux";
import {AuthenticationEnum} from "../../../../templateObjects/authentication/Authentication";

interface State {
    visible: number
}

interface Props extends RouteComponentProps, WithTranslation {
    logOff: (isAuth: boolean) => void
}

class UserDetails extends React.Component<Props, State> {
    state = {
        visible: -1
    };

    render() {
        const {t} = this.props;

/*         const OPTIONS = [
            'userDetails.personal.title',
            'userDetails.access.title',
            'userDetails.payment.title',
            'userDetails.history.title',
            'userDetails.newsletter.title',
            'userDetails.cancelAcc.title'
        ];

        const COMPONENTS = [
            <PersonalFormComponent/>,
            <AccessFormComponent/>,
            <PaymentInformationComponent/>,
            <OrderHistory/>,
            <NewsletterForm/>,
            <AccountDelete/>
        ]; */

        const logOff = (isAuth: boolean) => {
            this.props.logOff(isAuth);
            this.props.history.replace('/aboutUs');
            document.documentElement.scrollTop = 0;
        };

        return (

            <div style={{backgroundColor: "#F6EBE7", paddingTop: "30px"}}>
                <div className={classes.UserDetails}>
                    <h1 className={classes.Title}>{t('userDetails.myAcc')}</h1>
                    <hr className={classes.UnderLine}/>
                    {/*<DropDownBox titles={OPTIONS}></DropDownBox>*/}

                    <div className={classes.DownOptions}>
                        <h5 className={classes.BackLink}><Link to={RouteConstants.products} style={{
                            color: '#000000',
                            textDecoration: 'none'
                        }}>{t('userDetails.backToShop').toLocaleUpperCase()}</Link></h5>
                        <Button text={t('userDetails.logOff').toLocaleUpperCase()} isEmailButton={false}
                                clickCallBack={() => {
                                    logOff(false)
                                }}/>
                    </div>
                </div>
            </div>
        );
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logOff: (isAuth: boolean) => dispatch({type: AuthenticationEnum.AUTHORIZATION, isAuthenticated: isAuth})
    }
};

export default withTranslation()(connect(null, mapDispatchToProps)(UserDetails));