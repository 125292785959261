import React from "react";
import classes from './ProductsContent.module.css';
import ProductClass from '../../../../templateObjects/product/ProductClass';
import image from "../../../../assets/image/close.png"
import ShowMoreText from 'react-show-more-text';
import {withTranslation, WithTranslation} from "react-i18next";
import loadable from "@loadable/component";
const OneProductContent = loadable(() => import("./OneProductContent"));

interface Props extends WithTranslation {
  sectionDivId?: string,
  title?: string,
  description?: string,
  motto?: string,
  products: ProductClass[],
  hideLabel: boolean,
  orderBy: string,
  openCart: () => void
}

const ProductsContent: React.FC<Props> = (props: Props) => {

  const {t} = props;

  const sort = (): Array<ProductClass> => {
    let products = [...props.products];
    switch (props.orderBy) {
      case 'none': return products;
      case 'lowerPrice': return products.sort((a:any, b:any) => a.price > b.price? 1 : -1);
      case 'upperPrice': return products.sort((a:any, b:any) => a.price < b.price? 1 : -1);
      case 'nameA': return products.sort((a:any, b:any) => t(a.name).localeCompare(t(b.name),props.i18n.language));
      case 'nameZ': return products.sort((a:any, b:any) => t(b.name).localeCompare(t(a.name),props.i18n.language));
    }
    return products;
  }

  return (
    <div id={props.sectionDivId} className={classes.ProductContent}>
      {!props.hideLabel &&
      <React.Fragment>
          <h1 className={classes.ProductContentHeading} id={"accordion"}>{props.title}</h1>
          <p className={classes.Motto}>{props.motto}</p>
          <div className={classes.DescriptionMobile}>
              <ShowMoreText
                  lines={4}
                  more={<div className={classes.ShowMore}><p>{t("products.showMore")}</p><img src={image} alt="showMore"/></div>}
                  less={<div className={classes.ShowMore}><p>{t("products.showLess")}</p></div>}
                  expanded={false}
                  anchorClass={classes.AnchorClass}
              >
                  <p className={classes.DescriptionMobile}>{props.description}</p>
              </ShowMoreText>
          </div>
          <p className={classes.Description}>{props.description}</p>
      </React.Fragment>}
      <div className={props.products.length === 2 ? classes.TwoProductsOnly : classes.Products}>
        {
          sort().map((product: ProductClass, index: number) => {
            return <OneProductContent openCart={props.openCart} product={product} index={index} key={index} fallback={<div style={{width: "370px", height: "400px",display:'flex',alignItems:'center',justifyContent: 'center' }}>{t('productsContent.loading')}</div>}/>
          })
        }
      </div>
    </div>
  )
};

export default withTranslation()(ProductsContent);