import React from "react";
import InputFieldCommon from "../../../common/inputField/InputFieldCommon";
import classes from './RegistrationForm.module.css';
import {withTranslation, WithTranslation} from "react-i18next";
import RadioButtonsFields from "../../../common/radioButton/RadioButtonsFields";
import Checkbox from "../../../common/checkbox/Checkbox";
import Button from "../../../common/button/Button";
import ComboBox from "../../../common/combobox/ComboBox";

interface Props extends WithTranslation {
    //onClick: (data: any) => void
}

const RegistrationForm: React.FC<Props> = (props: Props) => {
    const yup = require('yup');
    const {t} = props;

    //const LEFT_COLUMN = [t('registration.name'), t('registration.surname'), t('registration.company'), t('registration.address')];
    //const RIGHT_COLUMN = [t('registration.zip'), t('registration.city'), t('registration.country'), t('registration.phone')];

    const firstCombobox = {
        id: 'country',
        //TODO: Countries
        optionsName: ['země','Česká republika', 'Slovensko', 'Libanon'],
        optionsValue: ['','CS', 'SK', 'LB']
    };

    const BOTTOM_FIELDS = [
        "name",
        "surname",
        "company",
        "address",
        "zip",
        "city",
    ];

    const UPPER_FIELDS_NAMES = [
        "name",
        "surname",
        "email",
        "password"
    ];
    const UPPER_FIELDS_TYPES = [
        "",
        "",
        "email",
        "password"
    ];


    const BG_COLOR = '#D9D9D9';

    const MAX_NAME = 128;
    const MIN_NAME = 1;

    const VALIDATIONS = {
        name: yup.string().required().max(MAX_NAME).min(MIN_NAME),
        surname: yup.string().required().min(MIN_NAME),
        email: yup.string().email().required(),
        password: yup.string().required().min(MIN_NAME).max(MAX_NAME),
        company: yup.string().notRequired().min(MIN_NAME),
        address: yup.string().required().min(MIN_NAME),
        city: yup.string().required().max(MAX_NAME).min(MIN_NAME),
        zip: yup.number().required().positive(),
        country: yup.string().required().max(MAX_NAME).min(MIN_NAME),
        phone: yup.number().required().positive()
    };

    const inlineStyle = {
        width: 240,
        height: 50
    };

    return (
        <form className={classes.RegistrationForm}>
            <RadioButtonsFields title={t('registration.radioBtnTitle')} name="gender"
                                texts={[t('registration.male'), t('registration.female')]}/>
            <div className={classes.InnerPart}>
                <div className={classes.UpperContainer}>
                        {
                            [0,1,2,3].map((indexOfFields, index: number)=>
                                <InputFieldCommon bgcolor={BG_COLOR} width={inlineStyle.width} height={inlineStyle.height} key={index}
                                error={t("userDetails.error")}
                                onChange={() => {
                                }} notEmpty={t("errorEmpty")} placeHolder={t("registration." + UPPER_FIELDS_NAMES[indexOfFields])}
                                validate={VALIDATIONS[UPPER_FIELDS_NAMES[indexOfFields]]}
                                type={UPPER_FIELDS_TYPES[indexOfFields]}/>
                            )
                        }                           
                </div>
                <p className={classes.Contacts}>{t('registration.contacts')}</p>
                <div className={classes.UpperContainer}>
                    {BOTTOM_FIELDS.map((value: string, index: number) =>
                        <InputFieldCommon bgcolor={BG_COLOR} width={inlineStyle.width} height={inlineStyle.height} key={index}
                                          error={t("userDetails.error")}
                                          onChange={() => {
                                          }} notEmpty={t("errorEmpty")} placeHolder={t("registration." + value)}
                                          validate={VALIDATIONS[value]}/>
                    )}
                    <div className={classes.CountrySelect}>
                        <ComboBox id={firstCombobox.id} optionsName={firstCombobox.optionsName} optionsValue={firstCombobox.optionsValue}
                                  onSelect={()=> {}}/>
                    </div>
                    <InputFieldCommon bgcolor={BG_COLOR} width={inlineStyle.width} height={inlineStyle.height}
                                          error={t("userDetails.error")}
                                          onChange={() => {
                                          }} notEmpty={t("errorEmpty")} placeHolder={t('registration.phone')}
                                          validate={VALIDATIONS['phone']}/>
                </div>
            </div>
            <div className={classes.CheckboxContainer}>
                <Checkbox text={t("registration.agreement")}/>
            </div>
            <Button clickCallBack={() => {
            } /*handleSubmit*/} text={t("registration.submit")} isEmailButton={false} width={inlineStyle.width} height={inlineStyle.height}/>
        </form>
    )
};

export default withTranslation()(RegistrationForm);