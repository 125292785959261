import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import ProductsFilter from "../../pageArtefacts/productsFilter/ProductsFilter";
import ProductsContent from "../../pageArtefacts/productsContent/ProductsContent";
// @ts-ignore
// @ts-ignore
import RecommendedProducts from '../../../../data/products/RecommendedProducts';
import ProductClass from "../../../../templateObjects/product/ProductClass";

interface State {
  productContainers: Array<ProductContainer>,
  viewList: Array<string>,
  sectionList: Array<string>,
  hideLabel: boolean,
  orderBy: string
}

interface ProductContainer {
  sectionDivId: string,
  title: string,
  motto: string,
  description: string,
  products: Array<ProductClass>
}

interface Props extends WithTranslation {
  openCart: () => void
}

class ProductsPage extends React.Component<Props, State> {

  static PRODUCT_CONTAINERS_LIST: Array<ProductContainer> = [
    {
      sectionDivId: "anticel",
      title: "productsContent.anticel.title",
      motto: "productsContent.anticel.motto",
      description: "productsContent.anticel.description",
      products: RecommendedProducts[3],
    },
    {
      sectionDivId: 'omega',
      title: 'productsContent.omega.title',
      motto: 'productsContent.omega.motto',
      description: 'productsContent.omega.description',
      products: RecommendedProducts[0]
    },
    {
      sectionDivId: 'kolagen',
      title: 'productsContent.kolagen.title',
      motto: 'productsContent.kolagen.motto',
      description: 'productsContent.kolagen.description',
      products: RecommendedProducts[1]
    },
    {
      sectionDivId: 'cbd',
      title: 'productsContent.cbd.title',
      motto: 'productsContent.cbd.motto',
      description: 'productsContent.cbd.description',
      products: RecommendedProducts[2]
    }
  ]

  constructor(props) {
    super(props);
    const {t} = this.props;
    this.state = {
      productContainers: ProductsPage.PRODUCT_CONTAINERS_LIST,
      viewList: ProductsPage.PRODUCT_CONTAINERS_LIST.map((product: ProductContainer) => t(product.title)),
      sectionList: ProductsPage.PRODUCT_CONTAINERS_LIST.map((product: ProductContainer) => product.sectionDivId),
      hideLabel: false, orderBy: 'none'}
  }

  private getFilter():string {
    let filter: string
    const params = new URLSearchParams(window.location.search);
    if(params.has('filter') && this.state.sectionList.includes(params.get('filter')!)){
      filter = params.get('filter')!
    }else{
      filter = "all"
    }
    return filter.toLowerCase()
  }

  componentDidMount(){
    this.filterProducts(this.getFilter())
    window.addEventListener('popstate', (event) => {
      this.filterProducts(this.getFilter())
    });
  }

  getAllProducts = (): Array<ProductClass> => {
    return this.state.productContainers.map((product: ProductContainer) => [...product.products])
      .reduce((acc: Array<ProductClass>, current: Array<ProductClass>) => {
        return [...acc, ...current];
      }, []);
  }

  handleProductsFilterUserChoice = (value: string) => {
    this.filterProducts(value);
    window.history.pushState(null, "", '/products?filter=' + value);
  }

  filterProducts = (value: string) => {
    const {t} = this.props;
    if (value === 'all') {
      this.setState({productContainers: ProductsPage.PRODUCT_CONTAINERS_LIST});
    } else {
      let filteredProducts = [...ProductsPage.PRODUCT_CONTAINERS_LIST];
      filteredProducts = filteredProducts.filter((product: ProductContainer) => t(product.sectionDivId) === value);
      this.setState({productContainers: filteredProducts})
    }
  }

  handleOrderByChange = (value: string) => this.setState({orderBy: value})


  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
        {this.state.productContainers &&
        <ProductsFilter viewList={this.state.viewList} onViewChange={this.handleProductsFilterUserChoice} sectionList={this.state.sectionList}
                        onOrderByChange={this.handleOrderByChange}
                        initFilter={this.getFilter()}
                        onHideLabelChange={() => {
                          this.setState({hideLabel: !this.state.hideLabel})
                        }}/>}
        {
          this.state.productContainers && (this.state.hideLabel ?
            <ProductsContent products={this.getAllProducts()} hideLabel={this.state.hideLabel} orderBy={this.state.orderBy} openCart={this.props.openCart} /> :
            this.state.productContainers.map((container: ProductContainer, index: number) => {
              return (<ProductsContent key={index} sectionDivId={container.sectionDivId}
                                       title={t(container.title)} motto={this.props.i18n.language === "cs" ? t(container.motto): undefined} description={t(container.description)}
                                       products={container.products} hideLabel={this.state.hideLabel} orderBy={this.state.orderBy} openCart={this.props.openCart} />)
            }))
        }

      </React.Fragment>
    )
  }
}

export default withTranslation()(ProductsPage);