import React from "react";
import classes from './ScrollButton.module.css';

const ScrollButton = () => {
  return (
              <div className={classes.Scroll} onClick={handleScroll}> 
                <i className="fa fa-angle-down"></i>
              </div>);
};
const handleScroll = () => {
  document.getElementById('products')!.scrollIntoView({behavior: "smooth"});
};
export default ScrollButton;