// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartOverview_CartOverviewGrid__2gLWR{\n    display: grid;\n    grid-template-columns: 120px repeat(5, 1fr) 90px 1fr 170px 1fr 90px 1fr;\n    margin-bottom: 30px;\n}\n\n.CartOverview_CardOverviewItem__1bGs_{\n    font-weight: bold;\n    text-transform: uppercase;\n    font-size: 12px;\n    letter-spacing: 3px;\n    color: #000;\n    font-family: Gotham-Medium;\n}\n\n\n.CartOverview_CartProduct__2YcsG{\n    grid-column: 2/7;\n    padding: 0 0 0 40px;\n}\n\n.CartOverview_CartPrice__1gE2X{\n    grid-column: 7/8;\n    text-align: right;\n    text-indent: -3px;;\n    direction: rtl;\n}\n\n.CartOverview_CardAmount__2k3p3{\n    grid-column: 9/10;\n    text-align: center;\n}\n\n.CartOverview_CartOverallPrice__2hCe7{\n    grid-column: 11/12;\n    text-align: right;\n    text-indent: -3px;\n    direction: rtl;\n}", ""]);
// Exports
exports.locals = {
	"CartOverviewGrid": "CartOverview_CartOverviewGrid__2gLWR",
	"CardOverviewItem": "CartOverview_CardOverviewItem__1bGs_",
	"CartProduct": "CartOverview_CartProduct__2YcsG",
	"CartPrice": "CartOverview_CartPrice__1gE2X",
	"CardAmount": "CartOverview_CardAmount__2k3p3",
	"CartOverallPrice": "CartOverview_CartOverallPrice__2hCe7"
};
module.exports = exports;
