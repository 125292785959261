import React, { Fragment } from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import Divider from "../../../common/divider/Divider";
import classes from "./CartSteps.module.css";
import {connect} from "react-redux";
import {CartStep} from "../../../../store/Reducer";
import { useHistory } from "react-router";

interface Props extends WithTranslation{
    highlightStep: number,
    setCurrentCartStep: (currentCartStep: number) => void
}

const CartSteps: React.FunctionComponent<Props> = (props: Props) => {

    let history = useHistory();

    const handleClick = (step:number) => {
        history.push(`/cart/${step}`)
        props.setCurrentCartStep(step);
    };


    const {t} = props;
    const steps = [1,/*2,*/3,4,5];
    
    return (
        <>
            <div className={classes.CartSteps}>
                <span className={classes.CartStepsSteps}>
                    {
                        steps.map((step, index)=>{
                          return <Fragment key={index}>
                                    <span onClick={step<props.highlightStep?()=>handleClick(step):undefined} style={step<props.highlightStep?{cursor: 'pointer'}:{cursor: 'default'}}
                                        className={props.highlightStep === step ? classes.CartStepSelected : classes.CartStepUnselected}>{t('cart.steps.' + step)}</span>
                                    {step!==5 && <span
                                        className={props.highlightStep === step ? classes.CartStepDividerSelected : classes.CartStepDividerUnselected} style={{cursor:"default"}}>/</span>}  
                                  </Fragment>
                                        }
                        )
                    }
                </span>
                <Divider type={"CartDivider"}/>
            </div>
            <div className={classes.CartWrapperMobile}>
                <div className={classes.CartStepsMobile}>
                    {
                        steps.map((step, index)=>{
                          return <Fragment key={index}>
                                    <span onClick={step<props.highlightStep?()=>handleClick(step):undefined} style={step<props.highlightStep?{cursor: 'pointer'}:{cursor: 'default'}}
                                        className={props.highlightStep === step ? classes.CartStepSelected : classes.CartStepUnselected}>{step===1?step:step-1}</span>
                                  </Fragment>
                                        }
                        )
                    }
                </div>
                <div className={classes.CartHeadingMobile}>{t(`cart.stepsMobile.${props.highlightStep}`)}</div>
                <div className={classes.CartDivider}><Divider type={"CartDivider"}/></div>
            </div>
        </>
    )
    
}


const mapDispatchToProps = dispatch => {
    return {
        setCurrentCartStep: (currentCartStep: number) => dispatch({type: CartStep.SET_CART_STEP, currentCartStep: currentCartStep})
    }
};

export default withTranslation()(connect(null, mapDispatchToProps)(CartSteps));