import React, {useEffect} from "react";
import classes from "./CartSignInOption.module.css"
import InputFieldCommon from "../../../common/inputField/InputFieldCommon"
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteConstants} from "../../../routes/RouteConstants";
import {Link, useHistory} from "react-router-dom";
import Button from "../../../common/button/Button";
import Divider from "../../../common/divider/Divider";
import {connect} from "react-redux";
import {CartStep} from "../../../../store/Reducer";


interface Props extends WithTranslation {
  classProp: string,
  headline: string,
  description: Array<string>,
  type: string,
  setCurrentCartStep: (currentCartStep: number) => void
}

const CartSignInOption = (props: Props) => {

  const history = useHistory();

  window.addEventListener("resize", () => {
    let headings = document.getElementsByClassName(classes.headingMobile);
    Array.from(headings).forEach(heading => {
      if (heading) {
        let item = heading.nextElementSibling as HTMLElement;
        window.innerWidth > 1220 ? item.style.display = "block" : item.style.display = "none";
      }
    })
  });


  useEffect(() => {
    let coll = document.getElementsByClassName(classes.headingMobile);
    for (let i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", () => {
        let content = coll[i].nextElementSibling as HTMLElement;
        let htmlChild = coll[i] as HTMLElement;
        if (content.style.display !== "block") {
          content.style.display = "block";
          htmlChild.style.paddingBottom = "40px";
        } else {
          content.style.display = "none";
          htmlChild.style.paddingBottom = "0px";
        }
      })
    }
  }, [])

  const {t} = props;

  return (
    <div className={props.classProp} style={{position: "relative"}}>
      <p className={classes.heading}
         style={{float: props.type === "firstTimer" || "oneTimer" ? "left" : "none"}}>{props.headline}</p>
      <p className={`${classes.headingMobile}`}>{props.headline}</p>
      <div className={classes.content}>
        {
          props.description.map((desc, index) => {
              return <p className={classes.description} key={index}>{desc}</p>       
          })
        }
        {/*Sign in*/}
        {props.type === "registered" ?
          <div className={classes.InputFieldWrapper}><InputFieldCommon onChange={() => {
          }} placeHolder={t("signin.email")} width={262} height={50} /></div> : null}
        {props.type === "registered" ?
          <div className={classes.InputFieldWrapper}><InputFieldCommon onChange={() => {
          }} placeHolder={t("signin.password")} width={262} height={50} /></div> : null}
        {props.type === "registered" ? <Link to={RouteConstants.password}
                                             className={classes.forgottenPassword}>{t('cart.signIn.forgottenPass')}</Link> : null}
        {props.type === "registered" &&
        <div className={classes.ButtonSignIn}><Button text={t("cart.signIn.signIn")} isEmailButton={false}
                                                      clickCallBack={() => history.push("/signin")}/></div>}
        {props.type === "firstTimer" &&
        <div className={classes.ButtonRegister}><Button text={t("cart.signIn.register")}
                                                        isEmailButton={false}
                                                        clickCallBack={() => history.push("/")}/></div>}
        {props.type === "oneTimer" &&
        <div className={classes.ButtonOneTimer}><Button text={t("cart.signIn.oneTimer")}
                                                        isEmailButton={false}
                                                        clickCallBack={() => {history.push("/cart/3"); props.setCurrentCartStep(3)}}/></div>}
      </div>
      <span className={classes.Divider}><Divider type={"SignInExpandableDivider"}/></span>
    </div>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentCartStep: (currentCartStep: number) => dispatch({type: CartStep.SET_CART_STEP, currentCartStep: currentCartStep})
  }
};

export default  withTranslation()(connect(null, mapDispatchToProps)(CartSignInOption));