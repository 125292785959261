import {RouteConstants} from './RouteConstants';
import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import LandingPage from "../ui/pages/landing/LandingPage";
import ContactPage from "../ui/pages/contacts/ContactPage";
import ProductsPage from "../ui/pages/products/ProductsPage";
import SignIn from "../ui/pages/signin/SignIn";
import Registration from "../ui/pages/registration/Registration";
import TextPage from "../ui/pages/textPages/TextPage";
import TextPageType from "../../templateObjects/textPageType/TextPageType";
import ProductDetail from "../ui/pages/productDetail/ProductDetail";
import UserDetails from '../ui/pages/userDetail/UserDetail';
import Cart from "../ui/pages/cart/Cart"
import ErrorPage from '../ui/pages/errorPage/ErrorPage';
import Blog from "../ui/pages/blog/Blog";
import BlogArticle from "../ui/pages/blog/blogArticle/BlogArticle";
import CreateNewArticle from "../ui/pages/blog/createNewArticle/CreateNewArticle";
import EditArticle from "../ui/pages/blog/editArticle/EditArticle";
import Services from "../ui/pages/services/Services";

interface Props{
  openCart: () => void;
}

const Routes: React.FunctionComponent<Props> = (outerProps: Props) => {
  const show = window.location.hostname === 'localhost';
  return (
    <Switch>
      <Redirect exact from={RouteConstants.home} to={RouteConstants.aboutUs}/>
      <Route exact path={RouteConstants.blog} component={Blog}/>
      <Route path={RouteConstants.createArticle} component={CreateNewArticle}/>
      <Route path={RouteConstants.aboutUs} component={LandingPage}/>
      <Route path={RouteConstants.contact} component={ContactPage}/>
      <Route path={RouteConstants.products}
             component={(props) => <ProductsPage {...props} openCart={outerProps.openCart}/>}/>
      <Route path={RouteConstants.error} component={ErrorPage}/>
        <Route path={RouteConstants.services} component={Services}/>
      <Route path={RouteConstants.personalTerms}
             component={(props) => <TextPage {...props} type={TextPageType.PERSONAL_DETAILS}/>}/>
      <Route path={RouteConstants.productReturn}
             component={(props) => <TextPage {...props} type={TextPageType.PRODUCTS_RETURN}/>}/>
     <Route path={RouteConstants.terms}
             component={(props) => <TextPage {...props} type={TextPageType.TERMS}/>}/>
      <Route path={RouteConstants.faq}
             component={(props) => <TextPage {...props} type={TextPageType.FAQ}/>}/>
      <Route exact path={RouteConstants.blogArticle}
             component={(props) => <BlogArticle {...props} />}/>
      <Route path={RouteConstants.editArticle}
             component={(props) => <EditArticle {...props} />}/>
      <Route path={RouteConstants.productDetail}
             component={(props) => <ProductDetail {...props} openCart={outerProps.openCart}/>}/>
      <Route path={RouteConstants.cart} component={Cart}/>
      <Route path={RouteConstants.signin} render={(props) => <SignIn {...props} isPassword={false}/>}/>

      { show ? <Route path={RouteConstants.userDetails} component={UserDetails}/>:null}
      { show ? <Route path={RouteConstants.password} render={(props) => <SignIn {...props} isPassword={true}/>}/>:null}
      { show ? <Route path={RouteConstants.registration} component={Registration}/>:null}

      <Redirect from={RouteConstants.home} to={RouteConstants.aboutUs}/>

    </Switch>
  )
};

export default Routes;