import React from "react";
import classes from './AboutUs.module.css';

interface Props {
  title: string,
  description: string,
  description2: string,
  description3: string,
  buttonText: string
}

const AboutUs: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className={classes.AboutUs}>
      <h1 className={classes.Header}>{props.title}</h1>
      <p className={classes.Description}>{props.description}</p>
      <p className={classes.Description}>{props.description2}</p>
      <p className={classes.Description}>{props.description3}</p>
    </div>
  )
};

export default AboutUs;