import React from "react";
import classes from "./Divider.module.css";

interface DividerProps{
    type?: string,
}


const Divider: React.FC<DividerProps> = (props) => {
    if(props.type) return <hr className={classes[props.type]}/>;
    return <hr className={classes.divider}/>
}

export default Divider;