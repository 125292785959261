import React from "react";
import classes from './NewsComponent.module.css';
import NewsInfo from "./newsInfo/NewsInfo";
import {WithTranslation, withTranslation} from 'react-i18next';
import NewsPictureBox from "./newsPictureBox/NewsPictureBox";
import introPicture from '../../../../assets/image/intro.png';

interface Props extends WithTranslation {

}

const News: React.FunctionComponent<Props> = (props: Props) => {

  const {t} = props;

  return (
    <div className={classes.News}>
      <div className={classes.InnerSection} id="idNewsComponent">
        <NewsInfo news={t('newsInfoComponent.news').toUpperCase()} title={t('newsInfoComponent.title')}
                  description={t('newsInfoComponent.description')}
                  buttonText={t('newsInfoComponent.buttonText')}/>
        <NewsPictureBox picture={introPicture}/>
      </div>
    </div>
  )
};

export default withTranslation()(News);

