import React from 'react';
import {connect} from 'react-redux';
import classes from './App.module.css';
import "./App.scss";
import Header from './components/ui/header/Header';
import Backdrop from './components/ui/header/backdrop/Backdrop';
import SlideDrawer from './components/ui/header/slideDrawer/SlideDrawer';
import Footer from "./components/ui/footer/Footer";
import {BrowserRouter} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';
import Routes from "./components/routes/Routes";
import DeliveryOffer from './components/ui/deliveryOffer/DeliveryOffer';
import CartSlider from "./components/ui/cartSlider/CartSlider";
import {Location} from "./templateObjects/location/Location";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-185342848-1');


interface State {
  sideDrawerOpen: boolean,
  cartDrawerOpen: boolean
}

interface Props extends WithTranslation{
  location?:Location
}

class App extends React.Component<Props, State> {

  state = {
    sideDrawerOpen: false,
    cartDrawerOpen: false
  };

  _toggleDrawerHandler = () => {
    this.setState((prevState: State) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  _toggleCartDrawerHandler = () => {
    this.setState( (prevState:State) => {
      return {cartDrawerOpen: !prevState.cartDrawerOpen};
    });
  };

  _backdroplinkHandler = () => {
    this.setState({sideDrawerOpen: false,cartDrawerOpen: false})
    document.body.style.overflow = "initial";
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen || this.state.cartDrawerOpen) {
      backdrop = <Backdrop click={this._backdroplinkHandler}/>;
      document.body.style.overflow = "hidden";
    }

    if(this.props.location === "cart"){
      document.getElementById("root-body")!.style.background = '#F6EBE7';
    }else{
      document.getElementById("root-body")!.style.background = '#FFFFFF';
    }

    const {t} = this.props;
    //TODO udelat delivery number dynamic
    return (
      <div className={classes.App}>
        <BrowserRouter>
        <div className={`${classes.MainPage} MainPage${this.props.location}`}>
            <DeliveryOffer text={t('landingPage.deliveryOffer')}/>
            <Header drawerClickHandler={this._toggleDrawerHandler}
                      show={this.state.sideDrawerOpen} openCartSlider={this._toggleCartDrawerHandler}/>
            <SlideDrawer show={this.state.sideDrawerOpen} openCartSlider={this._toggleCartDrawerHandler} closeDrawerSlider={this._toggleDrawerHandler} click={this._backdroplinkHandler}/>
            <CartSlider show={this.state.cartDrawerOpen} click={this._backdroplinkHandler}/>
            {backdrop}
            <Routes openCart={this._toggleCartDrawerHandler}/>
          </div>
          <Footer/>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.location
  }
};

export default withTranslation()(connect(mapStateToProps, null)(App));
