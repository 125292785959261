import React from "react";
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';


import CartSteps from "../../pageArtefacts/cartSteps/CartSteps";
import CartOverview from "../../pageArtefacts/cartOverview/CartOverview";
import DiscountComponent from "../../pageArtefacts/discountComponent/DiscountComponent";
import CartOrder from "../../pageArtefacts/cartOrder/CartOrder";
import Divider from "../../../common/divider/Divider";
import ProductClass, {ChangeAmountEnum, ProductActions} from "../../../../templateObjects/product/ProductClass";
import CartSignInOptions from "../../pageArtefacts/cartSignInOptions/CartSignInOptions"
import CartShippingAndPayment from "../../pageArtefacts/cartShippingAndPayment/CartShippingAndPayment"
import CartContactDetails from "../../pageArtefacts/cartContactDetails/CartContactDetails";
import OrderSummary from "../../pageArtefacts/orderSummary/OrderSummary";
import CartProduct from "../../pageArtefacts/cartProduct/CartProduct";
import {UserDetails, UserDetailsEnum} from "../../../../templateObjects/userDetails/UserDetails";

import classes from "./Cart.module.css";
import {Location, LocationActions} from "../../../../templateObjects/location/Location";


interface RouteInfo {
  step: string
}

interface Props extends RouteComponentProps<RouteInfo> {
  cartItems: Array<ProductClass>,
  totalPriceOfCart: number,
  paymentPrice: number,
  shippingPrice: number,
  shippingMethod: string,
  onIncrementOfAmount: (product: ProductClass) => void,
  onDecrementOfAmount: (product: ProductClass) => void,
  removeProduct: (product: ProductClass) => void,
  setPaymentPrice: (paymentPrice: number) => void,
  setShippingPrice: (paymentPrice: number) => void,
  setShippingMethod: (shippingMethod: string) => void,
  setUserDetails: (userDetails: object) => void,
  setLocation: (location: Location) => void,
  userDetails: UserDetails,
  currentCartStep: number,
  setShippingType: (setShippingType: string) => void,
  shippingType: string,
  setPacketaInfo: (id: number, info: string) => void,
  packetaId: number,
  packetaInfo: string
}

class Cart extends React.Component<Props, {}> {

  componentDidMount() {
    this.props.setLocation(Location.CART);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.setLocation(Location.COMMON);
  }

  render() {
    return (
      <React.Fragment>
        <CartSteps highlightStep={this.props.currentCartStep}/>
        {this.props.currentCartStep === 1 ?
          <div className={classes.Cart}>
            <div className={classes.CartOverview}><CartOverview/></div>
            {
              this.props.cartItems.map((item, i) => {
                return <CartProduct key={i} productClass={item}
                                    incrementAmount={this.props.onIncrementOfAmount}
                                    decrementAmount={this.props.onDecrementOfAmount}
                                    removeProduct={this.props.removeProduct}/>
              })
            }
            <DiscountComponent/>
            <div style={{marginTop: '90px'}}><Divider type={"OrderDivider"}/></div>
            <CartOrder currentStep={this.props.currentCartStep}
                       totalPrice={this.props.totalPriceOfCart}/>
          </div> : null}
        {this.props.currentCartStep === 2 ? <div className={classes.SignInOptions}>
          <CartSignInOptions/>
        </div> : null}
        {this.props.currentCartStep === 3 ? <div className={classes.CartContactDetails}>
          <CartContactDetails setUserDetails={this.props.setUserDetails} shippingPrice={this.props.shippingPrice}
                              paymentPrice={this.props.paymentPrice}
                              totalPriceOfCart={this.props.totalPriceOfCart}/>
        </div> : null}
        {this.props.currentCartStep === 4 ? <div className={classes.ShippingAndPayment}>
          <CartShippingAndPayment totalPrice={this.props.totalPriceOfCart}
                                  setShippingPrice={this.props.setShippingPrice}
                                  setPaymentPrice={this.props.setPaymentPrice}
                                  setShippingMethod={this.props.setShippingMethod}
                                  setShippingType={this.props.setShippingType}
                                  setPacketaInfo={this.props.setPacketaInfo}
          />
        </div> : null}
        {this.props.currentCartStep === 5 ? <div className={classes.CartOrderSummary}>
          <OrderSummary userDetails={this.props.userDetails} products={this.props.cartItems}
                        shippingPrice={this.props.shippingPrice}
                        paymentPrice={this.props.paymentPrice}
                        totalPrice={this.props.totalPriceOfCart}
                        shippingType={this.props.shippingType}
                        shippingMethod={this.props.shippingMethod}
                        packetaId={this.props.packetaId}
                        packetaInfo={this.props.packetaInfo}
          />
        </div> : null}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartItems,
    totalPriceOfCart: state.totalPriceOfCart,
    paymentPrice: state.paymentPrice,
    shippingPrice: state.shippingPrice,
    userDetails: state.userDetails,
    currentCartStep: state.currentCartStep,
    shippingMethod: state.shippingMethod,
    shippingType: state.shippingType,
    packetaId: state.packetaId,
    packetaInfo: state.packetaInfo
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onIncrementOfAmount: (product: ProductClass) => dispatch({type: ChangeAmountEnum.INCREMENT, product: product}),
    onDecrementOfAmount: (product: ProductClass) => dispatch({type: ChangeAmountEnum.DECREMENT, product: product}),
    removeProduct: (product: ProductClass) => dispatch({type: ProductActions.REMOVE_FROM_CART, product: product}),
    setPaymentPrice: (paymentPrice: number) => {
      dispatch({type: ProductActions.SET_PAYMENT, paymentPrice: paymentPrice})
    },
    setShippingPrice: (shippingPrice: number) => dispatch({
      type: ProductActions.SET_SHIPPING,
      shippingPrice: shippingPrice
    }),
    setShippingMethod: (shippingMethod: string) => dispatch({
      type: ProductActions.SET_SHIPPING_METHOD,
      shippingMethod: shippingMethod
    }),
    setUserDetails: (userDetails: object) => dispatch({
      type: UserDetailsEnum.SET_USER_DETAILS,
      userDetails: userDetails
    }),
    setLocation: (location: Location) => dispatch({
      type: LocationActions.SET_LOCATION,
      location: location
    }),
    setShippingType: (shippingType: string) => dispatch({
      type: ProductActions.SET_SHIPPING_TYPE,
      shippingType: shippingType
    }),
    setPacketaInfo: (id: number, info: string) => dispatch({
        type: ProductActions.SET_PACKETA_DATA,
        packetaId: id,
        packetaInfo: info
      }
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);