import ProductClass, {ProductCategory} from "../../templateObjects/product/ProductClass";

import anticelGel from '../../assets/image/Anticelulitidni_gel_retina.jpg';
import greenCoffeePeeling from '../../assets/image/Green_Coffee_Peeling_retina.jpg';
import anticelOlej from '../../assets/image/Anticelulitidni_masazni_smes_oleju_retina.jpg';

import anticelGelIcon from '../../assets/image/cartIcons/Anticelulitidni_gel_retina_kosik.jpg';
import greenCoffeePeelingIcon from '../../assets/image/cartIcons/Green_Coffee_Peeling_retina_kosik.jpg';
import anticelOlejIcon from '../../assets/image/cartIcons/Anticelulitidni_masazni_smes_oleju_retina_kosik.jpg';

const product1 = new ProductClass(37785,
        'products.anticel.product1.name', 299, 'products.anticel.product1.description',
        [greenCoffeePeeling],greenCoffeePeelingIcon, undefined, undefined, false, 1,
        ProductCategory.ANTICEL, 'products.anticel.product1.volume', 'products.anticel.product1.secondTitle',
        'products.anticel.product1.description2', 'products.anticel.product1.keyWords', 'products.anticel.product1.whyMe',
        'products.anticel.product1.usage', 'products.anticel.product1.ingredients', 'products.anticel.product1.extraIngredients',
        'products.anticel.product1.createdAt', undefined, undefined);

const product2 = new ProductClass(37787,
        'products.anticel.product2.name', 479, 'products.anticel.product2.description',
        [anticelGel],anticelGelIcon, undefined, undefined, false, 1,
        ProductCategory.ANTICEL, 'products.anticel.product2.volume', 'products.anticel.product2.secondTitle',
        'products.anticel.product2.description2', 'products.anticel.product2.keyWords', 'products.anticel.product2.whyMe',
        'products.anticel.product2.usage', 'products.anticel.product2.ingredients', 'products.anticel.product2.extraIngredients',
        'products.anticel.product2.createdAt', undefined, undefined);

const product3 = new ProductClass(37788,
            'products.anticel.product3.name', 259, 'products.anticel.product3.description',
            [anticelOlej],anticelOlejIcon, undefined, undefined, false, 1,
            ProductCategory.ANTICEL, 'products.anticel.product3.volume', 'products.anticel.product3.secondTitle',
            'products.anticel.product3.description2', 'products.anticel.product3.keyWords', 'products.anticel.product3.whyMe',
            'products.anticel.product3.usage', 'products.anticel.product3.ingredients', 'products.anticel.product3.extraIngredients',
            'products.anticel.product3.createdAt', undefined, undefined);
        
const anticelProducts = [
    product1,
    product2,
    product3
];

export default anticelProducts;