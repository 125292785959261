import React from 'react';
import style from "./DrawerToggleButton.module.css";

interface Props {
    click: () => void
}

class drawerToggleButton extends React.Component<Props>  {
    render() {
        return( <button className = {style.ToggleButton} onClick={this.props.click}>
            <div className = {style.ToggleButtonLine}/>
            <div className = {style.ToggleButtonLine}/>
            <div className = {style.ToggleButtonLine}/>
        </button>)
    }
}



export default drawerToggleButton;