import React, { useState } from "react";

import { returnTodayCzechDateFormat } from "../functions";

export const PublicationDateOption = (props) => {
  const {
    setPublicationDate,
    originalDate,
    classes,
    t,
    doNotChangeOption,
  } = props;
  const [uploadNow, setUploadNow] = useState(false);

  const today = returnTodayCzechDateFormat();

  return (
    <div>
      <h2>{t("blog.publicationDate")} </h2>
      <form>
        {doNotChangeOption ? (
          <>
            <input
              type="radio"
              value="now"
              id="doNotChangeDate"
              defaultChecked={true}
              onChange={() => {
                setPublicationDate(originalDate);
                setUploadNow(false);
              }}
              name="publication_option"
            />
            <label
              htmlFor="doNotChangeDate"
              style={{ paddingRight: "2rem" }}
              className={classes.OptionLabel}
            >
              {t("blog.notChangeDate")}
            </label>
          </>
        ) : null}

        <input
          type="radio"
          value="now"
          id="today"
          onChange={() => {
            setPublicationDate(today);
            setUploadNow(false);
          }}
          name="publication_option"
        />
        <label
          htmlFor="changeToday"
          style={{ paddingRight: "2rem" }}
          className={classes.OptionLabel}
        >
          {t("blog.today")} {today}
        </label>

        <input
          type="radio"
          value="later"
          id="changeDate"
          onChange={() => setUploadNow(true)}
          name="publication_option"
        />
        <label htmlFor="changeDate" className={classes.OptionLabel}>
          {t("blog.otherDate")}
        </label>
      </form>
      {uploadNow !== true ? null : (
        <p>
          <input
            onChange={(event) =>
              setPublicationDate(
                event.target.value.split("-").reverse().join(".")
              )
            }
            className={classes.TextInput}
            type="date"
          ></input>
        </p>
      )}
    </div>
  );
};
